<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>{{ labels.EDI_UPD_FL }}</h3>
      </div>
      <div class="closeicon-right">
                        <v-icon @click="closeLabelEdit" class="text-right"
                          >mdi-close-circle-outline
                        </v-icon>
                      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text class="px-6">
      <v-form lazy-validation>
        <addflexylabel :newLabel="newLabel" :langItems="langItems" :labels="labels" />
      </v-form>    
    </v-card-text>
    <v-divider class="py-2"></v-divider>
    <div class="px-md-10">
      <v-alert text :type="response_status" v-if="response_msg.length">
        {{ response_msg }}
      </v-alert>
    </div>
    <v-card-actions>
      <div class="main-flexyList">
        <v-btn class="nocap mt-1" @click="closeLabelEdit" text>
          <span class="pl-2"> {{ labels.CAN }}</span>
        </v-btn>
        <v-btn class="nocap primary mt-1" text @click="save">
          <span class="pl-2"> {{ labels.UPD }} </span>
        </v-btn>
        <v-checkbox v-if="isSuperAdmin" class="mt-0 ml-4"
        v-model="newLabel.effectAllOwners"
        :label="`Effect All Owners`"
      ></v-checkbox>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
// import Adddiscount from './ownerpopup/adddiscount.vue';
// import adddiscount from "./ownerpopup/adddiscount.vue";
import addflexylabel from "./flexy/addflexylabel.vue";
export default {
  components: {
    addflexylabel
  },
  name:"flexylabeldetail",
  props: ['newLabel', 'langItems', 'response_msg', 'response_status', 'labels'],

  data: () => ({
    closeDialog: false,
    response_msg: "",
    response_status: "",
    apiKey: "",
    owner: "",
    checkbox: true,
    iconlist: [],
    text: "",
    isSuperAdmin: localStorage.getItem('owner') == 1,
  }),
  methods: {
    created(){
      var session = this.$session.get("user_session");
      this.apiKey = session.userApiKey;
    },
    closeLabelEdit() {
      this.$emit('closeLabelEdit', false);
    },
    save(){
      this.$emit('save');
    },
  }
};
</script>

<style lang="scss">
.chevron-down-circle-outline {
  color: red;
}
.v-expansion-panel-header__icon .v-icon {
  color: red !important;
}

.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color:  var(--v-secondary-darken4) !important;
  border: 1px solid  var(--v-secondary-darken1) !important;
  border-radius: 100% !important;
}
</style>