<template>
  <!-- For super admin access, manage organisation details -->
  <div class="d-flex flex-column" style="height: 100%">
    <div class="s-css-reset flex-fill">
      <!-- Below button for Super Admin Access only -->
      <!-- Take note there is one more button serve same function at the bottom -->
      <LinkButton
        path="manageorgs"
        :label="labels.OD_BACK"
        icon="mdi-chevron-left"
        class="d-flex justify-center mt-8"
      />

      <!--  -->
      <v-card elevation="2" class="ma-4 mx-md-10 mb-md-10 mt-mb-4 org-print">
        <!-- Org details Header -->
        <v-container class="s-org-details">
          <v-row class="d-flex align-center justify-space-between">
            <div>
              <h2 class="my-4">
                {{ labels.TITLE }}
              </h2>
            </div>

            <div class="s-edit-btn-container" v-if="uAccess.ORG_UPDATEORGINFO">
              <v-btn
                v-show="!editingDetail"
                depressed
                elevation="0"
                class="nocap"
                @click="toggleEditingDetail()"
              >
                <v-icon> mdi-square-edit-outline </v-icon>
                {{ labels.OD_EDIT }}
              </v-btn>
            </div>

            <!-- [Edit] button container -->
            <!-- <v-col class="d-flex justify-end"> </v-col> -->
          </v-row>

          <!-- Divider between title and content -->
          <v-row class="mb-6"><v-divider></v-divider></v-row>

          <!-- Organisation Details -->
          <v-row>
            <v-col class="col-12 col-md-6">
              <v-row>
                <div class="col-12 col-sm-4">
                  <v-icon class="mb-2"> mdi-city-variant-outline </v-icon>
                  <b>
                    {{ labels.NAME }}
                  </b>
                </div>
                <span class="col-8">
                  {{ organisation.details.name }}
                </span>
              </v-row>
            </v-col>

            <v-col>
              <v-row class="mt-4 mt-md-0">
                <span class="col-12 col-sm-4">
                  <v-icon> mdi-form-textbox-password </v-icon>
                  <b>
                    {{ labels.CLIENT_ID }}
                  </b>
                </span>
                <span class="col-8 detail-space">
                  {{ organisation.details.client_id }}
                </span>
              </v-row>
            </v-col>
          </v-row>

          <!-- ----- -->
          <!-- Email -->
          <v-row class="mt-0 mt-md-4">
            <v-col class="col-12 col-md-6">
              <v-row class="mt-4 mt-md-0">
                <div class="col-12 col-sm-4">
                  <v-icon> mdi-email-outline </v-icon>
                  <b>
                    {{ labels.EMAIL }}
                  </b>
                </div>
                <div class="col-12 col-sm-8">
                  <span v-if="!editingDetail">
                    {{ organisation.details.email }}
                  </span>

                  <!-- Email editing mode -->
                  <v-text-field
                    v-else
                    single-line
                    outlined
                    dense
                    class="mb-2"
                    v-model="editDetailsInput.email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </div>
              </v-row>
            </v-col>

            <!-- ----------- -->
            <!-- Description -->
            <v-col>
              <v-row class="mt-4 mt-md-0">
                <div class="col-12 col-sm-4">
                  <v-icon> mdi-note-text-outline </v-icon>
                  <b>
                    {{ labels.STATUS }}
                  </b>
                </div>
                <div class="col-12 col-sm-8">
                  <span v-if="!editingDetail" class="col-12 col-sm-8 detail-space">
                    <span v-if="organisation.details.description > 300">
                      <span v-if="showDesc == true">{{
                        organisation.details.description
                      }}</span>
                      <span v-if="showDesc == false">{{
                        organisation.details.description.substring(0, 300) + "..."
                      }}</span>
                      <v-btn
                        small
                        depressed
                        v-if="showDesc == false"
                        @click="showDesc = true"
                        class="nocap"
                        ><v-icon class="primarytextcolor--text text--darken-1">
                          mdi-arrow-down</v-icon
                        >{{ labels.OD_SHOWMORE }}</v-btn
                      >
                      <v-btn
                        small
                        depressed
                        v-if="showDesc == true"
                        @click="showDesc = false"
                        class="nocap"
                        ><v-icon class="primarytextcolor--text text--darken-1">
                          mdi-arrow-up</v-icon
                        >{{ labels.OD_SHOWLESS }}</v-btn
                      >
                    </span>
                    <span v-else>{{ organisation.details.description }}</span>
                  </span>
                  <!-- Description editing mode -->
                  <v-textarea
                    v-else
                    single-line
                    outlined
                    dense
                    class="mb-2"
                    rows="4"
                    v-model="editDetailsInput.desc"
                  ></v-textarea>
                </div>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pl-0 pt-5 pb-2" v-if="editingDetail">
              <div class="col-12 col-sm-4">
                <v-icon>mdi-play-network-outline</v-icon>
                <b>Add Media Type</b>
              </div>
            </v-col>
            <v-col cols="12" sm="4" class="px-5 pb-2 pt-2 secondary lighten-3" v-if="editingDetail">
              Media Type
              <v-select
                class="dropdown"
                flat
                outlined
                depressed
                :items="mediaTypes"
                item-text="value"
                item-value="code"
                v-model="mediaobjects.media_type"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" class="px-5 pb-2 pt-2 secondary lighten-3" v-if="editingDetail">
              Mobile
                <v-text-field outlined v-model="mediaobjects.mobile"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class=" pb-2 pt-2 px-10 secondary lighten-3" v-if="editingDetail">
              Media Status
              <v-switch v-model="mediaobjects.media_status" :label= "mediaobjects.media_status ? 'On' : 'Off'"></v-switch>
            </v-col>
            <v-col cols="12" sm="10" class="px-5 pb-5 secondary lighten-3" v-if="editingDetail">
              Qr code
              <v-text-field outlined v-model="mediaobjects.qr_code"></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="2" md="2" lg="2" class="px-5 pb-5 secondary lighten-3" v-if="editingDetail">
              <div class="d-flex flex-column px-2">
                <span class="ml-2">
                  <v-btn class="nocap primary white--text" style="height: 41px; margin-top:21px;"
                    @click.prevent="addmediavalue()">
                    <v-icon>mdi-plus </v-icon>
                  </v-btn></span>
              </div>
            </v-col>
          </v-row>
          <v-row>
          <v-col cols="12" class=" px-0 lighten-3 my-4" v-if="organisation.details.mediavalue.length">
            <div class="pl-0 pt-5 pb-2">
              <v-icon>mdi-play-network-outline</v-icon>
              <b>Media Details</b>
            </div>
            <v-card :elevation="hover ? 24 : 2" style="width: 100%">
              <v-simple-table class="">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1">Media Type</span>
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1">Media Status</span>
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1">Qr Code</span>
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1">Mobile</span>
                      </th>
                      <th class="text-left" v-if="editingDetail">
                        <span class="font-weight-boald subtitle-1">Delete</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(req, index) in organisation.details.mediavalue" :key="req">
                      <td>{{ req.media_type }}</td>
                      <td>{{ req.media_status }}</td>
                      <td>{{ req.qr_code }}</td>
                      <td>{{ req.mobile }}</td>
                      <td v-if="editingDetail">
                        <v-icon color="error" @click="removemediavalue(index)">mdi-trash-can
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
          <!-- -* ABOVE *- Organisation Basic Details -->
        </v-container>

        <!-- --------------------- -->
        <!-- Confirm / Cancel Edit -->
        <v-row v-show="editingDetail">
          <v-container>
            <v-row class="d-flex justify-between">
              <v-col class="d-flex col-12 col-md-3"> </v-col>

              <v-col
                class="col-12 col-md-6 d-flex flex-row justify-center"
                v-if="saveChanges"
              >
                <v-btn
                  elevation="0"
                  class="nocap ma-4"
                  text
                  @click="cancelEditingDetail()"
                >
                  {{ labels.ADD_CANCEL }}
                </v-btn>
                <v-btn
                  elevation="0"
                  class="nocap ma-4"
                  color="tertiary2"
                  :dark="emailPattern.test(editDetailsInput.email)"
                  @click="confirmEditing()"
                  v-if="uAccess.ORG_UPDATEORGINFO"
                  :disabled="!emailPattern.test(editDetailsInput.email)"
                >
                  <v-icon>mdi-sticker-check-outline</v-icon>
                  {{ labels.OD_CONF_CHS }}
                </v-btn>
              </v-col>
              <v-col class="col-12 col-md-6 d-flex flex-row justify-center" v-else>
                <!-- Success notification -->
                <v-alert type="success" class="mt-4" dense>
                  {{ labels.OD_UP_SUC }}
                </v-alert>
              </v-col>

              <v-col class="d-flex col-12 col-md-3 justify-end">
                <v-btn
                  v-if="uAccess.ORG_DELETEORG && organisation.details.org_id!=u_session.org"
                  text
                  @click="toggleConfirm('deleteOrg')"
                  elevation="0"
                  color="error"
                  class="nocap ma-4"
                >
                  <v-icon> mdi-trash-can </v-icon>
                  {{ labels.OD_DEL }}
                </v-btn>
              </v-col>
              <!-- Line below commented by ZQ on 2021 Mar 30, changed button style to above v-btn item -->
              <!-- <p style="cursor:pointer;color: #8b714d !important; text-decoration:underline" @click="deleteOrg()">
                          Delete
                          </p> -->
            </v-row>
          </v-container>
        </v-row>
      </v-card>
      <!-- -* ABOVE *- Organisation Details -->

      <!-- ----------------------- -->
      <!-- Manage Users and Groups -->
      <!-- ----------------------- -->
      <v-card elevation="2" class="ma-4 ma-md-10 org-print">
        <div class="container-fluid">
          <!-- Manage Users and Groups tabs -->
          <v-toolbar
            v-if="loadTab"
            elevation="0"
            class="s-manage-users-and-groups"
          >
            <v-tabs
              grow
              slider-color="var(--v-tertiary1-base)"
              class="s-border-bottom justify-space-between"
              color="var(--v-tertiary1-base)"
              slider-size="6"
              v-model="manageUsersAndGroups_Tab"
            >
              <v-tab
                v-if="uAccess.ORG_GETUSERLIST"
                @click="
                  pageloader = true;
                  closePageLoad();
                "
              >
                {{ labels.USERS }}
              </v-tab>
              <v-tab
                v-if="
                  uAccess.ORG_GETORGASSIGNFUNCTIONS &&
                  u_session.orgType == 'main'
                "
                @click="
                  pageloader = true;
                  closePageLoad();
                "
              >
                {{ labels.FUNCTIONS }}
              </v-tab>
              <v-tab
                v-if="uAccess.ORG_GETORGGROUPLIST"
                @click="
                  pageloader = true;
                  closePageLoad();
                "
              >
                {{ labels.GROUP }}
              </v-tab>
            </v-tabs>
          </v-toolbar>
        </div>

        <!-- Manage Users and Groups tab content -->
        <v-container class="assFun-container">
          <v-row class="justify-center">
            <!-- Set width to 100% to make the tab content(table) full width -->
            <v-tabs-items
              style="width: 100%"
              v-model="manageUsersAndGroups_Tab"
              class="pb-8"
            >
              <v-tab-item key="Manage Users" v-if="uAccess.ORG_GETUSERLIST">
                <ManageUsers />
              </v-tab-item>
              <v-tab-item
                key="Manage Functions"
                v-if="
                  uAccess.ORG_GETORGASSIGNFUNCTIONS &&
                  u_session.orgType == 'main'
                "
              >
                <!-- Manage Functions -->
                <!-- <TagsSelection
                  v-if="uAccess.ORG_GETORGASSIGNFUNCTIONS"
                  :data="functionsList.allFunctionList"
                  :model="organisation.functions"
                  :title="labels.OD_FUN_ADDREMOVE"
                  stage-name="orgFunctions"
                  color="var(--v-tertiary1-base)"
                  divider
                  :service-object="updateOrgDetails"
                  on-submit-function="setFunctions"
                  :on-submit-btn-text="labels.OD_SAVE_CHANGE"
                  :tags-title="labels.OD_FUN_ASSIGNED"
                  error-message="Error! Editing not saved."
                /> -->
                <v-row>
                <div class="my-4">
                  
                  <v-menu offset-x :close-on-content-click="false" class="drop-size fun-dd-menu" style="display:inline-table">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :outlined="outlined"
                        dark
                        color= "tertiary1"
                        v-bind="attrs"
                        v-on="on"
                        class="nocap"
                        style="padding:0 0 0 5px;"
                      >
                      Add/Remove Organisation Functions
                        <v-icon style="margin: 0"> mdi-menu-right </v-icon>
                      </v-btn>
                    </template>
                    <div class="row fun-dd-row">
                      <div class="col-6 col-md-6 left-padding fun-dd-lefts">
                        <v-list style="min-height:60vh">
                          <h4 class="addGrptlt">Categories</h4>
                          <v-divider></v-divider>
                            <div  style="cursor:pointer" >
                              <v-list-item-group
                                v-model="type_filter"
                                active-class="bg-active"
                              >
                              <v-list-item @click="typefilters('')">All</v-list-item> 
                              <v-list-item @click="typefilters('102')">Microservices</v-list-item>              
                              <v-list-item @click="typefilters('140')">Platform Admin Functions</v-list-item>
                              </v-list-item-group>
                            </div>
                        </v-list>
                      </div>
                    
                      <div class="col-6 col-md-6 right-padding fun-dd-right">
                        <v-list style="max-height:60vh; overflow-y:scroll">
                          <div class="src-field">
                          <input type="search"
                            v-model="searchQuery"
                            append-icon="mdi-magnify"
                            placeholder="search function"
                            outlined
                            @keydown="filteredList()"
                            @click:clear="resetSearch(true)"
                            class="src-input"/>
                          <span type="submit">
                            <i class="fa fa-search"></i>
                          </span>
                          </div>
                          <v-list-item v-for="item in funList" :key="item" v-show="!checkExisting(item.function_name,organisation.functions)" >
                            <div >
                              <v-checkbox
                                v-model="organisation.functions"
                                color="tertiary1"
                                :value="item"
                              >
                                <template v-slot:label>
                                  <span class="black--text">
                                    {{ item.function_name }}
                                  </span>
                                </template>
                              </v-checkbox>
                            </div>
                          </v-list-item>
                        </v-list>
                      </div>
                    </div>
                  </v-menu>
                
                </div>
              </v-row>
                <v-divider class="ma-2"></v-divider>
                <h3 class="my-2">Assigned Functions</h3>
                <v-row>
                  <v-chip
                    class="mr-2 mb-2"
                    color="tertiary1"
                    dark
                    close
                    v-for="item in organisation.functions"
                    :key="item.function_name"
                    @click:close="removeArrayItem(organisation.functions, item)"
                  >
                  <span v-if="item.value!=undefined">
                      {{ item.value }}
                  </span>
                  <span v-else>
                      {{ item.function_name}}
                  </span>
                  </v-chip>
                </v-row>
                <v-row justify="center" v-if="saveChanges" style="margin-top:40px !important;">
                  <v-btn class="nocap mx-6"  :disabled ="(organisation.functionscount == organisation.functions.length)" text @click="getassignFunctionList()">
                    Cancel
                  </v-btn>
                  <v-btn
                    class="nocap mx-6"
                    :disabled ="(organisation.functionscount == organisation.functions.length)"
                    color="var(--v-tertiary2-base)"
                    :dark="(organisation.functionscount > organisation.functions.length ||  organisation.functionscount < organisation.functions.length)"
                    @click="submit()"
                  >
                    <v-icon>mdi-content-save-edit-outline</v-icon>
                    Save Change
                    <v-progress-circular
                      :width="3"
                      :size="15"
                      color="white"
                      indeterminate
                      class="ml-1"
                      v-if="progressloader"
                    ></v-progress-circular></v-btn
                  >
                </v-row>
                <v-row justify="center" style="height:48px;" v-else >
                    <!-- Success notification -->
                    <v-alert type="success" justify="center" class="mt-4" dense>
                      Changes saved!
                    </v-alert>
                </v-row>
              </v-tab-item>
              <v-tab-item
                key="Manage Groups"
                v-if="uAccess.ORG_GETORGGROUPLIST"
              >
                <ManageGroups />
              </v-tab-item>
            </v-tabs-items>
          </v-row>
        </v-container>
      </v-card>
      <!-- -* ABOVE *- Manage Users and Groups -->
      <!-- =================================== -->

      <!-- Below button for Super Admin Access only -->
      <!-- Take note there is one more button serve same function at the top -->
      <LinkButton
        path="manageorgs"
        :label="labels.OD_BACK"
        icon="mdi-chevron-left"
        class="d-flex justify-center mt-4 mb-8"
        v-if="u_session.orgType == 'main'"
      />

      <!-- <Loader v-if="loader"/> -->

      <!-- Remove Organisation Confirmation -->
      <!-- ----------------------------------- -->
      <ConfirmBox
        box-name="deleteOrg"
        :on-confirm-value="editDetailsInput"
        :box-title="labels.OD_REMOVE_ORG"
        :box-content="labels.OD_REMOVE_AREYOU"
        box-class="error-dialog"
        :confirm-btn-text="labels.USR_CONFIRM"
        :cancel-btn-text="labels.ADD_CANCEL"
        confirm-btn-icon="mdi-delete"
      >
      </ConfirmBox>
      <!-- -* ABOVE *- Remove Organisation Confirmation -->
      <v-overlay :value="pageloader" z-index="6" color="#cececea6">
        <!-- <v-img
          alt="sentient logo"
          class="shrink mt-1"
          :contain="true"
          src="@/assets/loader.gif"
          width="200"
        ></v-img> -->
        <loaderimg v-if="loader"/>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "@vue/composition-api";
// Components
import ManageUsers from "./ManageUsers";
import ManageGroups from "./ManageGroups";
import LinkButton from "../ui/LinkButton";
import TagsSelection from "../ui/TagsSelection";
import Loader from "../ui/Loader";
import ConfirmBox from "../ui/ConfirmBox";
import loaderimg from "../loaderimg.vue";
// Services
import {
  organisationService,
  manageOrgsService,
} from "../../services/organisationService";

export default defineComponent({
  components: {
    ManageUsers,
    ManageGroups,
    LinkButton,
    TagsSelection,
    Loader,
    ConfirmBox,
    loaderimg
  },
  setup(_, ctx) {
    //SESSION OBJECTS
    const session = ctx.root.$session,
      u_session = session.get("user_session").session,
      uAccess = u_session.uAccess;
      window.document.title = "Platform-admin";
      const onprem=ref(u_session.onprem)
    // Import individual variables and functions from organisationServices.js
    const {
      getOrganisationDetails,
      organisation,
      updateOrganisationDetail,
      functionsList,
      getassignFunctionList,
      stage,
      getfunctionList,
      getFlexi,
      confirmBoxs,
      toggleConfirm,
    } = organisationService();
    getOrganisationDetails();
    const { allAssignedFunction  } = manageOrgsService();
    const  progressloader= ref(false);
    const labels = ref({});
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      rules = {
        required: (value) => !!value || labels.value.OD_REQUIRED,
        email: (value) => {
          return emailPattern.test(value) || labels.value.EM_VAL;
        },
      };
    let manageUsersAndGroups_Tab = null;

    // State of editing Organisation Details
    const editingDetail = ref(false);
    const loadTab = ref(false);
    const showDesc = false;
    const copyTooltipText = ref(labels.value.COPY);

    const showCopyApi = ref(false);
    const showCopyKey = ref(false);
    const saveChanges = ref(true);
    const apiTooltip = ref(false);
    const pulicKeyTooltip = ref(false);
    const pageloader = ref(false);
    const category = ref('');
    const searchQuery =ref('');
    const mediaTypes = ref([]);
    const deletemediavalue = ref([]);
    const mediaobjects = ref({
      id:0,
      media_type : "Telegram",
      media_status : false,
      qr_code: "",
      mobile:""
    });
    mediaTypes.value = ["Telegram","Facebook","Instagram"];

    function tooltipCopy(from) {
      if (from == "api") {
        apiTooltip.value = true;
      } else {
        pulicKeyTooltip.value = true;
      }

      setTimeout(() => {
        apiTooltip.value = false;
        pulicKeyTooltip.value = false;
      }, 2000);
    }
    function addmediavalue(){
      if(mediaobjects.value.media_status ){
        mediaobjects.value.media_status = "On"
      }else{
        mediaobjects.value.media_status = "Off"
      }
      console.log(mediaobjects.value)
      organisation.details.mediavalue.push(mediaobjects.value)
      console.log(organisation.details.mediavalue)
      mediaobjects.value = {
        "id":0,
        "media_type":"Telegram",
        "media_status" : false,
        "qr_code":"",
        "mobile":""
      }
    }
    function closePageLoad() {
      setTimeout(() => {
        pageloader.value = false;
      }, 3000);
    }

    // Container for user input when editing details

    const mediastatus = () => {
      mediaobjects.value.media_status = !mediaobjects.value.media_status;
    };

    const removemediavalue = (index)=>{
      deletemediavalue.value.push(organisation.details.mediavalue[index].id)
      organisation.details.mediavalue.splice(index, 1)
    };
    const editDetailsInput = ref({
      email: "",
      desc: "",
      publicKey: "",
      mediavalue : [],
      deletemediavalue:[],
    });
    // -* ABOVE *- All variables //
    // ========================= //

    // get default field data when user edit organisation details
    const getEditDetailsInput = () => {
      editDetailsInput.value.email = organisation.details.email;
      editDetailsInput.value.desc = organisation.details.description;
      editDetailsInput.value.publicKey = organisation.details.public_key;
      editDetailsInput.value.name = organisation.details.name;
      editDetailsInput.value.id = organisation.details.org_id;
      editDetailsInput.value.userName = organisation.details.user_name;
      editDetailsInput.value.mediavalue = organisation.details.mediavalue;
      editDetailsInput.value.deletemediavalue = deletemediavalue.value;
    };

    const toggleEditingDetail = () => {
      editingDetail.value = !editingDetail.value;
    };

    const cancelEditingDetail = () => {
      toggleEditingDetail();
      // Reset v-model values for input fields when cancel input
      getEditDetailsInput();
    };
    const showTime = (from) => {
      setTimeout(function () {
        if (from == "api") {
          showCopyApi.value = false;
        } else {
          showCopyKey.value = false;
        }
      }, 1000);
    };
    const removeArrayItem = (array,item) => {
      const index = array.indexOf(item);
      if (index !== -1) {
        array.splice(index, 1);
      }
    };
    
    //GET ALL FUNCTION FOR ASSIGN FUNCTION DROPDOWN SELECT
    const funList = ref();
    const getAllfunctionList = async () => {
      await getfunctionList(u_session.owner);
      funList.value = functionsList.allFunctionList;
    };
    //GET LIST FUNCTION FOR ASSIGNED FUNCTIONs
    const allorgassignfunction=ref({});
    const getAssignedfunctionList = async () => {
      await getassignFunctionList();
      organisation.functionscount == organisation.functions.length
      
    };
    getAllfunctionList();
    getAssignedfunctionList();
    const typefilters = function(category){
        funList.value = functionsList.allFunctionList;
        if (category =="102"){
          funList.value = funList.value.filter(item=>item.type_id == category);
        }else if(category == ""){
          funList.value = functionsList.allFunctionList;
        }else{
          funList.value = funList.value.filter(item=>item.type_id != 102);
        }
    };
    const filteredList = () =>{  
      funList.value=functionsList.allFunctionList;
      if (searchQuery !=""){
        funList.value= funList.value.filter(list => list.function_name.toUpperCase().indexOf(searchQuery.value.toUpperCase()) !=-1)
      }
    };
    const resetSearch = (searchType)=>{
      if(searchType==true){
        funList.value=functionsList.allFunctionList;
        searchQuery.value='';
      }
    };
    const checkExisting = (name,dataarray) => {
      var isExist=false;
      if(dataarray!=undefined && dataarray.length>0){
        dataarray.forEach((fun)=>{
          if(name==fun.value){
            isExist=true;
          }
        })
      }
      return isExist;
    };
    const submit = async () => {
      progressloader.value=true;
      await allAssignedFunction(organisation.functions)
      setTimeout(function () {
        progressloader.value = false;
        saveChanges.value = false;
      }, 2000);
      setTimeout(function () {
        saveChanges.value = true;
      }, 5000);
      organisation.functionscount=organisation.functions.length;
    };
    // Triggers a function in organisationService.js file
    const confirmEditing = () => {
      saveChanges.value = false;
      // Call function in service file
      updateOrganisationDetail(editDetailsInput.value);
      // [TODOS] - Do we need one more function to update data directly from hrere?
      // Or make another API call in services file to update data?
      // Toggle off editing mode
      setTimeout(function () {
        saveChanges.value = true
        toggleEditingDetail();

      }, 2000);
    };
    // -* ABOVE *- All function //
    // ======================== //
    onMounted(() => {
      setTimeout(function () {
        // Simulate the API call
        getOrganisationDetails().then(() => {
          getEditDetailsInput();
        });
      }, 1000);
    });
    const getFlexiLabels = async () => {
      await getFlexi("ORG_DE", localStorage.getItem("lang"));
      labels.value = organisation.labels;
      loadTab.value = true;
    };
    getFlexiLabels();
    return {
      manageUsersAndGroups_Tab,
      editingDetail,
      //
      toggleEditingDetail,
      editDetailsInput,
      organisation,
      cancelEditingDetail,
      confirmEditing,
      functionsList,
      stage,
      getAllfunctionList,
      labels,
      showCopyApi,
      showCopyKey,
      showTime,
      u_session,
      onprem,
      uAccess,
      showDesc,
      rules,
      emailPattern,
      saveChanges,
      confirmBoxs,
      toggleConfirm,
      tooltipCopy,
      copyTooltipText,
      apiTooltip,
      pulicKeyTooltip,
      pageloader,
      closePageLoad,
      loadTab,
      funList,
      typefilters,
      filteredList,
      searchQuery,
      category,
      checkExisting,
      removeArrayItem,
      submit,
      resetSearch,
      getassignFunctionList,
      allAssignedFunction,
      allorgassignfunction,
      progressloader,
      mediaTypes,
      mediaobjects,
      mediastatus,
      addmediavalue,
      removemediavalue,
      deletemediavalue
    };
  },
});
</script>

<style lang="scss" scoped>
@media print {
  .container {
    max-width: 100% !important;
  }
}
.s-org-details {
  padding: 12px 90px;
}
// Controls style on organisation detail editing mode only
.s-org-details {
  .v-input__control {
    margin-right: 2rem;
  }
}
.padding5 {
  padding-left: 5px;
  padding-right: 5px;
}
.v-menu__content{
  max-height: 400px;
}
@media (max-width: 400px) {
  .s-edit-btn-container {
    width: 100%;
    text-align: right;
    margin-bottom: 16px;
  }
}
.assFun-container {
  padding: 12px 40px;
}
.fun-dd-row {
  background: #fff;
  width:596px;
    // padding: 20px 10px;
    
}
.addGrptlt {
  text-align: center;
    color: #000000de;
    margin-bottom: 5px;
}
.fun-dd-lefts .v-list {
  position: relative;
  background-color: #efefef;
}
.fun-dd-lefts .v-list-item{
  padding: 0 5px;
  font-size: 15px;
}
// .fun-dd-lefts .v-list-item:last-child {
//   position: absolute;
//   bottom: 5px;
// }
.fun-dd-right {
  padding-top: 0;
  padding-right: 0;
}
.fun-dd-right .v-list {
  padding-top: 0;
}
.fun-dd-right .v-text-field {
  width: 92%;
  margin: auto;
  position: sticky;
    z-index: 33;
    top: 0;
    background: #fff;
    margin-right: 12px;
  
}
.src-input:focus-visible {
  outline: none;
}
.src-input {
  width: 97%;
    height: 40px;
    border: 2px solid #c3c3c3;
    padding: 8px;
    margin: 10px 0 20px;
    border-radius: 4px;
    position: sticky;
    top: 0;
    z-index: 100;
    background: #fff;
}
.src-field {
  position: sticky;
    top: 0;
    z-index: 100;
    background: #fff;
}
.src-field span {
  position: absolute;
    top: 19px;
    right: 18px;
    z-index: 1000;
    color: #C3C3C3;
}
.left-padding{
background-color: #efefef;
padding: 10px 25px;
}
.right-padding{
  width: 230px;
    padding: 10px 15px 15px 10px;
}
//---------------------------------- mobile view ---------------------------------- 
@media only screen and (min-width: 350px) and (max-width: 767px) {

.fun-dd-right .v-list-item {
  padding-left: 0;
  padding-right: 0;
}
.s-org-details {
  padding: 12px;
}
.s-manage-users-and-groups .v-toolbar__content,.s-manage-users-and-groups.v-sheet.v-toolbar {
  height: auto !important;
}
.s-manage-users-and-groups .v-toolbar__content .v-slide-group__content {
  display: block;
}
.s-manage-users-and-groups .v-toolbar__content .v-slide-group__content .v-tab {
padding: 10px;
}
.assFun-container {
  padding: 12px 10px;
}
.assFun-container .v-btn:not(.v-btn--round).v-size--default {
  // padding: 0 0 0 5px;
  margin-bottom: 10px;
}
::v-deep(.v-menu__content--fixed) {
  max-width: 95%;
  left: 2.5% !important;
  top: 10% !important;
}
}
.bg-active {
  font-weight: bold;
  color: #000000de !important;
}
</style>