<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>{{ labels.EDIT_DMAPP }}</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text class="px-6">
      <!-- EXPANSION Basic Info  PANEL -->
      <v-col cols="12" class="panelBorder pa-0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="pl-10 secondary--text text--darken-4">Basic Info</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <addDemoAppInfo
                :demoappsAllData="demoappsAllData"
                :labels="labels"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- EXPANSION PANEL END -->
      <!-- EXPANSION Descriptions  PANEL -->
      <v-col cols="12" class="panelBorder pa-0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="pl-10 secondary--text text--darken-4">
                Additional Info
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <demoAdditionalInfo
                :demoappsAllData="demoappsAllData"
                :msListing="msListing"
                :demo_category="demo_category"
                :labels="labels"
                :demo_apps_iconlist="demo_apps_iconlist"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="my-4 px-md-16">
          <v-alert
            class="text-left"
            text
            :type="response_status"
            v-if="response_message.length"
          >
            {{ response_message }}
          </v-alert>
        </div>
      </v-col>
      <!-- EXPANSION PANEL END -->
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <div class="pl-10">
        <v-btn class="nocap" @click="closeDetailDemoapps" text>
          <span class=""> {{ labels.CAN }}</span>
        </v-btn>
        <v-btn class="nocap primary" text @click="demoAppEditDialog()">
          <span class=""> {{ labels.MIC_SAVE }}</span>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import addDemoAppInfo from "./demoPopup/addDemoapp.vue";
import demoAdditionalInfo from "./demoPopup/demoAdditionalInfo.vue";
export default {
  components: {
    addDemoAppInfo,
    demoAdditionalInfo,
  },
  name: "demoAppEdit",
  props: [
    "demoappsAllData",
    "msListing",
    "demo_category",
    "labels",
    "response_status",
    "response_message",
    "demo_apps_iconlist",
  ],
  data: () => ({
    checkbox: true,
    iconlist: [],
    text: "",
    itemsSource: [
      {
        text: "Nature",
        icon: "mdi-pencil-outline ",
      },
      {
        text: "Nightlife",
        icon: "mdi-pencil-outline ",
      },
    ],
    msType: ["Atomistic", "Pipeline"],
  }),
  methods: {
    closeDetailDemoapps() {
      this.$emit("closeDetailDemoapps", false);
    },
    demoAppEditDialog() {
      // this.msDetailDialog=false;
      // this.msEdit=true;
      this.$emit("updatedDemoappsSubmitted", false);
    },
  },
};
</script>

<style lang="scss">
.chevron-down-circle-outline {
  color: red;
}
.v-expansion-panel-header__icon .v-icon {
  color: red !important;
}

.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: var(--v-secondary-darken4) !important;
  border: 1px solid var(--v-secondary-darken1) !important;
  border-radius: 100% !important;
}
</style>