<template>
  <div class="mbl-msform msForm">
    <v-row>
      <v-col cols="12" sm="6">
        <label>{{ labels.NM }}</label>
        <v-text-field outlined placeholder="Name" v-model.trim="newDiscount.name">
        </v-text-field>
        <span v-if="!newDiscount.name && !isValidForm" style="color:red;">{{ labels.NMVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.DISC_TY }}</label>
        <v-select
          v-model="discountType"
          outlined
          :items="typeField"
          item-text="text"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.DISCP }}</label>
        <v-text-field outlined placeholder="Discount(%)" type="text" v-model="newDiscount.discount" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')">
        </v-text-field>
        <span v-if="!newDiscount.discount && !isValidForm" style="color:red;">{{ labels.DISCPVAL }}</span>
      </v-col>
      <!-- -----------------Start  Date---------------------- -->
      <v-col cols="12" sm="6">
        <label>{{ labels.S_DA }}</label>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
            readonly
              outlined
              class="datePicker"
              v-model="dateFormattedStart"
              v-bind="attrs"
              @blur="start_date = parseDate(dateFormattedStart)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="start_date"
            no-title
            :min="currentDate"
            :show-current="currentDate"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
        <!-- <p>Date in ISO format: <strong>{{ date }}</strong></p> -->
      </v-col>
      <!-- -----------------End Date---------------------- -->
      <v-col cols="12" sm="6">
        <label>{{ labels.E_DA }}</label>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              readonly
              class="datePicker"
              v-model="dateFormattedEnd"
              v-bind="attrs"
              @blur="end_date = parseDate(dateFormattedEnd)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="end_date"
            no-title
            @input="menu2 = false"
            :min="start_date"
          ></v-date-picker>
        </v-menu>
         <span v-if="(newDiscount.sdate > newDiscount.edate) && !isValidForm" style="color:red;">{{ labels.E_DAVAL }}</span>
        <!-- <p>Date in ISO format: <strong>{{ date }}</strong></p> -->
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.MIC }}</label>
        <v-select
          outlined
          v-model="newDiscount.micros"
          :items="microArray"
          item-text="name"
          item-value="id"
          placeholder="Microservices"
          multiple
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "adddiscount",
  props: ["microArray", "newDiscount", "isValidForm", "labels"],
  data: (vm) => ({
    currentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
      dateFormattedStart: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    dateFormattedEnd: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    menu1: false,
    menu2: false,
    micro_list1: [],
    iconlist: [],
    discountType: "seasonal",
    typeField: ["seasonal"],

    itemsField: [],
    msType: ["Atomistic", "Pipeline"],
  }),
  created(){
    this.microId();
    this.newDiscount.sdate = this.currentDate
    this.newDiscount.edate = this.currentDate
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    start_date (val) {
      this.dateFormattedStart = this.formatDate(val);
      this.newDiscount.sdate = this.start_date;
      // this.newDiscount.sdate = this.currentDate
    },
    end_date (val) {
      this.dateFormattedEnd = this.formatDate(val);
      this.newDiscount.edate = this.end_date;
      // this.newDiscount.edate = this.currentDate
    },
    newDiscount: {
      handler() {
        if (this.newDiscount.id > 0) {
          this.dateFormattedStart = this.formatDate(this.newDiscount.sdate);
          this.start_date = this.newDiscount.sdate;
          this.dateFormattedEnd = this.formatDate(this.newDiscount.edate);
          this.end_date = this.newDiscount.edate;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    microId(){
      this.newDiscount.micros.forEach((value, index) => {
        this.newDiscount.micros[index] = parseInt(value)
      })
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
.v-menu__content {
  margin-top: 0px !important;
  margin-left: 0px;
  background-color: red;
}
</style>
