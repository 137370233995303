<template>
  <div style="width:100%" v-if="userQuata">
    <v-form class="create-ORG"
      ref="form"
      v-model="valid"
      lazy-validation
    >
    <!-- ------------ -->
    <!-- Input Fields -->
    <!-- ------------ -->
    <!-- Take note of the v-model pattern, inputField.model is a -->
    <!-- String of the object value, don't put the whole object  -->
    <!-- & windowsize<767 && inputField.type === ''?'col-12':'' -->
    <v-row >
      <v-col class="col-6 pr-3 mbl-col" v-for="(inputField, i) in inputFields" :key="i.field" 
      :class="(inputField.type === ''?'col-6':'col-12')"
      >
        <label
          class="s-input-label"
          :class="inputField.required ? ' required' : ''"
        >
          {{ inputField.field }}
        </label>

        <v-textarea
          v-if="inputField.type === 'textarea'"
          rows="5"
          no-resize
          single-line
          outlined
          dense
          v-model="newOrganisation[inputField.model]"
          :rules="inputField.rules"
        ></v-textarea>
        
        <v-text-field
          v-if="inputField.type === ''"
          single-line
          outlined
          dense
          v-model="newOrganisation[inputField.model]"
          v-on:keyup="checkEmail(inputField.model,newOrganisation[inputField.model])"
          :rules="inputField.rules"
          class="force-override-for-error-msg"
        />

        <div v-else-if="inputField.type === 'multiselect'">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                :color="inputField.v_chip_color"
                v-bind="attrs"
                v-on="on"
                class="nocap justify-space-between"
              >
                {{labels.O_SELECT}}
                <v-icon style="margin:0">
                  mdi-menu-right
                </v-icon>
              </v-btn>
            </template>
            <v-list style="max-height:60vh; overflow-y:scroll">
              <v-list-item
              v-for="(item, index) in groupList"
              :key="index.name"
              >
                <v-checkbox
                  v-model="newOrganisation[inputField.model]"
                  :color="inputField.v_chip_color"
                  :value="item"
                >
                  <template v-slot:label>
                    <span class="black--text">
                      {{ item.name }}
                    </span>
                  </template>
                </v-checkbox>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- above - menu selection -->
          <div class="mt-2 mb-4">
            <v-chip
              class="ma-1"
              :color="inputField.v_chip_color"
              dark
              close
              v-for="item in newOrganisation[inputField.model]"
              :key="item.name"
              @click:close="
                removeArrayItem(newOrganisation[inputField.model], item)
              "
            >
              {{ item.name}}
            </v-chip>
          </div>
          <!-- above - display selected menu item as tags -->
        </div>
        <div v-else-if="inputField.type === 'multiselect2'">
          <v-menu  offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                block
                :color="inputField.v_chip_color"
                v-bind="attrs"
                v-on="on"
                class="nocap justify-space-between"
              >
                {{labels.O_SELECT}}
                <v-icon style="margin:0">
                  mdi-menu-right
                </v-icon>
              </v-btn>
            </template>
            <div class="row fun-dd-row">
              <div class="col-6 col-md-6 left-padding fun-dd-lefts">
                <v-list style="min-height:60vh;">
                  <h4 class="addGrptlt">Categories</h4>
                  <v-divider></v-divider>
                    <div  style="cursor:pointer" >
                      <v-list-item-group
                        v-model="type_filter"
                        active-class="bg-active"
                      >
                      <v-list-item @click="typefilters('')">All</v-list-item> 
                      <v-list-item @click="typefilters('102')">Microservices</v-list-item>              
                      <v-list-item @click="typefilters('140')">Platform Admin Functions</v-list-item>
                      </v-list-item-group>
                    </div>
                </v-list>
              </div>
              <div class="col-6 col-md-6 right-padding fun-dd-right">
                <v-list style="max-height:60vh; overflow-y:scroll">
                  <div class="src-field">
                  <input type="search" 
                    v-model="searchQuery"
                    append-icon="mdi-magnify"
                    placeholder="search function"
                    outlined
                    @keyup="filteredList()"
                    @click:clear="resetSearch(true)"
                    class="src-input"/>
                  <span type="submit">
                    <i class="fa fa-search"></i>
                  </span>
                  </div>
                  <v-list-item
                    v-for="item in funList" :key="item">
                    <v-checkbox
                      v-model="newOrganisation[inputField.model]"
                      :color="inputField.v_chip_color"
                      :value="item"
                    >
                      <template v-slot:label>
                        <span class="black--text">
                          {{ item.function_name }}
                        </span>
                      </template>
                    </v-checkbox>
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-menu>
          <!-- above - menu selection -->
          <div class="mt-2 mb-4">
            <v-chip
              class="ma-1"
              :color="inputField.v_chip_color"
              dark
              close
              v-for="item in newOrganisation[inputField.model]"
              :key="item.function_name"
              @click:close="
                removeArrayItem(newOrganisation[inputField.model], item)
              "
            >
              {{ item.function_name}}
            </v-chip>
          </div>
          <!-- above - display selected menu item as tags -->
        </div>
      </v-col>
      
    </v-row>

    <!-- !!! This is a special input which request customized function -->
    <!-- <v-row>
      <v-col cols="3">
        <p class="s-input-label">
          {{labels.PUBLICKEY}}
        </p>
      </v-col>
      <v-col cols="9">
        <v-textarea
          rows="3"
          no-resize
          single-line
          outlined
          dense
          hide-details=""
          v-model="newOrganisation.consumerPublicKey"
        ></v-textarea>
        <a color="#A27E4C" text small class="s-border-bottom mt-2 nocap" href="keys" target="_blank">
          <v-icon small>mdi-account-key-outline</v-icon>
          {{labels.GEN_KEYS}}
        </a>
      </v-col>
    </v-row> -->
    <!-- -ABOVE- Input Fields -->
    <!-- ==================== -->

    <!-- ----------- -->
    <!-- Error Alert -->
    <!-- ----------- -->
    <v-row justify="center" class="mt-4">
      <v-alert
        dense
        outlined
        type="error"
        v-show="emailExist"
        transition="fade-transition"
      >
        {{labels.ADD_THISEMAIL}}
      </v-alert>
      <v-alert
        dense
        outlined
        type="error"
        v-show="!inputFieldsValid"
        transition="fade-transition"
      >
        {{labels.ADD_PLEASEMAKE}}
      </v-alert>
    </v-row>
    <!-- --------------- -->
    <!-- Control Buttons -->
    <!-- --------------- -->
    <v-row class="justify-center">
      <v-btn
        elevation="0"
        class="nocap mt-4 mx-6"
        text
        @click="toggleDialog('createOrganisationWindow',false);"
        >{{labels.CANCEL}}</v-btn
      >
      <v-btn
        elevation="0"
        class="nocap mt-4 mx-6"
        color="btn-chat"
        dark
        @click="loader=true;useCreateOrganisation()"  
      >
        <v-icon>mdi-bank-plus</v-icon>

        
        {{labels.CREATE}}
        <v-progress-circular
          :width="3"
          :size="15"
          color="white"
          indeterminate
          class="ml-1"
          v-if="loader"
        ></v-progress-circular>
      </v-btn>
    </v-row>
    </v-form>
  </div>
  <div v-else>
    <div class="v-text-field__details exists-msg">
          <div class="error--text" role="alert">
              <div class="text-xs-h1">
                {{labels.ADD_LIMIT}}
              </div>
          </div>
        </div>
        <v-row class="mt-4 d-flex justify-center">
          <v-btn
            text
            color="btn-chat"
            class="mx-4 nocap"
            @click="toggleDialog('createOrganisationWindow',false)"
          >
            <template v-if="labels">
              {{labels.CLS_BTN}}
            </template>
          </v-btn>
    </v-row>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import {
  organisationService,
  manageOrgsService
} from '../../services/organisationService'

export default defineComponent({  
  setup(_, ctx) {
    //SESSION OBJECTS
    const session = ctx.root.$session,
    u_session=session.get("user_session").session;
    const form = ref(null),
    userQuata=ref(true);
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      rules = {
        required: (value) => !!value || labels.ADD_REQ,
        namecounter: (value) => (value.trim()!='' && value.length >= 5) || labels.ADD_NAMETOO,
        rootnamecounter: (value) => (value.trim()!='' && value.length >= 5) || labels.ADD_ROOTNAMETOO,
        email: (value) => {
          return emailPattern.test(value) || labels.ADD_PLEASEEMAIL
        }
      }

    const { toggleDialog, functionsList, preGroupsList,allEmailList, getfunctionList, getPreGroupList,getAllOrgEmail,organisation,getUserQuata } = organisationService();
    //FLEXI LABELS
      const labels=organisation.labels;
      const { createOrganisation } = manageOrgsService(),
      newOrganisation = ref({}),assignfunctionlist = ref([]),

      // Data controls input fields in Add Organisation popup window
      inputFields = [
        {
          field: labels.ADD_NAME,
          // !IMPORTANT! name refer to newOrganisation.name in actual v-model
          model: 'name',
          // !IMPRTANT! don't call the function like rules.required(), will provide error
          rules: [rules.required,rules.namecounter],
          type: '', // empty : text-field, "textarea", 'multiselect',
          // Show required highlight, affected create organisation event
          // required: true
        },
        {
          field: labels.ORGANIZATION_ID,
          model: 'clientId',
          rules: [rules.required],
          // required: true,
          type: '',
        },
        {
          field: labels.RUSER_NAME,
          model: 'rootUserName',
          rules: [rules.required, rules.rootnamecounter],
          // required: true,
          type: '',
        },
        {
          field: labels.ADD_EMAIL,
          model: 'email',
          rules: [rules.required, rules.email],
          // required: true,
          type: '',
        },
        {
          field: labels.ADD_DESC,
          model: 'description',
          type: 'textarea',
          rules: [rules.required],
          // required: true
        },
        // {
        //   field: labels.TICKET_ID,
        //   model: 'tickedId'
        // },
        {
          field: labels.CREATE_GROUP,
          model: 'groups',
          type: 'multiselect',
          selectionList:"",
          v_chip_color: 'secondary'
        },
        {
          field: labels.O_FNS,
          model: 'functions',
          type: 'multiselect2',
          selectionList: "",
          v_chip_color: 'tertiary1'
        }
      ],
      inputFieldsValid = ref(true),
      emailExist = ref(false)
      const funList = ref();
      const groupList = ref();
      const loader = ref(false);
      const valid=ref(false);
      const category=ref('');
      const searchQuery =ref('');
      const windowsize = ref(0);
      window.addEventListener('resize', handleResize);
  
         
      const getAllfunctionList = async() => {
          await getfunctionList(u_session.owner);
          funList.value = functionsList.allFunctionList;
          // alert(JSON.stringify(funList.value));
      }
      const handleResize = function(){
        windowsize.value=window.innerWidth;
      }
      const typefilters = function(category){
          funList.value = functionsList.allFunctionList;
          if (category =="102"){
            funList.value = funList.value.filter(item=>item.type_id == category);
          }else if(category == ""){
            funList.value = functionsList.allFunctionList;
          }else{
            funList.value = funList.value.filter(item=>item.type_id != 102);
          }
      };
      const filteredList = () =>{   
        funList.value=functionsList.allFunctionList; 
        if (searchQuery !=""){
          funList.value= funList.value.filter(list => list.function_name.toUpperCase().indexOf(searchQuery.value.toUpperCase()) !=-1)
        }
      };
      const resetSearch = (searchType)=>{
        if(searchType==true){
          funList.value=functionsList.allFunctionList;
          searchQuery.value='';
        }
      };
      const getAllgroupList = async() => {
          await getPreGroupList(u_session.owner);
          groupList.value = preGroupsList.allGroupList;
      }
      
      const checkExisting = (name,dataarray) => {
        var isExist=false;
        if(dataarray!=undefined){
          dataarray.forEach((fun)=>{
            if(name==fun.function_name){
              isExist=true;
            }
          })
        }
        return isExist;
      }

      const getAllEmail = async() => {
          //await getAllOrgEmail();
      }

      const checkEmail=(key,val) => {
          if(key==='email' && emailPattern.test(val)){
            var array=allEmailList.list;
            if(array.indexOf(val) !== -1) {
              emailExist.value=true;
            }else{
              emailExist.value=false;
            }
          }
      }

    const getAllUserQuata = async () => {
      await getUserQuata();
      userQuata.value = organisation.userQuata;
    };
     
      getAllUserQuata();
      getAllfunctionList();
      getAllgroupList();
      getAllEmail();
    const initNewOrganisation = () => {
      newOrganisation.value = {
        name: '',
        rootUserName: '',
        email: '',
        description: '',
        consumerPublicKey: '',
        ticketId: '',
        groups: [],
        functions: [],
        clientId: ''
      }
    }
    initNewOrganisation()
 
    const useCreateOrganisation = () => {
       form.value.validate()
      // Check if all required fields are fulfilled
      inputFields.forEach((inputField) => {
        // If inputField have rules
        if (inputField.rules) {
          // Test each value in newOrganisation with the related rules
          inputField.rules.forEach((rule) => {
            // If the rest result if not true
            //(when false, it's a string, due to vuetify input field rule props)
            rule(newOrganisation.value[inputField.model]) !== true
              ? (inputFieldsValid.value = false)
              : ''
            //  This can also be a function to assign error messages for each input field
          })
        }
      })


      // Check if any of the input field not pass the condition
      // Email should not existing one 
      if (inputFieldsValid.value && !emailExist.value) {
        createOrganisation(newOrganisation.value)
        // Toggle off the popup window
        //toggleDialog('createOrganisationWindow')
      } else {
        // Input fields not fulfilled
        loader.value=false;
        console.log('x input fileds not fulfilled')

        // Init input fields value after 5 seconds
        setTimeout(() => {
          inputFieldsValid.value = true
        }, 2200)
      }
    }

    // Helper function to remove an item from array
    const removeArrayItem = (array, item) => {
      const index = array.indexOf(item)
      if (index !== -1) {
        array.splice(index, 1)
      }
    }

    // watch(dialogs, (dialogs) => {
    //   if (dialogs.createOrganisationWindow === false) {
    //     initNewOrganisation();
    //   }
    // })
    initNewOrganisation();
    return {
      toggleDialog,
      useCreateOrganisation,
      newOrganisation,
      inputFields,
      removeArrayItem,
      inputFieldsValid,
      assignfunctionlist,
      getAllfunctionList,
      getAllgroupList,
      groupList,
      funList,
      preGroupsList,
      allEmailList,
      getAllOrgEmail,
      checkEmail,
      emailExist,
      organisation,
      labels,
      form,
      loader,
      valid,
      userQuata,
      getUserQuata,
      category,
      searchQuery,
      typefilters,
      filteredList,
      checkExisting,
      resetSearch,
      handleResize
    }
  }
})
</script>

<style lang="scss" scoped>
  // .v-menu__content {
  //   overflow: hidden;
  // }
.required::after {
  content: '*';
  color: red;
  margin-left: 0.3rem;
}
.fun-dd-row {
  background: #fff;
    // padding: 20px 10px;
    
}
.addGrptlt {
  text-align: center;
    color: #000000de;
    margin-bottom: 5px;
}
.fun-dd-lefts .v-list {
  position: relative;
  background-color: #efefef;
}
.fun-dd-lefts .v-list-item{
  padding: 0 5px;
  font-size: 15px;
}
// .fun-dd-lefts .v-list-item:last-child {
//   position: absolute;
//   bottom: 5px;
// }
.fun-dd-right {
  padding-top: 0;
  padding-right: 0;
}

.fun-dd-right .v-list {
  padding-top: 0;
}
.fun-dd-right .v-text-field {
  width: 92%;
  margin: auto;
  position: sticky;
    z-index: 33;
    top: 0;
    background: #fff;
    margin-right: 12px;
  
}
.src-input:focus-visible {
  outline: none;
}
.src-input {
  width: 97%;
    height: 40px;
    border: 2px solid #c3c3c3;
    padding: 8px;
    margin: 10px 0 20px;
    border-radius: 4px;
    position: sticky;
    top: 0;
    z-index: 100;
    background: #fff;
}
.src-field {
  position: sticky;
    top: 0;
    z-index: 100;
    background: #fff;
}
.src-field span {
  position: absolute;
    top: 19px;
    right: 18px;
    z-index: 1000;
    color: #C3C3C3;
}
.left-padding{
background-color: #efefef;
padding: 10px 25px;
}
.right-padding{
  width: 230px;
    padding: 10px 15px 15px 10px;
}
//---------------------------------- mobile view ---------------------------------- 
@media only screen and (min-width: 350px) and (max-width: 767px) {
 ::v-deep(.v-menu__content--fixed) {
  max-width: 95%;
  left: 2.5% !important;
  top: 10% !important;
}
.mbl-col {
  flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
}
.fun-dd-right .v-list-item {
  padding-left: 0;
  padding-right: 0;
}
}
.bg-active {
  font-weight: bold;
  color: #000000de !important;
}

// changed the spacing for the input error message.
.force-override-for-error-msg .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0px !important;
}
</style>