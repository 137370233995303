<template>
  <div>
    <v-container style="padding-left: -30px !important; padding-right: -30px !important;">
      <v-row class="mb-6 mt-2 justify-center" v-if="uAccess.ORG_UPDATEORGGROUP">
        <v-btn
          color="var(--v-tertiary2-base)"
          dark
          class="nocap"
          @click="toggleDialog('manageGroups_createGroup')"
        >
          <v-icon>mdi-account-multiple-plus</v-icon>
          {{labels.ADD_GRP}}
        </v-btn>
        <v-btn
          color="var(--v-tertiary1-base)"
          dark
          class="nocap ml-3"
          @click="toggleDialog('manageGroups_preGroup')"
          v-if="u_session.orgType=='main'"
        >
          <v-icon>mdi-account-multiple-plus</v-icon>
          {{labels.OD_PREDEF_GRP}} 
        </v-btn>

        <v-btn
          plain
          text
          elevation="0"
          color="info"
          class="nocap ml-2 "
          :disabled="!selectedGroups[0]"
          @click="toggleConfirm('deleteSelectedGroups')"
          v-if="u_session.orgType=='main'"
      >
          <v-icon color="info">
              mdi-trash-can-outline
          </v-icon>
          {{labels.OD_DEL_SEL_GRP}}
      </v-btn>
      </v-row>
      <!-- Manage Group table -->
      <!-- <pre>{{ JSON.stringify(organisation.groups, null, '\t') }}</pre> -->
      <v-data-table
        :headers="manageGroups_TableHeader"
        :mobile-breakpoint="0"
        :items="organisation.groups"
        v-model="selectedGroups"
        item-key="group_id"
        :show-select="u_session.orgType=='main'"
        class="elevation-0 s-table-beige s-table b-table"
        style="cursor:pointer"
        @click:row="selectGroup"
      >
        <!-- Slot for counting amount of users in each group -->
        <template v-slot:[`item.groupMembers`]="{ item }">
          <div class="d-flex justify-center">
            {{ item.groupMembers }}
          </div>
        </template>
        <!--  -->
        <template v-slot:[`item.details`]="{}">
          <v-icon color="var(--v-tertiary1-base)">mdi-account-box-multiple</v-icon>
        </template>
      </v-data-table>
    </v-container>

    <!-- -------------------- -->
    <!-- Group Details Window -->
    <!-- -------------------- -->
    <Dialog
      dialog-name="manageGroups_groupDetail"
      :dialog-title="labels.OD_GRP_DETAILS"
      max-width="960"
      :close-button="false"
    >
      <div style="width:100%">
        <v-row class="mb-2 justify-space-between">
          <!-- -------------------------- -->
          <!-- Group name and description -->
          <!-- -------------------------- -->
          <v-col>
            <v-row class="mb-4">
              <b class="col-12 col-sm-3">{{labels.OD_GRP_NM}}:</b>
              <p class="col-12 col-sm-9">
                {{ selectedGroup.details.group_name }}
              </p>
              <!-- <v-text-field
                v-else
                single-line
                outlined
                dense
                class="mb-2 col-12 col-md-6"
                v-model="selectedGroup.details.grpName"
              ></v-text-field> -->
            </v-row>
            <!-- Group Description -->
            <v-row class="mb-4">
              <b class="col-12 col-sm-3">{{labels.OD_GRP_DESC}}:</b>
              <p class="col-12 col-sm-9" v-if="selectedGroup.details.group_description">
                {{ selectedGroup.details.group_description }}
              </p>
              <p class="col-12 col-sm-9" v-else>
                {{labels.NIL}}
              </p>
              <!-- <v-textarea
                v-else
                single-line
                outlined
                dense
                class="mb-2 col-12 col-md-6"
                rows="2"
                no-resize
                v-model="selectedGroup.details.grpDesc"
              ></v-textarea> -->
            </v-row>
          </v-col>

          <!-- Edit group detail button -->
          <!-- <div>
            <v-btn
              v-show="!editingGroupDetails"
              plain
              elevation="0"
              color="rgba(0,0,0,0)"
              class="nocap"
              @click="editGroupDetails()"
            >
              <v-icon>
                mdi-square-edit-outline
              </v-icon>
              Edit
            </v-btn>
          </div> -->
        </v-row>
  <!-- -*ABOVE*- Group detail area --> 
        <v-row class="col-12 elevation-1">
          <v-tabs
            grow
            v-model="groupDetails_tab"
            class="s-border-bottom"
            slider-color="rgba(217,194,162, .3)"
            slider-size="99"
            
          >
            <v-tab class="nocap" active-class>
              {{labels.OD_GRP_FUNS}}
            </v-tab>
            <v-tab class="nocap" default>
              {{labels.OD_GRP_MEMS}}
            </v-tab>
          </v-tabs>
        </v-row>
      
        <!-- =========================== -->

        <!-- Tab Items -->
        <v-tabs-items
          v-model="groupDetails_tab"
          class="elevation-1"
          style="height:40vh; overflow-y:scroll"
        >
          <!-- --------------- -->
          <!-- Group Functions -->
          <!-- --------------- -->
          <v-tab-item key="Functions">
            <div class="pa-4">
              <v-row class="ma-2" v-if="organisation.groupfunctionsList.length!=functionsList.assignOrgFunctions.length">
                <!-- Dropdown selection of functions -->
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }" v-if="uAccess.ORG_ADDFUNCTIONTOGROUP">
                    <v-btn outlined v-bind="attrs" v-on="on" class="nocap justify-space-between drop-size">
                      {{labels.OD_GRP_MANGE_GRP_FUN}}
                      <v-icon style="margin:0">
                        mdi-menu-right
                      </v-icon>
                    </v-btn>
                  </template>

                  <!-- Group selection items -->
                  <v-list style="max-height:60vh">
                    <v-list-item
                      v-for="(func, index) in functionsList.assignOrgFunctions"
                      :key="index"
                      v-show="!checkExisting(func.name,organisation.groupfunctionsList)"
                    >
                      <v-checkbox
                        v-model="organisation.groupfunctionsList"
                        color="tertiary1"
                        :value="func"
                      >
                        <template v-slot:label>
                          <span class="black--text">
                            {{ func.name }}
                          </span>
                        </template>
                      </v-checkbox>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>

              <v-row> </v-row>

              <v-divider class="my-4" v-if="organisation.groupfunctionsList.length!=functionsList.assignOrgFunctions.length"></v-divider>
              <v-row class="ma-2">
                <b>{{labels.OD_GRP_GRP_FUNS}} :</b>
              </v-row>
              <v-chip
                class="ma-1"
                color="tertiary1"
                dark
                close
                small
                v-for="functions in organisation.groupfunctionsList"
                :key="functions.name"
                @click:close="removeFunction(functions)"
              >
                <span v-if="functions.value!=undefined">
                  {{ functions.value }}
                </span>
                <span v-else>
                  {{ functions.name}}
                </span>
              </v-chip>
            </div>
          </v-tab-item>

          <!-- ------------------- -->
          <!-- Manag Group Members -->
          <!-- ------------------- -->
          <v-tab-item key="Users">
            <v-data-table
              :headers="groupMembers_TableHeader"
              :mobile-breakpoint="0"
              :items="organisation.groupuserList"
              item-key="value"
              :hide-default-footer="true"
              class="elevation-0 s-table-dark-gray s-table ma-4"
              style="cursor:pointer"
            >
            </v-data-table>
          </v-tab-item>
          <!-- -ABOVE- Manag Group Members -->
          <!-- =========================== -->
        </v-tabs-items>

        <!-- {{ selectedGroup }} -->
         <!-- Success notification -->
      <v-alert type="success" class="mt-4" dense  v-show="success">
        {{labels.OD_UP_SUC}}
      </v-alert>
        <v-row class="justify-center">
          <v-btn elevation="0" class="nocap ma-4" text @click="cancelEditing()"
            >{{labels.CLS_BTN}}</v-btn
          >
          <v-btn
            :disabled="organisation.groupfunctionsCount == organisation.groupfunctionsList.length"
            elevation="0"
            class="nocap ma-4"
            color="tertiary2"
            :dark="organisation.groupfunctionsCount>organisation.groupfunctionsList.length || organisation.groupfunctionsCount<organisation.groupfunctionsList.length"
            @click="saveChange()"
          >
            <v-icon>mdi-sticker-check-outline</v-icon>
            {{labels.OD_SAVE_CHANGE}}

            <v-progress-circular
              :width="3"
              :size="15"
              color="white"
              indeterminate
              class="ml-1"
              v-if="buttonloader"
        ></v-progress-circular>
          </v-btn>
        </v-row>
      </div>
    </Dialog>

    <!-- ------------------------------- -->
    <!-- Create New Groups Dialog Window -->
    <!-- ------------------------------- -->
    <Dialog
      :dialog-title="labels.OD_CRE_NW_GRP"
      dialog-name="manageGroups_createGroup"
      max-width="760"
      :buttons="false"
      :close-button="false"
    >
      <AddGroup />
    </Dialog>

    <!-- ------------------------------- -->
    <!-- Predefined New Groups Dialog Window -->
    <!-- ------------------------------- -->
    <Dialog
      :dialog-title="labels.SEL_PREDDEF_GRPS"
      dialog-name="manageGroups_preGroup"
      max-width="760"
      :buttons="false"
      :close-button="false"
    >
      <SelectPredefinedGroup />
    </Dialog>

         <!-- Remove Group Confirmation -->
    <!-- ----------------------------------- -->
        <ConfirmBox
            box-name="deleteSelectedGroups"
            :on-confirm-value="selectedGroups"
            :box-title="labels.OD_GRP_CONF_DEL"
            :box-content="labels.OD_GRP_CONF_AREYOU"
            box-class="error-dialog"
            :confirm-btn-text="labels.OD_DEL"
            :cancel-btn-text="labels.ADD_CANCEL"
            confirm-btn-icon="mdi-delete"
        >
        </ConfirmBox>
    <!-- -* ABOVE *- Remove Group Confirmation -->
    

    <v-overlay :value="loader" z-index="6" color="#cececea6">
      <!-- <v-img
        alt="sentient logo"
        class="shrink mt-1"
        contain="true"
        src="@/assets/loader.gif"
        width="200"
      ></v-img> -->
      <loaderimg v-if="loader"/>
    </v-overlay>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import {
  organisationService,
  manageGroupsService
} from '../../services/organisationService.js'
// Components
import Dialog from '../ui/Dialog'
import AddGroup from './AddGroup'
import SelectPredefinedGroup from './SelectPredefinedGroup'
import ConfirmBox from "../ui/ConfirmBox";
import loaderimg from "../loaderimg.vue";

export default defineComponent({
  components: { loaderimg, Dialog, AddGroup,SelectPredefinedGroup,ConfirmBox },
  setup(_, ctx) {
    const session=ctx.root.$session,
    u_session=session.get("user_session").session,
    uAccess=u_session.uAccess;
    const labels = ref({});
    const manageGroups_TableHeader=ref([]);
    const groupMembers_TableHeader=ref([]);
    //**GET LABELS */
    setTimeout(() => {
      labels.value = organisation.labels;
      manageGroups_TableHeader.value = [
      {
        text: labels.value.OD_GRP_NM,
        align: 'left',
        value: 'group_name',
        class: 'space-remove'
      },
      { text: labels.value.GRP_DESC, align: 'left', value: 'group_description' },
      //{ text: labels.value.OD_GRP_MEMS, align: 'left', value: 'users' },
      {
        text: labels.value.GRP_DESC,
        align: 'left',
        value: 'created_by'
      },
      {
        text: labels.value.USER_C_DATE,
        align: 'left',
        value: 'date_created'
      },
      {
        text: labels.value.OD_GRP_DETAILS,
        align: 'center',
        value: 'details'
      }
    ];
    groupMembers_TableHeader.value = [
      { text: labels.value.USER_NAME, align: 'center', value: 'value' },
      //{ text: 'Last Activity', align: 'center', value: 'lastActivity' },
      //{ text: 'Created On', align: 'center', value: 'createdOn' }
    ];
    }, 2000);
    // Import functions from service file
    const { organisation, toggleDialog, functionsList, getGroupList,toggleConfirm,confirmBoxs } = organisationService()
    const { updateGroup,getGroupFunctions } = manageGroupsService()

    const selectedGroups=ref([]),
    loader = ref(false);
    getGroupList();

    // Container for selected group detail (when user open group detail popup window)
    const selectedGroup = ref({
      details: {},
      // !!! Important !!! //
      // Static copy of unmodified details
      // When cancel editing, details reset to this value
      originalValue: {}
      
    })

    // For group details dialog tab display
    let groupDetails_tab = null;

    // If true, will be editing mode
    const editingGroupDetails = ref(false)

    // List of members belongs to selected group
    // For Group Details (window) -> Group Member (tab)
    const selectedGroup_Members = ref([])

    const deleteFunList=ref([]);
    const success=ref(false);
    const buttonloader=ref(false);

    const editGroupDetails = () => {
      editingGroupDetails.value = true
    }

    // When user click group table row, set data in selectedGroup
    const selectGroup = async(detail) => {
      // Init tab to functions
      groupDetails_tab = 'Functions';
      await getGroupFunctions(detail);

      // Reset editing group details state
      editingGroupDetails.value = false
      // Reset selectedGroup
      selectedGroup.value = {
        details: {},
        originalValue: {}
      }

      // detail argument have all parameters from
      // organisationService -> organisation.groups.[the selected group]
      selectedGroup.value.details = detail

      // !!! Critical !!! //
      // Mapping each property in detail to original value, so the value is NOT reactive
      // CAN'T use selectedGroup.value.originalValue = detail, detail is reactive
      // If to so, everything will be updated in real time, and cannot cancel input
      for (let i in Object.keys(detail)) {
        let k = Object.keys(detail)[i]
        selectedGroup.value.originalValue[k] = detail[k]
      }

      // init value
      selectedGroup_Members.value = []
      // Get all users under the selected organisation
      organisation.users.forEach((user) => {
        if (user.groups.indexOf(detail.group_name) !== -1) {
          selectedGroup_Members.value.push(user)
        }
      })

      console.log(detail)

      toggleDialog('manageGroups_groupDetail')
    }
    // -ABOVE- selectGroup() function //

    const toggleEditingGroupDetails = () => {
      editingGroupDetails.value = !editingGroupDetails.value
    }

    const cancelEditing = () => {
      // Reset values in details when user cancel editing
      for (let i in Object.keys(selectedGroup.value.details)) {
        let k = Object.keys(selectedGroup.value.details)[i]
        selectedGroup.value.details[k] = selectedGroup.value.originalValue[k]
      }
  groupDetails_tab = 'Functions';
      toggleDialog('manageGroups_groupDetail')
    }

    const saveChange = () => {
      // Save updated value to selectedGroup detial
      // selectedGroup.value.details.groupName = selectedGroup.value.updated.groupName
      //toggleDialog('manageGroups_groupDetail')
      // Some functions here to call service file and make the API call to update group detail
      buttonloader.value=true;
      success.value=true;
      updateGroup(selectedGroup.value.details,organisation.groupfunctionsList,deleteFunList.value);
      setTimeout(() => {
          success.value=false;
          buttonloader.value=false;
          toggleDialog('manageGroups_groupDetail');
      }, 2000);
    }

    const removeFunction = async(item) => {
      const index = organisation.groupfunctionsList.indexOf(item)
        if (index !== -1) {
          organisation.groupfunctionsList.splice(index, 1);
        }
        if(item.functionId==undefined){
          deleteFunList.value.push(item);
        }
    }

    // Function names exists in dropdown
     const checkExisting = (name,dataarray) => {
      var isExist=false;
      if(dataarray!=undefined && dataarray.length>0){
        dataarray.forEach((fun)=>{
          if(name==fun.value){
            isExist=true;
          }
        })
      }
      return isExist;
    };

    return {
      organisation,
      manageGroups_TableHeader,
      groupMembers_TableHeader,
      selectGroup,
      selectedGroup,
      groupDetails_tab,
      toggleEditingGroupDetails,
      editingGroupDetails,
      cancelEditing,
      toggleDialog,
      functionsList,
      saveChange,
      selectedGroup_Members,
      editGroupDetails,
      u_session,
      uAccess,
      selectedGroups,
      loader,
      toggleConfirm,
      confirmBoxs,
      removeFunction,
      deleteFunList,
      success,
      buttonloader,
      labels,
      checkExisting
    }
  }
})
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 400px){
  .drop-size{
    width: 230px;
  }
}

.b-table {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
</style>