<template>
  <div>
    <!-- <navigation/> -->
    <div>
      <v-breadcrumbs :items="items" v-if="ownerDrop">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner"  :ownerDesc="ownerDesc" v-if="ownerDrop"/>
      </div>
      <v-content class="white maincontent">
        <div class="pb-4">
          <div class="d-flex justify-space-between align-center">
            <div>
              <h2 class="text-center font-weigt-bold">Notifications</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
            </div>
          </div>
          <div
            class="
              d-flex
              flex-md-row flex-sm-column
              justify-end
              align-md-end
              justify-md-space-between">
              <div> 
          <v-chip outlined
            @click="makeSelectedEmpty"
            class="ma-2 white"
          v-if="selectedFun.length"
          >
            <v-icon>mdi-close-circle</v-icon><span>&nbsp;</span>  {{ selectedFun.length }} selected
          </v-chip>
        </div>
          <div>
             <v-btn text class="tertiary2--text nocap py-5" @click.stop="addnotifications = true;">
              <v-icon class="pr-1 add" style="font-size:26px">mdi-checkbox-multiple-marked-circle-outline </v-icon>
                Add Notifications
              </v-btn>
            <v-btn text
              @click.stop="deletenotifications = true"
              :disabled="!selectedFun.length"
              class="error--text nocap py-5"
            >
              <v-icon class="pr-1 add">mdi-delete-circle </v-icon>
              DELETE
            </v-btn>
          </div>
        </div>
          <div>
            <v-data-table
              :mobile-breakpoint="0"
              v-model="selectedFun"
              :headers="headers"
              :items="notificationvalue"
              item-key="id"
              item.value=""
              show-select
              @click:row="editnotifications"
              class="elevation-1 listingTable"
            >
            </v-data-table>
          </div>
        </div>
        <!--------------------------Add notifications start------------------------------------------>

        <v-row justify="center" style="margin-bottom: 25px !important;">
            <v-form ref="form">
              <v-dialog
                class="stepBystep"
                scrollable
                v-model="addnotifications"
                persistent
                max-width="1000px"
              >
                <v-card class="ms-title">
                  <v-card-title>
                    <div class="py-2">
                      <h3 class="text-center">Add Notifications</h3>
                       <div class="closeicon-right">
                      <v-icon   @click="clearObj()" class="text-right"
                        >mdi-close-circle-outline
                      </v-icon>
                      </div>
                    </div>
                  </v-card-title>
                  <v-divider class="my-4"></v-divider>
                  <v-card-text style="height: 400px">
                    <addNotificationInfo :newItems="newItems"/>
                    <div class="px-md-10">
                      <v-alert
                        text
                        :type="response_status"
                        v-if="response_msg.length"
                      >
                        {{ response_msg }}
                      </v-alert>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="px-10 py-6 text-center">
                   
                    <v-btn
                      class="nocap"
                      large
                      text
                      @click="clearObj()"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      class="nocap mx-5"
                      large
                      color="primary"
                      @click="Submit()"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-row>
        <!-------------------------------------end--------------------------------------------------->
        <!-- --------------------------Success pop up ---------------------------------------->
        <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="successNotification"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-img
                    style="z-index: 1"
                    alt="sentient logo"
                    class="d-flex align-center justify-center"
                    src="@/assets/tick-mark1.svg"
                    contain
                    max-width="192"
                  ></v-img>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Success</div>
                  </v-card-title>
                  <p v-if="response_message.length">{{response_message}}</p>
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap" color="primary" @click="successNotification = false">Done </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!--------------------------------------------------- End ----------------------------->
        <!-------------------------------------start delete popup--------------------------------->
        <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="deletenotifications"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-avatar
                    color="error"
                    size="62"
                  >
                    <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Delete Confirmation</div>
                  </v-card-title>
                  Are you sure want to delete the selected function?
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap mx-5" text @click="deletenotifications = false">Cancel </v-btn>
                      <v-btn class="nocap mx-5" color="error" @click="deletenotify()">Delete </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          
        <!---------------------------------end --------------------------------------------------->
        <!-- -------++++ Function Detail Pages +++++--------->
        <v-dialog v-model="detailnotificationdialog" width="1000" scrollable>
            <detailnotification
              @closeDetail="closeDialog"
              :rowItem="selectedRowData"
              @notificationseditopen="notificationseditopen"
            />
          </v-dialog>
          <!-- ----------------------------- -->
          <!-- -------++++ Edit function +++++--------->
          <v-dialog
            v-model="editnotificationsdialog"
            width="1000"
            scrollable
            class="d-none"
            persistent
          >
            <editnotification :newItems="selectedRowData" @closenotifyEdit="closeEdit" @saveFunEditedData="saveNotificationEdit" :response_status="response_status" :response_msg="response_msg" />
          </v-dialog>
          <!-- ----------------------------- -->
      </v-content>
    </div>
    <loaderimg v-if="loader"/>
  </div>
</template> 
<script>
import API_Util from "../services/util/API_Util.js";
import addNotificationInfo from "./notifyInfo/addNotificationInfo.vue";
import editnotification from "./editnotification.vue";
import detailnotification from "./detailnotification.vue";
export default {
  components:{
    addNotificationInfo,
    editnotification,
    detailnotification
  },
  name:"notifications",
  data: () => ({
    notificationvalue:[],
    apiKey: "",
    headers : [],
    ownerDrop : "",
    selectedFun: [],
    selectedOwner : "",
    ownerDesc : "",
    addnotifications : false,
    isValidForm : false,
    detailnotificationdialog : false,
    editnotificationsdialog : false,
    deletenotifications : false,
    selectedRowData: {},
    response_msg : "",
    response_message:"",
    response_status : "",
    successNotification : false,
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Platform Setting - Notifications",
        disabled: true,
        href: "",
      },
    ],
    newItems : {
      id: 0,
      type : "notify",
      message : "",
      version : ""
    }

  }),
  created(){
    this.loader = true;
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
    this.getnotification();
    this.getFlexi();
    this.clearNotificationData();
  },
  methods:{
    clearObj(){
      this.addnotifications = false;
      this.refreshData();
    },
    clearNotificationData(){
      this.newItems = {
      id: 0,
      type : "notify",
      message : "",
      version : ""
    }

    },
    refreshData() {
      this.newItems.msgtype = "notify";
      this.newItems.message = "";
      this.newItems.version = "";
    },
    closeDialog() {
      this.detailnotificationdialog = false;
      this.editnotificationsdialog = true;
    },
    closeEdit(status) {
      this.editnotificationsdialog = status;
    },
    saveNotificationEdit(emittedData) {
      this.newItems = emittedData;
      console.log(this.newItems)
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
        .post(API_Util.URLS.updatenotifications , this.newItems , { headers })
        .then((response) => {
          var resp = response.data;
          if (resp.message == "Successfully Updated" ){
            this.response_message = "Successfully Updated";
            this.successNotification = true;
          } else {
            this.response_msg = "Something Error";
            this.response_status = "Error";
              setTimeout(() => {
                this.response_msg = "";
              }, 2000);
          }
          setTimeout(() => {
            this.clearNotificationData();
            this.editnotificationsdialog = false;
            this.getnotification();
          }, 3000)
        });
      
    },
    getnotification() {
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
        .post(API_Util.URLS.getnotifications , {}, { headers })
        .then((response) => {
          console.log(response)
          this.notificationvalue = response.data.data.all;
          console.log(this.notificationvalue)
          this.loader = false;
        });
    },
    changeOwner(obj) {
      this.loader = true;
      localStorage.setItem("owner", obj.ownerId);
      localStorage.setItem('ownerName', obj.ownerName);
      localStorage.setItem('ownerType', obj.ownerType);
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
      this.$emit('ownerchange',obj.ownerType);
      this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
      this.getnotification();
    },
    getFlexi(){
      
      this.headers = [
        { text:"TYPE", align: "start", sortable: true, value: "type",},
        { text: "MESSAGE", value: "message" },
        { text: "VERSION", value: "version" },
        { text: "CREATED_BY", value: "created_by" },
        { text: "DATE_CREATED", value: "date_created" },
      ]
    },
    notificationseditopen(value) {
      this.detailnotificationdialog = value;
    },
    Submit(){
      var data = this.newItems;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.apiKey,
      };
      API_Util.URLS.axios
        .post(API_Util.URLS.insertnotifications , data, { headers })
        .then((response) => {
          var resp = response.data;
          if (resp.message == "Successfully Inserted" ){
            this.response_message = "Successfully Added";
            this.successNotification = true;
          } else {
            this.response_msg = "Something Error";
            this.response_status = "Error";
              setTimeout(() => {
                this.response_msg = "";
              }, 2000);
          }
          setTimeout(() => {
            this.clearNotificationData();
            this.addnotifications = false;
            this.getnotification();
          }, 3000)
        })
        .catch((err) => console.log(err));
        
        
    },
    deletenotify(){
      const headers = { "x-api-key": this.apiKey };
      this.selectedFun.forEach(function (arrayItem) {
        const data = {
          "id": arrayItem.id,
          "owner_id":localStorage.getItem("owner")
        }
        API_Util.URLS.axios
          .post(API_Util.URLS.deletenotifications , data, {
            headers,
          })
          .then((response) => response)
          .catch((err) => console.log(err));
      });
      this.selectedFun = [];
      this.deletenotifications = false;
      this.getnotification();

    },
    editnotifications(obj) {
      console.log(obj)
      this.detailnotificationdialog = true;
      this.selectedRowData = obj
    },

  }
};
</script>
<style lang="scss">
</style>
