<template>
  <!-- --------------------------- READ ME --------------------------- -->
  <!-- This component is created for sample alert confirmation purpose -->
  <!-- The major props is the alertName, a string that matche the keys -->
  <!-- in alerts and alertOnConfirmFunctions in organisationService.js -->
  <!--                                                                 -->
  <!-- Toggle this alert window by using function :                    -->
  <!-- toggleAlert('alertName'), here alertName will be a string       -->
  <!--                                                                 -->
  <!-- Use :buttons="false" to add customized button, but remember     -->
  <!-- to set the close dialog function when user customnized button   -->
  <!-- --------------------------------------------------------------- -->

  <div>
    <!-- Activate Organisation Confirmation -->
    <!-- ----------------------------------- -->
    <v-dialog
      persistent
      v-model="confirmBoxs[boxName]"
      content-class="s-css-reset"
      style="z-index:999"
      max-width="560"
    >
      <div class="white px-4">
        <v-container :class="boxClass">
          <v-row class="my-4 d-flex justify-center">
            <h3>
              <v-avatar class="confirm-avatar" size="50">
                <v-icon class="confirm-color mr-0" size="40">
                 mdi-alert-circle-outline
                </v-icon>
              </v-avatar>
              {{boxTitle}}
            </h3>
          </v-row>
          
          <v-row class="my-4 d-flex justify-center text-center">
            <span class=" my-2 justify-center text-center " v-html="boxContent">
           </span>
          </v-row>
          <v-row class="my-4 d-flex justify-center">
            <v-btn text          
             
              class="mx-4 nocap"
              @click="toggleConfirm(boxName)"
              v-if="boxClass!='success-dialog'"
            >
              {{cancelBtnText}}
            </v-btn>
            <v-btn
              class="mx-4 nocap confirm-btn white--text"
              @click="buttonLoader=true;boxOnConfirmFunctions[boxName](onConfirmValue)"
            >
            <v-icon style="color:white !important">{{ confirmBtnIcon }}</v-icon>
              {{ confirmBtnText }}
              <v-progress-circular
                      :width="3"
                      :size="15"
                      color="white"
                      indeterminate
                      class="ml-1"
                      v-if="buttonLoader==true && boxClass!='success-dialog'"
                    ></v-progress-circular>
            </v-btn>
          </v-row>
        </v-container>
      </div>
    </v-dialog>
    <!-- -* ABOVE *- Activate Organisation Confirmation -->
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
// Services
import { organisationService } from "../../services/organisationService";

export default defineComponent({
  props: {
    boxName: { type: String, default: "" },
    // Pass some variabled from outside of Alert component to organisationServices.js
    onConfirmValue: {},
    boxTitle: { type: String, default: "Title" },
    boxContent: { type: String, default: "" },
    confirmBtnText: { type: String, default: "Confirm" },
    cancelBtnText: { type: String, default: "Cancel" },
    boxClass: { type: String, default: "" },
    confirmBtnIcon: { type: String, default: "" },
    // Show Cancel and Confirm button
    buttons: { type: Boolean, default: false },
    // Show / Hide top right "x" close button
    closeButton: { type: Boolean, default: true },
    // Controls the max-width property of dialog
    maxWidth: { default: 560 },
    buttonLoader : { type: Boolean, default: false },
  },
  setup() {
    const {
      confirmBoxs,
      toggleConfirm,
      boxOnConfirmFunctions,
    } = organisationService();

    return {
      confirmBoxs,
      toggleConfirm,
      boxOnConfirmFunctions
    };
  },
});
</script>

<style lang="scss" scoped>

</style>