<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>{{ labels.EDI_DISC_DET }}</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text class="px-6">
      <v-form>
        <adddiscount :newDiscount="newDiscount" :microArray="microArray" :labels="labels" />
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <div class="px-md-10">
      <v-alert text :type="response_status" v-if="response_msg.length">
        {{ response_msg }}
      </v-alert>
    </div>
    <v-card-actions>
      <div class="pl-10">
        <v-btn class="nocap" @click="closeDisEdit" text>
          <span class=""> {{ labels.CAN }}</span>
        </v-btn>
        <v-btn class="nocap primary" text @click="save">
          <span class="">{{ labels.SAV }} </span>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import Adddiscount from "./ownerpopup/adddiscount.vue";
import adddiscount from "./ownerpopup/adddiscount.vue";
export default {
  components: {
    adddiscount,
    Adddiscount,
  },
  name: "discounteditpage",
  props: ["newDiscount", "microArray", "response_status", "response_msg", "labels"],

  data: () => ({
    closeDialog: false,
    response_msg: "",
    response_status: "",
    apiKey: "",
    owner: "",
    checkbox: true,
    iconlist: [],
    text: "",
  }),
  methods: {
    created() {
      var sessionObj = this.$session.get("user_session");
      this.apiKey = sessionObj.session.userApiKey;
    },
    closeDisEdit() {
      this.$emit("closeDisEdit", false);
    },
    save() {
      this.$emit("save");
    },
    onUpdate(text) {
      this.text = text;
    },
    onChangefile(fieldname, imageref) {
      this[fieldname] = [...this.$refs[fieldname].files];

      if (fieldname != "imagelist" && this[fieldname].length > 1) {
        this[fieldname].splice(0, this[fieldname].length - 1);
      }

      for (let i = 0; i < this[fieldname].length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.$refs[`${imageref}` + parseInt(i)][0].src = reader.result;
        };
        reader.readAsDataURL(this[fieldname][i]);
      }
    },
  },
};
</script>

<style lang="scss">
.chevron-down-circle-outline {
  color: red;
}
.v-expansion-panel-header__icon .v-icon {
  color: red !important;
}

.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: var(--v-secondary-darken4) !important;
  border: 1px solid var(--v-secondary-darken1) !important;
  border-radius: 100% !important;
}
</style>