<template>
  <v-app app :style="cssProps" v-cloak>
    <!-- <font-awesome-icon icon="user-secret" /> -->
    <router-view name="header" />
    <router-view name="mainContent" />
  </v-app>
</template>

<script>
export default {
  data: () => ({
    primary: "#00a884",
    body: "#dedad7",
    // themeColor:"#a90000",
    secondary: "#00a884",
    tertiary1: "#00a884",
    tertiary2: "#1d1d1d",
    error: "#b22222",
    success: "#4CAF50",
    info: "#ff5252",
    footerText: "#9E9E9E",
    primarytextcolor: "#9E9E9E",
    accent: "#90CAF9",
    amberAlert: "#FFC107",
    primaryBorder1: "#616161",
    primaryBorder2: "#e15555",
    tertiaryBorder1: "#afabab",
    tertiaryBorder2: "#cbac81",
    //
  }),
  created() {
    this.$vuetify.theme.themes.light.primary = this.primary;
    this.$vuetify.theme.themes.light.secondary = this.secondary;
    this.$vuetify.theme.themes.light.body = this.body;
    this.$vuetify.theme.themes.light.tertiary1 = this.tertiary1;
    this.$vuetify.theme.themes.light.tertiary2 = this.tertiary2;
    this.$vuetify.theme.themes.light.error = this.error;
    this.$vuetify.theme.themes.light.success = this.success;
    this.$vuetify.theme.themes.light.info = this.info;
    this.$vuetify.theme.themes.light.footerText = this.footerText;
    this.$vuetify.theme.themes.light.primarytextcolor = this.primarytextcolor;
    this.$vuetify.theme.themes.light.accent = this.accent;
    this.$vuetify.theme.themes.light.amberAlert = this.amberAlert;
    this.$vuetify.theme.themes.light.primaryBorder1 = this.primaryBorder1;
    this.$vuetify.theme.themes.light.primaryBorder2 = this.primaryBorder2;
    this.$vuetify.theme.themes.light.tertiaryBorder1 = this.tertiaryBorder1;
    this.$vuetify.theme.themes.light.tertiaryBorder2 = this.tertiaryBorder2;
  },
};
</script>
