<template>
  <div>
    <!-- <navigation/> -->
    <div>
      <v-breadcrumbs :items="items" v-if="ownerType === 'PM'">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" />
      </div>
      <v-content class="white maincontent">
        <div class="pb-4">
      <div class="d-flex justify-space-between align-center pb-6">
            <!-- <div cols="12 px-0" v-if="ownerType === 'PM'">
              <v-btn text class="nocap px-0" @click="$router.go(-1)">
                <span class="grey--text">
                  <v-icon style="margin-left: -10px">mdi-arrow-left</v-icon>
                  {{ labels.BK }}
                </span>
              </v-btn>
            </div> -->
            <div>
              <h2 class="text-center font-weigt-bold">{{ labels.REFF }}</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
              <!-- <v-btn
                class="tertiary2 white--text nocap py-5"
                @click.stop="addPromocodes"
              >
                <v-icon class="pr-1 add">mdi-plus-circle</v-icon>
                Add Promocodes
              </v-btn> -->
            </div>
          </div>
          <div
            class="
              d-flex
              flex-md-row flex-sm-column
              justify-start
              align-md-end
              justify-md-space-between">
          </div>
          <!----------Searching--------------------- -->
          <!-- <div v-show="ownerType === 'PM' && false">
            <label class="secondary--text text--lighten-1">Editing Referral Codes for</label>
                 <v-combobox  class="mt-4 selectCom"
              v-model="selectedOwner"
              :items="ownerList"
              item-text="ownerName"
              item-value="ownerId"
              :search-input.sync="search"
              label="select owner"
              hide-selected
              @change="changeOwner"
              dens
              solo
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ search }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div> -->
          <!-- ---------------------------- -->
      
          <div>
            <v-data-table
            :mobile-breakpoint="0"
              :headers="headers"
              :items="referralcodeListing"
              @click:row="getReferralList"
              class="elevation-1 listingTable"
            >
            </v-data-table>
          </div>
          <v-row justify="center" style="margin-bottom: 25px !important;">
            <v-form ref="form" v-model="valid">
              <v-dialog
                class="stepBystep"
                scrollable
                v-model="addreferralDialog"
                persistent
                max-width="1000px"
              >
                
              </v-dialog>
            </v-form>
          </v-row>
          
        </div>
      </v-content>
    </div>
    <loaderimg v-if="loader"/>
  </div>
</template> 
<script>
import API_Util from "../services/util/API_Util.js";
import loaderimg from "./loaderimg.vue";
import headline from "./headline.vue";

export default {
     name: "referralcode",
    components: {
    loaderimg,headline,
  },
  data: () => ({
    ownerName: "",
    referraldetailDialog: false,
    addreferralDialog:false,
    referraledit:false,
    selectedOwner: "",
    ownerDesc: "",
    ownerList: [],
    referralcodeListing: [],
    loading: false,
    search: "",
    selectedInt: [],
    selectedMs: [],
    apiKey: "",
    owner_id: "",
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Marketing - Referral Codes",
        disabled: true,
        href: "",
      },
    ],
    loader: true,
    ownerType: "",
    labels: {},
    language: "EN",
  }),

  created(){
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerName = sessionObj.session.ownerName;
    this.ownerType = sessionObj.session.ownerType;
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.owner = localStorage.getItem("owner");
    this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
    this.getFlexi();
    this.getOrgList();
    this.getReferralList();
  },

  methods: {
    changeOwner(obj) {
      localStorage.setItem("owner", obj.ownerId);
      localStorage.setItem('ownerName', obj.ownerName);
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
      this.$emit('ownerchange',obj.ownerType);
      this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
      this.getReferralList();
    },
    async getOrgList() {
      var empt = {}
      await API_Util.URLS.axios
        .post(API_Util.URLS.listOrg, empt, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.ownerList = response.data.results;
        });
    },
     allOwners(){      
       this.$router.push("/ownerlisting", () => { window.location.reload()});  
    },
      closeDialog(value) {
      this.referraldetailDialog = value;
      this.referraledit = true;
    },
    getReferralList(){
      var owner_id = localStorage.getItem("owner");
      const headers = { "Content-Type" : "application/json", "x-api-key" : this.apiKey};
      API_Util.URLS.axios.post(API_Util.URLS.referralList, owner_id, {headers})
      .then((response) => {
        this.referralcodeListing = response.data.data;
        this.loader = false;
      })
    },
    getFlexi() {
      API_Util.URLS.axios
        .post(
          API_Util.URLS.getlblbyscr,
          {
            owner_id: this.owner,
            scrId: "REFERRAL",
            lang: this.language,
          },
          { headers: { "x-api-key": this.apiKey } }
        )
        .then((response) => {
          this.labels = response.data.labels;
          this.headers =  [
                            {
                              text: this.labels.CD,
                              align: "start",
                              sortable: true,
                              value: "code",
                            },
                            { text: this.labels.S_DA, value: "start_date" },
                            { text: this.labels.DESC, value: "desc" },
                            { text: this.labels.CRE, value: "dateUpdated" },
                          ];
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>
<style lang="scss">
.v-input__append-inner {
  margin-top: 11px !important;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 46px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.container {
  max-width: 1224px;
}
.v-menu__content {
  background-color: white;
}
.v-chip .mdi-pencil-box-multiple {
  font-size: 21px !important;
}
.v-btn::before {
  background-color: transparent !important;
}
.v-stepper__step__step {
  height: 12px !important;
  min-width: 12px !important;
  width: 12px !important;
}

.v-stepper {
  // .v-stepper__step__step.primary {
  //     background-color: red !important;
  //     border-color: red !important;
  // }
  .v-divider {
    border-width: thin 0px 3px 0 !important;
  }

  .v-stepper__step {
    padding: 30px 0px 0px;

    hr {
      .v-stepper--alt-labels.v-stepper__header.v-divider {
        margin: 35px -80px 0 !important;
      }
    }
  }
}

//  .v-stepper__step--complete + .v-divider{
//      background-color: red !important;
//   }
.v-stepper__step__step {
  color: transparent !important;
  .v-icon {
    display: none !important;
  }
}
.v-stepper__header {
  box-shadow: none !important;
}

.ms-title {
  .v-card__title {
    text-align: center !important;
    display: block !important;
  }
}
.v-card__actions {
  text-align: center !important;
  display: block !important;
}
</style>
