<template>
  <div class="mbl-msform msForm">
    <v-row>
      <v-col cols="12">
        <label>{{labels.MIC_DT_COLL}}</label>
        <v-text-field
          outlined
          v-model.trim="microAllData.dataCollectMethod"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{labels.MIC_SRC}}</label>
        <v-select
          outlined
          v-model="microAllData.source"
          name="micro_source"
          :items="micro_source"
          item-text="value"
          item-value="code"
          placeholder="Select Microservice Source"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{labels.MIC_SRCLK}}</label>
        <v-text-field
          outlined
          v-model.trim="microAllData.webLink"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <label>{{ labels.MIC_KW }}</label>
        <v-combobox
          outlined
          multiple
          v-model="microAllData.keywords"
          append-icon
          chips
          deletable-chips
          class="tag-input"
        >
        </v-combobox>
      </v-col>

     <v-col cols="12" class="px-6">
      <label>{{labels.MIC_TAG}}</label>
      <v-row class="mt-2 text-fields-row secondary lighten-3">
        <v-col cols="12" class="type">
          <div class="d-flex flex-md-row flex-sm-column mt-3">
            <div class="d-flex flex-column px-2 w100">
              <label>{{labels.MIC_KE}}</label>
              <v-text-field
                outlined
                v-model.trim="tagsObj.key"
                @blur="checkUniqueTags"
              ></v-text-field>
              <span v-if="uniqueTags" style="color: red"
                >{{labels.MIC_KEVAL}}</span
              >
            </div>

             <div class="d-flex flex-column px-2 w100">
              <label>{{labels.MIC_VA}}</label>
              <v-text-field outlined v-model="tagsObj.value"></v-text-field>
             </div>
            

           <div class="d-flex flex-column">
              <span class="ml-1"
                ><v-btn
                  class="nocap primary white--text"
                  style="height: 41px; margin-top:21px;"
                  :disabled="
                    !tagsObj.key ||
                    !tagsObj.value ||
                    microAllData.tags.find((o) => o.key === tagsObj.key)
                  "
                  @click.prevent="addTags(tagsObj.id)"
                >
                  <v-icon>mdi-plus </v-icon>
                </v-btn></span
              >
            </div>      

        </div>
      </v-col>
      </v-row>
     </v-col>
      <v-col cols="12" class="px-0 pt-6" v-if="microAllData.tags.length">
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    <span class="font-weight-boald subtitle-1">{{labels.MIC_KE}}</span>
                  </th>
                  <th>
                    <span class="font-weight-boald subtitle-1"> {{labels.MIC_VA}}</span>
                  </th>
                  <th>
                    <span class="font-weight-boald subtitle-1">{{labels.DEL}}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(tag, index) in microAllData.tags" :key="tag.key">
                  <td>{{ tag.key }}</td>
                  <td>{{ tag.value }}</td>
                  <td @click.prevent="removeTags(index)">
                    <v-icon color="error">mdi-trash-can </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "msAdditionalInfo",
  props: ["microAllData", "micro_source", "labels"],
  data: () => ({
    apiKey: "",
    tagsObj: { id: 0 },
    dataCollectMethod: "",
    source: "",
    webLink: "",
    deltTagIds: [],
    uniqueTags: false,
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
  },
  methods: {
    checkUniqueTags() {
      if (this.microAllData.tags.find((o) => o.key === this.tagsObj.key)) {
        this.uniqueTags = true;
      } else {
        this.uniqueTags = false;
      }
    },
    clearTags(id) {
      if (id > 0) {
        this.tagsObj = { id: id };
      } else {
        this.tagsObj = { id: 0 };
      }
    },
    addTags(id) {
      this.microAllData.tags.push(this.tagsObj);
      if (id > 0) {
        this.tagsObj = { id: id };
      } else {
        this.tagsObj = { id: 0 };
      }
    },
    removeTags(idx) {
      // this.microAllData.deltTagIds = [];
      if (this.microAllData.tags[idx].id > 0) {
        this.deltTagIds.push(this.microAllData.tags[idx].id);
        this.microAllData.deltTagIds = this.deltTagIds;
      }
      this.microAllData.tags.splice(idx, 1);
    },
  },
  computed: {},
  watch: {
    microAllData(data) {
      this.$emit("microDataSubmitted", data);
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>