<template>
  <div class="mbl-msform msForm" style="min-height: 300px;">
    <v-row>
      <v-col cols="12">
        <v-toolbar elevation="0" class="grey lighten-4" >
          <div  class="nocap alertmsg" v-if="microAllData.requestTypes.length == 0">
              Please add the Microservice type
          </div>
          <v-tabs
            v-else
            :mobile-breakpoint="0"
            v-model="pricing_tier"
            class="s-border-bottom"
            active-class="box-color white--text"
            hide-slider>
            <v-tab class="nocap s-border-right col-4" 
              :href="'#'+req" 
              v-for="(req, index) in typearr()" :key="index"
              @click="pricingIndex(index, req)">
              <span v-if="req == 'sync'">
                Small File
              </span>
              <span v-if="req == 'async'">
                Large File
              </span>
              <span v-if="req == 'stream'">
                Streaming
              </span>
            </v-tab>
          </v-tabs>
        </v-toolbar>
      </v-col>
    <!-- </v-row> -->
    <!------Tab items----- -->
    <!-- <v-row> -->
      <v-col cols="12">
        <v-tabs-items
          v-if="microAllData.requestTypes.length > 0"
          transition="fade-transition"
          class="col-12 grey lighten-5"
          v-model="pricing_tier">
          <v-tab-item value="sync">
            <pricingPlanTier :microAllData="microAllData" :labels="labels" :pricingValue="pricingValue" />
          </v-tab-item>
          <v-tab-item value="async">
            <pricingPlanTier :microAllData="microAllData" :labels="labels" :pricingValue="pricingValue"/>
          </v-tab-item>
          <v-tab-item value="stream">
            <pricingPlanTier :microAllData="microAllData" :labels="labels" :pricingValue="pricingValue"/>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import API_Util from '../../services/util/API_Util.js';
import pricingPlanTier from './pricingPlanTier.vue';
export default {
  components:{
    pricingPlanTier
  },
  name: "mspricingPlan",
  props: ['microAllData', 'labels'],
  data: () => ({
    apiKey: "",
    micro_pricing_type: [],
    pricingObj: {id:0, tier: []},
    tierObj: {},
    pricing: [],
    owner: '',
    radios: 'tiered',
    pricing_tier: 'small',
    pricingValue: 0,
    desc: '',
    arr:[],
    newType:{}
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
    this.getFunPricingType();
    this.typearr();
  },
  methods: {
    typearr(){
      this.arr = this.microAllData.requestTypes;
      const unique = [...new Set(this.arr.map(item => item.type))];
      return this.newType = unique
    },
    pricingIndex(indx){
      this.pricingValue = indx;
    },
    clearPricingTier(id) {
      if (this.microAllData.pricing.id > 0) {
        this.pricingObj = {id:id, tier: []};
      } else {
        this.pricingObj = {id:0, tier: []};
      }
      
    },
    addTier() {
      this.pricingObj.tier.push(this.tierObj);
      this.tierObj = {};
    },
    addPricingTier() {
      this.microAllData.pricing.push(this.pricingObj);
      this.pricingObj = {id:0, tier: []};
    },
    async getFunPricingType() {
     var data = {
      "owner_id":localStorage.getItem("owner"),"lang":"EN","code":"PRICING_ENDPOINT_TYPE"
    };
    let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'x-api-key': this.apiKey
            }
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
        this.micro_pricing_type = response;
    },
  },
  watch: {
    microAllData(data) {
      this.$emit('microDataSubmitted', data);
    }
  }
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}

.box-color{
  background-color: #8eaf04 !important;
  max-width: 3000px !important;
}

.space{
  padding-left: 45px;
  margin-top: 10px;
}

.space-text{
  padding-left: 25px;
  margin-top: 9px;
}

.alertmsg{
  padding-left: 200px;
  width: 700px;
  font-size: 20px;
  color: red;
}
</style>