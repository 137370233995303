<template>
  <div class="msForm">
    <v-row>
      <v-col cols="12" sm="6" class="pb-0">
        <label>{{ labels.TAGS }}</label>
      </v-col>
      <v-col cols="12" class="px-2 secondary lighten-3">
        <div class="d-flex justify-space-between flex-md-row flex-sm-column mt-3">
          <div class="d-flex flex-column w100 px-2">
            <label>{{ labels.TAGSKEY }}</label>
            <v-text-field
              outlined
              v-model.trim="tagObj.key"
              @blur="checkUniqueTags"
            ></v-text-field>
            <span v-if="uniqueTags" style="color: red"
              >Keys should be unique</span
            >
          </div>
          <div class="d-flex flex-column w100 px-2">
            <label>{{ labels.TAGSVAL }}</label>
            <v-text-field outlined v-model="tagObj.value"></v-text-field>
          </div>

          <div class="mt-6">
            <!-- <span class="px-2 pb-1">
              <v-btn
                class="nocap"
                outlined
                style="height: 40px"
                @click.prevent="tagObj = { id: 0 }"
                ><v-icon>mdi-autorenew </v-icon>
              </v-btn></span
            > -->
            <span class="px-2 pb-1">
              <v-btn
                class="nocap primary white--text"
                style="height: 40px"
                :disabled="
                  !tagObj.key ||
                  !tagObj.value ||
                  tag.find((o) => o.key === tagObj.key)
                "
                @click.prevent="addTags"
              >
                <v-icon>mdi-plus </v-icon>
              </v-btn></span
            >
          </div>
        </div>
      </v-col>
<!-- ------- -->

      <v-col cols="12" class="px-0 pt-6">
        <v-card>
          <v-simple-table v-if="demoappsAllData.tags.length">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{
                      labels.TAGSKEY
                    }}</span>
                  </th>
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">
                      {{ labels.TAGSVAL }}</span
                    >
                  </th>
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{
                      labels.DEL
                    }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ta, index) in demoappsAllData.tags" :key="index">
                  <td>{{ ta.key }}</td>
                  <td>{{ ta.value }}</td>
                  <td @click.prevent="deleteTags(index)">
                    <v-icon color="error">mdi-trash-can </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>

      <v-col cols="12">
        <label>{{ labels.CAT }}</label>
        <v-select
          outlined
          v-model="demoappsAllData.category"
          :items="demo_category"
          item-text="value"
          item-value="code"
          placeholder="Select Category"
        ></v-select>
        <span
          v-if="!demoappsAllData.category && !isValidForm"
          style="color: red"
          >{{ labels.CATVAL }}</span
        >
      </v-col>

      <v-col cols="12">
        <label>{{ labels.KEYWO }}</label>
        <v-combobox
          outlined
          multiple
          v-model="demoappsAllData.keywords"
          append-icon
          chips
          deletable-chips
          class="tag-input"
          :search-input.sync="search"
        >
        </v-combobox>
      </v-col>
      <v-col cols="12">
        <label>{{ labels.DM_LINK }}</label>
        <v-text-field
          outlined
          placeholder="Demo Link"
          v-model.trim="demoappsAllData.demolink"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <label>{{ labels.CD_LINK }}</label>
        <v-text-field
          outlined
          placeholder="Code Link"
          v-model.trim="demoappsAllData.codelink"
        ></v-text-field>
      </v-col>
      <v-col cols="12" >
        <v-radio-group
          row
          v-model="iconmodel" 
          class="radioposition iconcolor"
        >
          <v-radio
            label="Upload Icon"
            value="UploadIcon"
            @mouseup="uploadicon=true;iconurl=false"
          ></v-radio>
          <v-radio
            label="Icon Url"
            value="IconUrl"
            @mouseup="uploadicon=false;iconurl=true"
          ></v-radio>
        </v-radio-group>
        <div  v-show="uploadicon" >
          <label>{{ labels.DMAPP_LOGO }}</label>
          <div class="" style="width: 135px">
            <div
              class="d-flex flex-column position-relative"
              style="width: 135px"
            >
              <div v-if="demoappsAllData.mIconUrl !='' && demoappsAllData.mIconUrl.startsWith('http')" class="" style="position: relative">
                <button
                  class="pos close-iconPos"
                  type="button"
                  @click="clearLogo"
                  title="Remove file"
                >
                  <v-icon color="error">mdi-close-circle </v-icon>
                </button>
              
              <img
                class="shrink rounded mx-auto logoBoxSize fileimgWidth"
                style="padding: 0px"
                v-if="demoappsAllData.mIconUrl"
                :src="demoappsAllData.mIconUrl"
                alt="Logo Image"
                contain
                aspect-ratio="2"
              />
              </div>
              <div
                v-else
                @dragover.prevent="dragOver"
                @dragleave.prevent="dragLeave"
                @drop.prevent="drop($event, 'logo')"
              >
                <div class="text-center d-flex-column justify-center fileBox">
                  <v-icon class="secondary--text"> mdi-cloud-upload</v-icon>
                  <div>{{ labels.UP_ICON }}</div>
                </div>
                <input
                  type="file"
                  class="clicktofileUpload overflow-hidden w-px h-px opacity-0"
                  @change="fileChange($event.target.files, 'logo')"
                  accept="image/*"
                />
              </div>
            </div>
            
          </div>
        </div>
        <div v-show="iconurl">
          <label>Icon Url</label>
          <v-select
            class="twidth"
            outlined
            :items="demo_apps_iconlist"
            item-text="value"
            item-value="value"
            placeholder="select demoapps icon...."
            v-model="demoappsAllData.mIconUrl"
          ></v-select>
        </div>
      </v-col>
      <v-col cols="12" class="adddemoimg">
        <label>{{ labels.DM_IMAGES }}</label>
        <div class="" style="width: 135px">
          <div
            class="d-flex flex-column position-relative"
            style="width: 135px"
          >
            <div
              @dragover.prevent="dragOver"
              @dragleave.prevent="dragLeave"
              @drop.prevent="drop($event, 'demo_images')"
            >
              <div class="text-center d-flex-column justify-center fileBox">
                <v-icon class="secondary--text"> mdi-cloud-upload</v-icon>
                <div>{{ labels.UP_ICON }}</div>
              </div>
              <input
                type="file"
                multiple="multiple"
                class="clicktofileUpload overflow-hidden w-px h-px opacity-0"
                @change="fileChange($event.target.files, 'demo_images')"
                accept="image/*"
              />
            </div>
          </div>
        </div>
        <div
          v-for="(d_img, index) in demoappsAllData.demo_images"
          :key="index"
          class="d-inline-block mr-3"
        >
          <div class="mt-4 d-inline-flex">
            <div>
              <div style="position: relative">
                <button
                  class="pos close-iconPos"
                  type="button"
                  @click="clearImages(index)"
                  title="Remove file"
                >
                  <v-icon color="error">mdi-close-circle </v-icon>
                </button>
              </div>
              <div>
                <img
                  class="shrink rounded mx-auto logoBoxSize fileimgWidth"
                  style="padding: 0px"
                  :src="d_img.imgUrl"
                  alt="Demo Images"
                  contain
                />
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12">
        <label>{{ labels.URL_CODE }}</label>
        <v-text-field
          outlined
          placeholder="urlcode..."
          v-model.trim="demoappsAllData.urlcodems"
        ></v-text-field>
        <span
          v-if="!demoappsAllData.urlcodems && !isValidForm"
          style="color: red"
          >{{ labels.URL_CODEVAL }}</span
        >
      </v-col>

      <v-col cols="5">
        <label>{{ labels.ASS_FNS }}</label>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              large
              block
              v-bind="attrs"
              v-on="on"
              class="nocap justify-space-between"
              color="tertiary1"
            >
              {{ labels.MIC_ASN_FN }}
              <v-icon style="margin: 0"> mdi-menu-right </v-icon>
            </v-btn>
          </template>

          <!-- Group selection items -->
          <v-list style="max-height: 60vh; overflow-y: scroll">
            <v-list-item
              v-for="fun in filteredMsListing"
              :key="fun.function_id"
            >
              <v-checkbox
                v-model="demoappsAllData.functions"
                color="tertiary1"
                :disabled="
                  demoappsAllData.functions.some(
                    (obj) => obj.function_id == fun.function_id
                  )
                "
                :value="fun"
              >
                <template v-slot:label>
                  <span class="black--text"> {{ fun.function_name }} </span>
                </template>
              </v-checkbox>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>

      <v-col cols="12">
        <v-divider class="my-4"></v-divider>
        <div class="mb-4">
          <v-chip
            class="ma-1"
            close
            color="tertiary1"
            dark
            v-for="(func, index) in demoappsAllData.functions"
            :key="index"
            @click:close="removeArrayItem(demoappsAllData.functions, func)"
          >
            {{ func.function_name }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import API_Util from "../../services/util/API_Util.js";
export default {
  name: "demoAdditionalInfo",
  props: [
    "demoappsAllData",
    "msListing",
    "demo_category",
    "isValidForm",
    "labels",
    "demo_apps_iconlist"
  ],
  data: () => ({
    iconlist: [],
    tagObj: { id: 0 },
    tag: [],
    deleteMsTags: [],
    apiKey: "",
    uniqueTags: false,
    uploadicon:true,
    iconurl:false,
    iconmodel:"UploadIcon",
    urlmodel:'IconUrl',
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    if (this.demoappsAllData.id > 0) {   
      if (this.demoappsAllData.tags !== []) {
        this.tag = this.demoappsAllData.tags;
      }
    } 
  },
  methods: {
    checkUniqueTags() {
      if (this.tag.find((o) => o.key === this.tagObj.key)) {
        this.uniqueTags = true;
      } else {
        this.uniqueTags = false;
      }
    },
    fileChange(files, input) {
      if (input === "logo") {
        this.demoappsAllData.mIconUrl = URL.createObjectURL(files[0]);
        this.demoappsAllData.logo_file = files[0];
      }
      if (input === "demo_images") {
        files.forEach((file) => {
          this.demoappsAllData.demo_images.push({
            imgUrl: URL.createObjectURL(file),
            file: file,
            id: 0,
          });
        });
        // this.demoappsAllData.demo_images = this.demoappsAllData.demo_images;
      }
    },

    clearLogo() {
      this.demoappsAllData.mIconUrl = "";
      this.demoappsAllData.logo_file = "";
    },
    deleteTags(idx) {
      if (this.demoappsAllData.tags[idx].id > 0) {
        this.demoappsAllData.deleteTags = this.deleteMsTags;
        this.demoappsAllData.deleteTags.push(this.demoappsAllData.tags[idx].id);
      }
      this.demoappsAllData.tags.splice(idx, 1);
    },

    clearImages(index) {
      if (this.demoappsAllData.demo_images[index].id > 0) {
        this.demoappsAllData.img_deleted_array.push(this.demoappsAllData.demo_images[index].id);
      }
      this.demoappsAllData.demo_images.splice(index, 1);
      // this.demoappsAllData.demo_images = this.demo_images;
    },
    removeArrayItem(array, item) {
      var ids = {"ids": item.id}
      if (item.id) {
        API_Util.URLS.axios
          .post(API_Util.URLS.deleteDemoappsFunction, ids, {
            headers: { "x-api-key": this.apiKey },
            params: {},
          })
          .then((response) => response)
          .catch((err) => console.log(err));
      }
      const index = array.indexOf(item);
      if (index !== -1) {
        array.splice(index, 1);
      }
    },
    addTags() {
      this.tag.push(this.tagObj);
      this.tagObj = { id: 0 };
      this.demoappsAllData.tags = this.tag;
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event, type) {
      event.preventDefault();
      this.fileChange(event.dataTransfer.files, type); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    // onChangefile(fieldname, imageref) {
    //   this[fieldname] = [...this.$refs[fieldname].files];

    //   if (fieldname != "imagelist" && this[fieldname].length > 1) {
    //     this[fieldname].splice(0, this[fieldname].length - 1);
    //   }

    //   for (let i = 0; i < this[fieldname].length; i++) {
    //     let reader = new FileReader();
    //     reader.onload = () => {
    //       this.$refs[`${imageref}` + parseInt(i)][0].src = reader.result;
    //     };
    //     reader.readAsDataURL(this[fieldname][i]);
    //   }
    // },
  },
  computed: {
    filteredMsListing() {
      return this.msListing.filter((obj) => obj.type_id === "102");
    },
  },
  watch: {
    demoappsAllData: {
      handler(data) {
        this.$emit("demoappsAllDataSubmitted", data);
    if (this.demoappsAllData.id > 0) {
        this.demoappsAllData.demo_images = this.demoappsAllData.images;
      if (this.demoappsAllData.tags !== []) {
        this.tag = this.demoappsAllData.tags;
      }
    } 
        
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">

  .msForm {
    padding: 24px 40px;
  }
.v-input__slot {
  min-height: 40px !important;
}

.mr-md {
  margin-bottom: -30px;
}
.add.v-icon.v-icon {
  font-size: 18px;
}
.detailLabel {
  min-width: 160px;
  font-weight: bold;
}
.colorPanel {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.colorLabel {
  font-weight: bold;
  padding: 0px 0px 0px 18px !important;
  display: flex;
  width: 210px;
  text-align: left;
}
.colorClass {
  width: 100px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 2.5;
}
.imgBox {
  border: 1px solid #aaaaaa;
}
.position-relative {
  position: relative !important;
}
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -29px;
  min-width: 100px;
  max-width: 100px;
  left: 10px;
}
.close-icon {
  position: absolute !important;
  top: 0px;
  right: 0px;
}
#preview img {
  max-width: 100%;
  max-height: 500px;
}
// label {
//   margin-top: 20px;
// }
.fileBox {
  min-width: 100px;
  height: 119px;
  border: 1px grey dashed;
  background-color: aliceblue;
  border-radius: 4px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.pos {
  position: absolute;
  right: -13px;
  top: -13px;
}
.mdi-cloud-upload {
  font-size: 38px !important;
}
.colorPicker {
  .v-input__append-inner {
    div {
      cursor: pointer;
      height: 24px !important;
      width: 84px !important;
      border-radius: 0 !important;
      transition: border-radius 200ms ease-in-out 0s;
      margin: 5px;
      border: 1px solid #060606;
    }
  }
  .v-text-field > .v-input__control > .v-input__slot:before {
    border-style: none !important;
    border-bottom-color: none !important;
    border: none;
  }
  .v-text-field__slot {
    display: none !important;
  }
}
.themeBox {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  // justify-content: space-between;
  .v-responsive__sizer {
    padding: 24% !important;
  }
}
.label-width {
  width: 200px;
}
.colorBox {
  border: 1px solid #d7d7d7;
  padding: 5px;
}
.clicktofileUpload {
  position: absolute;
  top: 0;
  width: 135px;
  height: 140px;
}
.fileimgWidth {
  max-width: 80px;
  min-width: 80px;
  padding: 0px;
}
.color-picker {
  .v-size--default {
    min-width: 50px;
    border-radius: 0;
  }
  .v-btn {
    height: 25px !important;
    min-width: 50px !important;
  }
}
.logoBoxSize {
  // max-width: 80px !important;
  max-height: 64px !important;
}
.close-iconPos {
  position: absolute;
  // bottom: 80px;
  right: 0;
  // height: 135px;
}
.radioposition{
  display:flex;
  flex-direction: row !important;
  padding-right:10px;
  padding-bottom: 15px;
}
.twidth{
  width:50%;
}
/* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .msForm {
    padding: 0;
  }
}
</style>