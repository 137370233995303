<template>
    <div class="px-10 msForm grey lighten-5 rounded-3 scroll-bar" style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          ">
        <!-- -----------Basic Info------------>
        <v-col cols="12" class="px-0 pb-5">
          <h2>Basic Info</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" sm="6">
            <label class="my-4">{{ labels.TIT }}</label>
            <div>{{ microAllData.name }}</div>
          </v-col>
          <v-col cols="12" sm="6" v-if="microAllData.code">
            <label class="my-4">{{ labels.COD }}</label>
            <div>{{ microAllData.code }}</div>
          </v-col>
          <v-col cols="12" sm="6" v-if="microAllData.classText">
            <label class="my-4">{{ labels.MIC_CL }}</label>
            <div>{{ microAllData.classText }}</div>
          </v-col>
          <v-col cols="12" sm="6" v-if="microAllData.fnTypeText">
            <label class="my-4">{{ labels.TYPE }}</label>
            <div>{{ microAllData.fnTypeText }}</div>
          </v-col>
          <v-col cols="12">
            <label class="my-4">{{ labels.MIC_URLC }}</label>
            <div>{{ microAllData.urlCode }}</div>
          </v-col>

          <v-col cols="12" v-if="microAllData.iconUrl.length">
            <label class="my-4">{{ labels.MIC_ICO }}</label><br>
              <div class="colorPanel mt-0">
              <div class="colorClass imgBox d-flex align-center">
                <v-img
                  alt="sentient logo"
                  class="shrink rounded mx-auto d-block"
                  style="padding: 0px"
                  :src="microAllData.iconUrl"
                  min-width="80"
                  max-width="36"
                  contain
                  aspect-ratio="2"
                ></v-img>
              </div>
            </div>
          </v-col>

        </v-row>
        <!-- -----------Descriptions ------------>
        <v-col cols="12" class="px-0 py-6" v-if="microAllData.description1 || microAllData.description2 || microAllData.useCase">
          <h2>{{ labels.DESC }}</h2>
        </v-col>
        <v-row
          v-if="microAllData.description1 || microAllData.description2 || microAllData.useCase"
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" v-if="microAllData.description1">
            <label class="my-4">{{ labels.SHRT }}</label>
            <div>{{ microAllData.description1 }}</div>
          </v-col>
          <v-col cols="12" v-if="microAllData.description2">
            <label class="my-4">{{ labels.LNG }}</label>
            <!-- <div v-html="microAllData.description2">
            </div> -->
              <markdown-it-vue
                class="md-body text-justify mt-3"
                :content="microAllData.description2"
                :options="options"
              >
              </markdown-it-vue>
          </v-col>
          <v-col cols="12" v-if="microAllData.useCase">
            <label class="my-4">{{ labels.USECA }}</label>
            <!-- <div v-html="microAllData.useCase"></div> -->
              <markdown-it-vue
                class="md-body text-justify mt-3"
                :content="microAllData.useCase"
                :options="options"
              >
              </markdown-it-vue>
          </v-col>
        </v-row>
        <!-- Microservice Request Types -->
        <v-col cols="12" class="px-0 py-6" v-if="microAllData.requestTypes.length">
          <h2>Microservices Type</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
          v-if="microAllData.requestTypes.length"
        >
          <v-col cols="12" v-if="microAllData.requestTypes.length">
             <label class="my-4">{{ labels.MICRO_TY }}</label>
             <v-card>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>                                
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >{{ labels.MICRO_TY }}</span
                                  >
                                </th>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >{{ labels.YAM_URL }}</span
                                  >
                                </th>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >{{ labels.MIC_DOCURL }}</span
                                  >
                                </th>

                               
                               
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(req, index) in microAllData.requestTypes" :key="index">
                                <td>{{ req.type }}</td>
                                <td>{{ req.yamlURL }}</td>
                                <td>{{ req.docsURL }}</td>            
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
          </v-col>
        </v-row>
        <!-- -----------Pricing  Plan------------>
        <v-col cols="12" class="px-0 py-6" v-if="microAllData.pricing.length">
          <h2>Pricing and Plan</h2>
        </v-col>
        <v-row
        v-if="microAllData.pricing.length"
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" v-if="microAllData.pricing.length">
             <label class="my-4">Added Pricing Plan</label>
             <v-card>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Plan</span
                                  >
                                </th>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Feature</span
                                  >
                                </th>

                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Description</span
                                  >
                                </th>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Pricing</span
                                  >
                                </th>
                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Number of free call</span
                                  >
                                </th>

                                <th class="text-left">
                                  <span class="font-weight-boald subtitle-1"
                                    >Free limit (Billing)</span
                                  >
                                </th>
                               
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(price, index) in microAllData.pricing" :key="index">
                                <td>{{ price.plan }}</td>
                                <td>{{ price.feature }}</td>
                                <td>{{ price.desc }}</td>
                                <td>{{ price.price }}</td>
                                <td>{{ price.noofcall }}</td>
                                <td>{{ price.freecalls }}</td>
                            
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
          </v-col>
        </v-row>

         <!-- -----------Pricing  Plan------------>

        <!-- -----------Add Data Schema------------>
        <v-col cols="12" class="px-0 py-6" v-if="microAllData.dataSchema.length">
          <h2>Data Schema</h2>
        </v-col>
        <v-row
          v-if="microAllData.dataSchema.length"
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" v-if="microAllData.dataSchema.length">
             <label class="my-4">{{ labels.DSCH }}</label>
             <v-card>
                    <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="lighten-4 text-center grey--text">
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{ labels.DSCH }}</span>
                  </th>
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{ labels.TYPE }}</span>
                  </th>
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{ labels.ISNUL }}</span>
                  </th>
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{ labels.SIZ }}</span>
                  </th>

                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1"
                      >{{ labels.DESC }}</span
                    >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ds, index) in microAllData.dataSchema" :key="index">
                  <td>{{ ds.name }}</td>
                  <td>{{ ds.type }}</td>
                  <td>{{ ds.isnull }}</td>
                  <td>{{ ds.datalength }}</td>
                  <td>{{ ds.description }}</td>                 
                </tr>               
              </tbody>
            </template>
          </v-simple-table>
                      </v-card>
          </v-col>
        </v-row>
        <!-- -----------Additional Info ------------>
        <v-col cols="12" class="px-0 py-6" v-if="microAllData.dataCollectMethod || parsedMicroSource || microAllData.webLink || microAllData.keywords">
          <h2>Additional Info</h2>
        </v-col>
        <v-row
          v-if="microAllData.dataCollectMethod || parsedMicroSource || microAllData.webLink || microAllData.keywords"
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" sm="6" v-if="microAllData.dataCollectMethod">
            <label class="my-4">{{ labels.MIC_DT_COLL }}</label>
            <div>{{ microAllData.dataCollectMethod }}</div>
          </v-col>
          <v-col cols="12" sm="6" v-if="parsedMicroSource">
            <label class="my-4">{{ labels.MIC_SRC }}</label>
            <div>{{ parsedMicroSource }}</div>
          </v-col>
          <v-col cols="12" sm="6" v-if="microAllData.webLink">
            <label class="my-4">{{ labels.MIC_SRCLK }}</label>
            <div>{{ microAllData.webLink }}</div>
          </v-col>
          <v-col cols="12" v-if="microAllData.keywords">
            <label class="my-4">{{ labels.MIC_KW }}</label>
              <v-combobox
              outlined
              multiple
              v-model="microAllData.keywords"
              append-icon
              chips
              class="tag-input"
              >
              </v-combobox>
          </v-col>
          <v-col cols="12" v-if="microAllData.tags.length">
             <label class="my-4">{{ labels.TAGS }}</label>
            <v-card :elevation="hover ? 24 : 2">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.MIC_KE }}</span
                        >
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.MIC_VA }}</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(tag, index) in microAllData.tags" :key="index">
                      <td>{{ tag.key }}</td>
                      <td>{{ tag.value }}</td>                     
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
        <!-- -----------Access Details------------>
        <v-col cols="12" class="px-0 py-6">
          <h2>Access Details</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" v-if="microAllData.accessText">
            <label class="my-4">{{ labels.MIC_ACC }}</label>
            <div>{{ microAllData.accessText }}</div>
          </v-col>
          <v-col cols="12" v-if="microAllData.modelExport">
            <label class="my-4">{{ labels.MIC_MODEL_EXP }}</label>
            <div>{{ microAllData.modelExport }}</div>
          </v-col>

          <!--<v-col cols="12" v-if="microAllData.feature.length">
             <label class="my-4">{{ labels.MIC_FEAT }}:</label>
            <v-card :elevation="hover ? 24 : 2">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.MIC_FEAT_TIT }}</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(fea, index) in microAllData.feature" :key="index">
                      <td>{{ fea.title }}</td>                 
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>-->

        </v-row>
        <!-- -----------Validation Settings------------>
        <v-col cols="12" class="px-0 py-6" v-if="microAllData.mandatoryFields || microAllData.limitFields.length">
          <h2>Validation Settings</h2>
        </v-col>
        <v-row
          v-if="microAllData.mandatoryFields || microAllData.limitFields.length"
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12" v-if="microAllData.mandatoryFields">
            <label class="my-4">{{ labels.MAN }}</label>
              <v-combobox
              outlined
              multiple
              v-model="microAllData.mandatoryFields"
              append-icon
              chips
              class="tag-input"
              >
              </v-combobox>
          </v-col>
          <v-col cols="12" v-if="microAllData.limitFields.length">
             <label class="my-4">{{ labels.LIMTT }}</label>
            <v-card :elevation="hover ? 24 : 2">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.TYPE }}</span
                        >
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.LOW_LIM }}</span
                        >
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.UP_LIM }}</span
                        >
                      </th>

                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{ labels.FIEL }}</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(limits, index) in microAllData.limitFields" :key="index">
                      <td>{{ limits.type }}</td>
                      <td>{{ limits.lowerLimit }}</td>
                      <td>{{ limits.upperLimit }}</td>
                      <td>{{ limits.field }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
</template>

<script>
import MarkdownItVue from "markdown-it-vue";
export default {
  name: "msDuplicateDetail",
  components: {
    MarkdownItVue,
  },
  props: ['microAllData','msDetailDialog', 'micro_source', 'micro_limits', 'request_type','request_version', 'labels'],
  data: () => ({
    options: {
      markdownIt: {
        linkify: true,
      },
      linkAttributes: {
        attrs: {
          target: "_blank",
          rel: "noopener",
        },
      },
    },
  }),
  methods: {
    closeMsDetailDialog() {
      this.$emit('closeMsDetailDialog', false);
    },
    msEditDialog(){
      this.msDetailDialog=false;
      this.msEdit=true;
      this.$emit('closeDetailDialog', false);
    },
    onUpdate(text) {
      this.text = text;
    },
    getLimitText(object) {
      let limitObj =  this.micro_limits.find(obj => obj.code === object, object);
      return limitObj.value;
    },
  },
  computed: {
    parsedMicroSource() {
      if (this.microAllData.source) {
        let micro_source_obj = this.micro_source.find(obj=>obj.code === this.microAllData.source, this.microAllData.source);
        return micro_source_obj.value;
      } else {
        return "";
      }
    }
  }
};
</script>

<style>
.scroll-bar{
  overflow: scroll;
}
</style>