<template>
  <div class="mbl-msform msForm">
    <v-row>
      <v-col cols="12">
        <label>Code</label>
        <v-text-field outlined placeholder="Code" v-model.trim="newSubValue.code" @input="() => (newSubValue.code = newSubValue.code.toUpperCase())"></v-text-field>
        <span v-if="!newSubValue.code && !isValidForm" style="color:red;"> Code is required</span>
      </v-col>
      <v-col cols="12" >
        <label>Value (EN)</label>
        <v-text-field outlined placeholder="Value (EN)" v-model.trim="newSubValue.value"></v-text-field>
        <span v-if="!newSubValue.value && !isValidForm" style="color:red;">Value is required</span>
      </v-col>
      <v-col cols="12">
        <label>Order By</label>
        <v-text-field rows="1" outlined type="number" min="0" placeholder="Order By" v-model.trim="newSubValue.orderBy"></v-text-field>
        <span v-if="!newSubValue.value && !isValidForm" style="color:red;">Value is required</span>
      </v-col>
      <!-- ----------------------------------  -->
      
      <v-col cols="12" class="secondary lighten-3">
        <div class="d-flex align-center">
          <v-row class="text-fields-row">
            <v-col cols="12" sm="5">
              <label>Languages</label>
              <v-select
                outlined
                v-model="lang_obj.lang"
                :items="langItems"
                item-text="language"
                item-value="id"
                placeholder="Language"
                item-disabled="disable"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="5">
              <label>Text</label>
              <v-text-field outlined placeholder="Text" v-model.trim="lang_obj.text"></v-text-field>
            </v-col>

            <v-col class="addflexy-vcol" cols="12" sm="2">
              <!-- <span class="mt-2">
                <v-btn class="nocap" outlined style="height: 40px"
                  ><v-icon @click.prevent="lang_obj={id:0,}">mdi-autorenew </v-icon>
                </v-btn></span
              > -->
              
              <span class="mt-2 ml-2"
                ><v-btn class="nocap primary white-text" style="height: 40px;">
                  <v-icon 
                  :disabled="!lang_obj.lang || !lang_obj.text || lang_array.find(o => o.lang === lang_obj.lang)"
                  @click.stop="add">mdi-plus</v-icon>
                </v-btn></span>
            </v-col>
          </v-row>
        </div>
      </v-col>
           <v-col cols="12">
            <v-card :elevation="hover ? 24 : 2">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr> 
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >Translate Language</span>
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >Text</span
                        >
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >Delete</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(ta, index) in lang_array" :key="index">
                      <td>{{getLang(ta.lang)}}</td>
                      <td>{{ta.text}}</td>      
                       <td @click.prevent="deleteTrans(ta, index)">
                          <v-icon                    
                            color="error"
                            >mdi-trash-can
                          </v-icon></td>                   
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          
      <!-- ----------------------------- -->
    </v-row>
  </div>
</template>

<script>
// import API_Util from '../../services/util/API_Util';
export default {
  name: "addflexyvaluesublist",
  props: ['newSubValue', 'isValidForm', 'langItems', 'valueInfo'],
  data: () => ({
    itemsField: [],
    msType: ["Atomistic", "Pipeline"],
    dropName: [],
    lang: "",
    text: "",
    lang_name: "",
    text_display: "",
    lang_array: [],
    lang_obj: {id:0,},
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    if(this.newSubValue.id > 0){
      if(this.valueInfo.languages !== []){
        this.lang_array = this.valueInfo.languages;
      }
    }
    
  },
  computed: {},

  methods: {
    add(){
      this.langItems.forEach((rowObj) => {
        if(rowObj.id == this.lang_obj.lang){
          rowObj.disable = true
        }
      })
      this.lang_array.push(this.lang_obj);
      this.newSubValue.languages = this.lang_array;
      this.lang_obj = {id:0};
      this.langItems.forEach((row) => {
        this.newSubValue.languages.forEach((obj) => {
          if(obj.lang == row.id){
            obj.lang = row.language
          }
        })
      });
    },
    deleteTrans(item, index){
      this.lang_array.splice(index, 1)
      this.langItems.forEach((row) => {
        if(isNaN(item.lang)){
          if(row.language == item.lang){
            row.disable = false
          }
        }
        else{
          if(row.id == item.lang){
            row.disable = false
          }
        }
      })
    },

    getLang(langId){
      var lang_name = langId
      this.langItems.forEach((row) => {
        if(row.id == langId){
          row.disable = true
          lang_name = row.language
        }
      })
      return lang_name
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
.v-menu__content {
  margin-top: 0px !important;
  margin-left: 0px;
}
</style>
