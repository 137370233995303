<template>
<div>
  <div class="elevation-4 py-5  white d-flex-none">
        <div class="container text-center d-flex flex-column-reverse align-sm-center px-6 mid-footer flex-md-row">
          <div class="d-flex flex-column">
    <div class="text-center d-flex flex-column  align-sm-center justify-center flex-md-row mid-footer">
      <div class="px-4 py-sm-3">
        <span> <a href="https://sentient.io/terms&conditions.htm" class="text-decoration-none coolLink">Terms Of Use</a></span>
      </div>
      <div class="pr-md-4 py-sm-3">
        <span><a href="https://sentient.io/privacy.htm" target="_blank" class="text-decoration-none coolLink">Privacy Policy</a></span>
      </div>  
      <div class="pr-md-4 py-sm-3">
        <span><a href="https://www.kb.sentient.io/" target="_blank" class="text-decoration-none coolLink">Help Center</a></span>
      </div>
      <div class="pr-md-4 py-sm-3">
        <span><a href="https://www.sentient.io/contact" target="_blank" class="text-decoration-none coolLink">Contact Us</a></span>     
    </div>
    </div>
    <div>
      <div class="text-center secondary--text d-flex flex-column px-4 align-sm-center justify-center justify-sm-start flex-md-row mid-footer">Copyright @ 2022 Sentient.io</div>
    </div>
          </div>
   
   
     <v-spacer></v-spacer>
    <div class="text-center d-flex flex-column  align-sm-center justify-end justify-sm-center flex-md-row mid-footer">     
       <a href="https://www.sentient.io/" target="_blank" class="text-decoration-none d-flex align-center justify-center">      
      <v-img contain
        alt="sentient logo"
        class="shrink"
        src="@/assets/sentientlogo.svg"
        min-width="200"
        max-width="403"
    
      ></v-img>
       </a>
    </div>
     </div>      
  </div>
</div>
</template> 
<script>
export default {
  name: "Footer",
  data: () => {
    return {
    currentYear : new Date().getFullYear()
    }
  },
};
</script>
<style lang="scss">
.mid-footer{
.coolLink{
  color:var(--v-secondary-darken4) !important;
}
}
@media screen and (min-width: 599px) {
  .mid-footer {
    align-items: center !important;
  }
}
.container {
  max-width: 1224px;
}
.v-responsive__sizer{
  padding-bottom: 28% !important;
}
</style>
