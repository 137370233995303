<template>
    <div class="text-center">
        <v-dialog
            :value="show"
            hide-overlay
            persistent
            fullscreen
            transition="fade-transition"
        >
            <v-card
                color="rgba(255,255,255,0.6)"
                elevation="0"
                class="d-flex align-center"
            >
                <v-card-text class="d-flex justify-center">
                    <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
// import * as loader from '../../assets/loader.gif';

export default defineComponent({
    setup() {
        const show = ref(false),
            start = () => {
                show.value = true;
            },
            stop = () => {
                show.value = false;
            };
        return { show, start, stop };
    }
});
</script>

<style lang="scss" scoped></style>