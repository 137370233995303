<template>
  <div class="px-6 py-6 msForm">
    <v-col cols="12" class="px-6 secondary lighten-3">
      <label>{{ labels.REQ_TY }}</label>
      <v-row class="mt-2 text-fields-row secondary lighten-3">
        <v-col cols="12" class="type" >
          <v-row>
            <v-col cols="12" xs="12" sm="3" md="3" lg="3">
              <div >
              <label>Select MS Type</label>
              <v-select
                outlined
                v-model="requestTypesObject.type"
                :items="request_type"
                item-text="value"
                item-value="code"
                placeholder="Select MS Type"
                @blur="checkUniqueRequest"
              ></v-select>
              <span v-if="uniqueRequest" style="color: red"
                >Type and Version should be unique</span
              >
            </div>
            </v-col>
            <v-col cols="12" xs="12" sm="3" md="3" lg="3">
              <div class="icon_relates">
                <label>Major Version</label>
                <v-text-field
                  outlined
                  v-model.trim="requestTypesObject.major"
                  oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                  @blur="checkUniqueRequest"
                ></v-text-field>
                <div class="pl-5 pt-3 tool-tip-icon icon_position">
                  <v-tooltip top max-width="200">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon  dark v-bind="attrs" v-on="on">
                        fa-question-circle
                      </v-icon>
                    </template>
                    <span>Major version numbers change whenever there is some significant change being introduced.</span>
                  </v-tooltip>
                </div>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="3" md="3" lg="3">
              <div class="icon_relates">
                <label>Minor Version</label>
                <v-text-field
                outlined
                v-model.trim="requestTypesObject.minor"
                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                @blur="checkUniqueRequest"
              ></v-text-field>
              <div class="pl-5 pt-3 tool-tip-icon icon_position">
                <v-tooltip top max-width="200">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon dark v-bind="attrs" v-on="on">
                      fa-question-circle
                    </v-icon>
                  </template>
                  <span>Minor version numbers change when a new, minor feature is introduced or when a set of smaller features is rolled out.</span>
                </v-tooltip>
              </div>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="3" md="3" lg="3">
              <div class="icon_relates">
                <label>Patch Version</label>
              <v-text-field
                outlined
                v-model.trim="requestTypesObject.patch"
                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                @blur="checkUniqueRequest"
              ></v-text-field>
              <div class="pl-5 pt-3 tool-tip-icon icon_position">
                <v-tooltip top max-width="200">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon  dark v-bind="attrs" v-on="on">
                      fa-question-circle
                    </v-icon>
                  </template>
                  <span>Patch numbers change when a new build of the software is released to customers. This is normally for small bug-fixes.</span>
                </v-tooltip>
              </div>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="5" md="5" lg="5">
              <div>
              <label>{{ labels.YAM_URL }}</label>
              <v-text-field
                outlined
                v-model.trim="requestTypesObject.yamlURL"
              ></v-text-field>
              <span v-if="!requestTypesObject.yamlURL && !microAllData.requestTypes.length && !validForm" style="color: red"
                >{{ labels.YAM_URLVAL }}</span>
            </div>
            </v-col>
            <v-col cols="12" xs="12" sm="5" md="5" lg="5">
              <div>
                <label>{{ labels.MIC_DOCURL }}</label>
              <v-text-field
                outlined
                v-model.trim="requestTypesObject.docsURL"
              ></v-text-field>
              <span v-if="!requestTypesObject.docsURL && !microAllData.requestTypes.length && !validForm" style="color: red"
                >{{ labels.MIC_DOCURLVAL }}</span>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="2" lg="2">
              <div class="d-flex flex-column px-2">
              <span class="ml-2"
                ><v-btn
                  class="nocap primary white--text"
                  style="height: 41px; margin-top:21px;"
                  :disabled="
                    !requestTypesObject.type ||
                    !requestTypesObject.major ||
                    !requestTypesObject.minor ||
                    !requestTypesObject.patch ||
                    !requestTypesObject.yamlURL ||
                    !requestTypesObject.docsURL ||
                    microAllData.requestTypes.find(
                      (obj) => obj['type'] === requestTypesObject.type &&  
                      obj['major'] === requestTypesObject.major &&
                      obj['minor'] === requestTypesObject.minor &&
                      obj['patch'] === requestTypesObject.patch 
                    )
                  "
                  @click.prevent="addRequestType"
                >
                  <v-icon>mdi-plus </v-icon>
                </v-btn></span
              >
            </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <div
      cols="12"
      class="grey lighten-3 my-4"
      v-if="microAllData.requestTypes.length"
    >
      <v-card :elevation="hover ? 24 : 2" style="width: 100%">
        <v-simple-table class="mstable">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  <span class="font-weight-boald subtitle-1"
                    >{{ labels.MICRO_TY }}</span
                  >
                </th>
                <th class="text-left">
                  <span class="font-weight-boald subtitle-1"
                    >MS Version</span
                  >
                </th>
                <th class="text-left">
                  <span class="font-weight-boald subtitle-1">{{ labels.YAM_URL }}</span>
                </th>

                <th class="text-left">
                  <span class="font-weight-boald subtitle-1">{{ labels.MIC_DOCURL }}</span>
                </th>
                <th class="text-left">
                  <span class="font-weight-boald subtitle-1">{{ labels.EDI }}</span>
                </th>
                <th class="text-left">
                  <span class="font-weight-boald subtitle-1">{{ labels.DEL }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(req, index) in microAllData.requestTypes"
                :key="index"
                v-show="!enableEditing"
              >
                <td v-if="req.isEdit">
                  <div  class="pb-3 width" >
                    <label>Select MS Type</label>
                      <v-select 
                        outlined
                        v-model="req.type"
                        :items="request_type"
                        item-text="value"
                        item-value="code"
                        placeholder="Select Request Type"
                      ></v-select>
                  </div>
                </td>
                <td v-else>{{ req.type }}</td>
                <td v-if="req.isEdit">
                  <div class="pb-3 width ">
                    <label>MS Version</label>
                    <v-text-field
                    outlined
                    v-model.trim="req.version"
                    ></v-text-field>
                  </div>
                </td>
                <td v-else>v{{ req.major}}.{{req.minor}}.{{req.patch}}</td>
                <td v-if="req.isEdit">
                  <div  class="pb-3 width">
                    <label>{{ labels.YAM_URL }}</label>
                    <v-text-field
                      outlined
                      v-model.trim="req.yamlURL"
                    ></v-text-field>
                  </div>
                </td>
                <td v-else>{{ req.yamlURL }}</td>
                <td v-if="req.isEdit">
                  <div  class="pb-3 width">
                    <label>{{ labels.MIC_DOCURL }}</label>
                    <v-text-field
                      outlined
                      v-model.trim="req.docsURL"
                    ></v-text-field>
                  </div>
                </td>
                <td v-else>{{ req.docsURL }}</td>
                <td v-if="req.isEdit">
                  <v-icon
                    class="pb-3"
                    color="tertiary1"
                    @click="enableEditTypes(index,'S')"
                    >mdi-content-save-all-outline
                  </v-icon>
                </td>
                <td v-else>
                  <v-icon
                    color="tertiary1"
                    @click="enableEditTypes(index,'E')"
                    >mdi-square-edit-outline</v-icon
                  >
                </td>
                <td v-if="req.isEdit">
                  <!-- <v-icon color="error">mdi-trash-can </v-icon> -->
                </td>
                <td v-else>
                  <v-icon
                    color="error"
                    @click.prevent="removeRequestTypes(index, req.type)"
                    >mdi-trash-can
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import API_Util from "../../services/util/API_Util.js";
import Vue from "vue";
export default {
  name: "mstype",
  props: ["microAllData", "request_type","validForm", "labels"],
  data: () => ({
    owner: "",
    requestTypesObject: { id: 0 },
    enableEditing: false,
    uniqueRequest: false,
    pricingObj: {id:0, tier: []},
    desc: ""
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
    if (this.microAllData.requestTypes.length == 0) {
      this.validForm = false;
    }

  },
  methods: {
    checkUniqueRequest() {
      if (
        this.microAllData.requestTypes.find(
          (obj) => obj["type"] === this.requestTypesObject.type && 
          obj["major"]===this.requestTypesObject.major && 
          obj["minor"]===this.requestTypesObject.minor &&
          obj["patch"]===this.requestTypesObject.patch 
        )     
      ) {
        this.uniqueRequest = true;
      } else {
        this.uniqueRequest = false;
      }
    },
    clearRequestTypesObject(id) {
      if (id > 0) {
        this.requestTypesObject = { id: id };
      } else {
        this.requestTypesObject = { id: 0 };
      }
    },
    removeRequestTypes(idx, req) {
      if (this.microAllData.requestTypes[idx].id > 0) {
        this.microAllData.deleteRequestTypes.push(
          this.microAllData.requestTypes[idx].id
        );
      }
      const pindex = this.microAllData.pricing.findIndex(object => {
        return object.endpointType === req;
      });
      var pricing = this.microAllData.pricing.splice(pindex, 1);
      const headers = {
              headers: { "x-api-key": this.apiKey },
              params: {},
            }
        pricing.forEach(function (arrayItem) {
        var det = {"ids": arrayItem.id, "owner_id": localStorage.getItem("owner")}
        API_Util.URLS.axios
          .post(
            API_Util.URLS.deleteMicroPricingTier, det,
            headers
          )
          .then((response) => response.data)
          .catch((err) => console.log(err));
          det = {}
      });

      this.microAllData.requestTypes.splice(idx, 1);
      if (!this.microAllData.requestTypes.length){
        this.validForm = false;
      } else {
        this.validForm = true;
      }
    },
    enableEdit() {
      this.enableEditing = true;
    },
    enableEditTypes(index,from) {
      this.enableEditing = true;
      if(from=="E"){
        Vue.set(this.microAllData.requestTypes[index], "isEdit", true);
      }else{
        Vue.set(this.microAllData.requestTypes[index], "isEdit", false);
      }
      this.enableEditing = false;

    },
    addRequestType() {
      this.requestTypesObject.version="v"+this.requestTypesObject.major+"."+this.requestTypesObject.minor+"."+this.requestTypesObject.patch;
      this.requestTypesObject.isEdit=false;
      this.validForm = true;
      this.microAllData.requestTypes.push(this.requestTypesObject);
      this.microAllData.pricing.push({"id":0, "callperapi": "", "desc": this.desc, "tier": this.pricingObj.tier, "endpointType": this.requestTypesObject.type, 
      "freecalls": 0, "mincostperapi": 0, "feature": "", "plan": "", "price": "", "noofcall": ""})
      this.requestTypesObject = { id: 0 };
    },
  },
  watch: {
    microAllData(data) {
      this.$emit("microDataSubmitted", data);
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
.typt {
  .v-input__append-inner {
    margin-top: 6px !important;
  }
}
.width{
  width:180px;
}
.type{
  padding:12px 0;
}
.main-version .v-badge{
  left:8px;
  top:-2px;
}
.mstable .v-data-table__wrapper::-webkit-scrollbar {

height: 7px;
}
.mstable .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: #CBAC81;
  border-radius: 4px;
  // box-shadow: inset 0 0 6px rgb(0 0 0 / 50%)
}
.icon_relates{
position:relative;
}
.icon_position{
  position:absolute;
  top: -15px; 
  right: 65px;
}
.tool-tip-icon{
  font-size: 20px !important;
  color: var(--v-primarytextcolor-darken1) !important;
  cursor: pointer !important;
}

</style>