
<template>
    <div>
        <!-- <navigation/> -->
        <div>
            <v-breadcrumbs :items="items" v-if="ownerDrop">
                <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                </template>
            </v-breadcrumbs>
            <div class="owner-tlt">
                <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" v-if="ownerDrop" />
            </div>
            <v-content class="white maincontent">
                <div class="pb-4">
                    <div class="d-flex justify-space-between align-center">
                        <div>
                            <h2 class="text-center font-weigt-bold">{{ labels.MS }}</h2>
                        </div>
                        <div class="text-right">
                            <v-spacer></v-spacer>
                        </div>

                    </div>
                    <div class="d-flex selected-border pb-5">
                        <p class="pb-3 mt-4 mr-4 mb-0" :class="tabstatus == 'pending' ? 'active-chip' : ''"
                            @click="tabstatus = 'pending', searchText = '', getheader('pending')">{{ labels.PENDING }} <v-chip
                                color="c5c5c5">{{ regmslisting.pending.length }}</v-chip></p>
                        <p class="pb-3 mt-4 mr-4 mb-0" :class="tabstatus == 'approved' ? 'active-chip' : ''"
                            @click="tabstatus = 'approved', searchText = '', selectedMicro = [], getheader('approved')">{{
                                labels.APPROVED }}<v-chip color="#c5c5c5"> {{ regmslisting.approved.length }}</v-chip></p>
                        <p class="pb-3 mt-4 mr-4 mb-0" :class="tabstatus == 'rejected' ? 'active-chip' : ''"
                            @click="tabstatus = 'rejected', searchText = '', selectedMicro = [], getheader('rejected')">{{
                                labels.REJECTED }}<v-chip color="#c5c5c5"> {{ regmslisting.rejected.length }}</v-chip></p>
                    </div>
                    <v-divider class="mt-1"></v-divider>
                    <div class="
                            d-flex
                            flex-md-row flex-sm-column
                            justify-start
                            align-md-end
                            justify-md-space-between
                            my-4">
                        <div style="width: 30%;">
                            <v-text-field class="search-bar sen-bg-white" v-model="searchText" clearable
                                @click:clear="resetSearch(tabstatus)" solo hide-details append-icon="mdi-magnify"
                                :label="Search" prepend-inner-icon="mdi-refresh"
                                v-on:keyup="findText(tabstatus)"></v-text-field>
                        <!-- <v-chip outlined @click="makeSelectedEmpty" class="ma-2 white" v-if="selectedmicro.length">
                                <v-icon>mdi-close-circle</v-icon><span>&nbsp;</span> {{ selectedmicro.length }} selected
                                </v-chip> -->
                        </div>
                        <div>
                            <v-btn text class="tertiary2--text nocap py-5" @click.stop="regMicroserve()">
                                <v-icon class="pr-1 add" style="font-size: 26px">
                                    mdi-view-dashboard-edit-outline
                                </v-icon>
                                {{ labels.REGMS }}
                            </v-btn>
                            <v-btn text @click.stop="approvems = true" v-if="tabstatus == 'pending' && ownerType == 'PM'"
                                :disabled="!selectedmicro.length" class="tertiary2--text nocap py-5">
                                <v-icon class="pr-1 add" style="font-size: 26px">
                                    mdi-check-circle
                                </v-icon>
                                {{ labels.APPROVE }}
                            </v-btn>
                            <v-btn text @click.stop="rejectms = true; rejection = ''"
                                v-if="tabstatus == 'pending' && ownerType == 'PM'" :disabled="!selectedmicro.length"
                                class="warning--text nocap py-5">
                                <v-icon class="pr-1 add" style="font-size: 26px">
                                    mdi-close-circle
                                </v-icon>
                                {{ labels.REJECT }}
                            </v-btn>
                            <v-btn text @click.stop="deletems = true" :disabled="!selectedmicro.length"
                                class="error--text nocap py-5">
                                <v-icon class="pr-1 add" style="font-size: 26px">
                                    mdi-trash-can
                                </v-icon>
                                {{ labels.DEL }}
                            </v-btn>
                        </div>
                    </div>
                    <!-----------  Register Microservice Table  ------------------->
                    <div>
                        <v-data-table :mobile-breakpoint="0" :single-select="tabstatus == 'pending' ? true : false"
                            v-model="selectedmicro" :headers="header" :search="search_label"
                            :items="regmslisting[tabstatus]" item-key="fun_id" item-value="fun_id" show-select
                            @click:row="openMsDetailPage" class="elevation-1 listingTable">
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>
                                        <v-checkbox v-model="selectedmicro" :value="item" @click="selected_micro(item)"
                                            style="margin: 0px; padding: 0px" hide-details multiple color="dark-grey" />
                                    </td>
                                    <td @click="openMsDetailPage(item)">{{ item.fun_name }}</td>
                                    <td @click="openMsDetailPage(item)">{{ item.fun_type }}</td>
                                    <td @click="openMsDetailPage(item)">{{ item.fun_desc }}</td>
                                    <td @click="openMsDetailPage(item)" v-if="tabstatus === 'pending'">{{ item.fun_endpoint
                                    }}</td>
                                    <td @click="openMsDetailPage(item)" v-if="tabstatus === 'approved'">{{
                                        item.fun_model_status }}</td>
                                    <td @click="openMsDetailPage(item)" v-if="tabstatus === 'rejected'">

                                        <span v-if="fun_model_status">Deployment error </span>
                                        <span v-else>Manual Reject</span>
                                        <span v-if="item.fun_error_msg" class="ml-1">- {{ item.fun_error_msg }}</span>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                    <!------------Register Microservice Form ------------------->
                    <v-row justify="center">
                        <v-form>
                            <v-dialog class="stepBystep" scrollable v-model="regmicroservices" persistent
                                max-width="1000px">
                                <v-card class="ms-title">
                                    <v-card-title>
                                        <div class="py-2">
                                            <h3 class="text-center">{{ labels.REGMS }}</h3>
                                            <div class="closeicon-right">
                                                <v-icon @click="clearObjectData" class="text-right">mdi-close-circle-outline
                                                </v-icon>
                                            </div>
                                        </div>
                                    </v-card-title>
                                    <v-divider class="my-4"></v-divider>
                                    <v-card-text style="height: 400px" class="basicIfo-sec">
                                        <div>
                                            <v-stepper alt-labels v-model="currentStep" flat class="stepper sticky">
                                                <v-stepper-header flat>
                                                    <template v-for="(item, index) in stepperData">
                                                        <v-stepper-step :key="item.id" :complete="currentStep > index + 1"
                                                            :step="index + 1">
                                                            <span class="caption"> {{ item.title }}</span>
                                                        </v-stepper-step>
                                                        <v-divider :key="item.id"></v-divider>
                                                    </template>
                                                </v-stepper-header>
                                                <v-stepper-items>
                                                    <v-stepper-content step="1">
                                                        <v-col cols="12" class="pb-0 px-6">
                                                            <h3 class="px-3">{{ labels.MSD }}</h3>
                                                        </v-col>
                                                        <regMsDetails :regmicroalldata="regmicroalldata"
                                                            :validForm="validForm" :labels="labels"
                                                            :uploadSourceCode="uploadSourceCode" :datacheck="datacheck"
                                                            :response_message="response_message" />
                                                    </v-stepper-content>
                                                    <v-stepper-content step="2">
                                                        <v-col cols="12" class="px-10">
                                                            <h3 class="px-3">{{ labels.APID }}</h3>
                                                        </v-col>
                                                        <regApiDetails :regmicroalldata="regmicroalldata"
                                                            :validForm="validForm" :labels="labels"
                                                            :response_message="response_message" />
                                                    </v-stepper-content>

                                                    <v-stepper-content step="3">
                                                        <v-col cols="12" class="px-10">
                                                            <h3 class="px-3">{{ labels.DOCD }}</h3>
                                                        </v-col>
                                                        <regDocsDetails :regmicroalldata="regmicroalldata"
                                                            :validForm="validForm" :labels="labels"
                                                            :uploadYaml="uploadYaml" />
                                                    </v-stepper-content>
                                                </v-stepper-items>
                                            </v-stepper>
                                        </div>
                                    </v-card-text>
                                    <v-card-actions class="px-10 py-6 text-center">
                                        <v-btn class="nocap" large text @click="clearObjectData" v-if="currentStep == 1">
                                            {{ labels.CANCEL }}
                                        </v-btn>
                                        <v-btn class="nocap mx-5" large text @click="backStep()" v-if="currentStep > 1">
                                            {{ labels.BACK }}
                                        </v-btn>
                                        <v-btn class="nocap mx-5" large v-if="currentStep < 3" color="primary"
                                            @click="nextStep()">

                                            {{ labels.NEXT }}
                                        </v-btn>
                                        <v-btn class="nocap mx-5" large v-if="currentStep == 3" color="primary"
                                            @click="save()">
                                            {{ labels.SAVE }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-form>
                    </v-row>

                    <!-- Success Register MS pop up  -->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="successms"
                            persistent max-width="388" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-title class="text-h5 text-bold d-flex text-center pa-0"
                                    style="margin-bottom: -19px">
                                    <v-img style="z-index: 1;height: 40px;" alt="sentient logo"
                                        class="d-flex align-center justify-center" src="@/assets/tick-mark1.svg"
                                        contain></v-img>
                                </v-card-title>
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-4">{{ labels.SUCCESS }}</div>
                                    </v-card-title>
                                    <p v-if="response_msg.length">{{ response_msg }}</p>
                                    <v-card-actions>
                                        <div class="text-center mt-4">
                                            <v-btn class="nocap" color="primary" @click="successPopupClose">{{ labels.DON }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!-- End -->

                    <!-- file Uploading pop up  -->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="uploadms"
                            persistent max-width="388" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                            <!-- <v-card-title class="text-h5 text-bold d-flex text-center pa-0"
                                    style="margin-bottom: -19px">
                                    
                                    </v-card-title> -->
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-2">{{ file_name }} uploading...</div>
                                    </v-card-title>

                                    <v-progress-linear v-if="upload_count > 0" v-model="upload_count" color="primary"
                                        height="20">
                                        <template v-slot:default="{ value }">
                                            <strong>{{ Math.ceil(value) }}%</strong>
                                        </template>
                                    </v-progress-linear>

                                    <v-img style="z-index: 1; height: 40px;" alt="sentient logo"
                                        class="mt-2 d-flex align-center justify-center" src="@/assets/loading.gif"
                                        contain></v-img>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!-- End -->

                    <!--  Error pop up  -->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="errorms"
                            persistent max-width="388" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                            <!-- <v-card-title class="text-h5 text-bold d-flex text-center pa-0"
                                    style="margin-bottom: -19px">
                                    
                                    </v-card-title> -->
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-2">{{ labels.INVALID }}</div>
                                    </v-card-title>
                                    <p v-if="response_msg.length">{{ response_msg }}</p>
                                    <v-card-actions>
                                        <div class="text-center mt-4">
                                            <v-btn class="nocap" color="primary" @click="successPopupClose">{{ labels.OK }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!-- End -->

                    <!--------------------- Microservice delete pop up ---------------------------->
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="deletems"
                            persistent max-width="388" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-title class="text-h5 text-bold d-flex text-center pa-0"
                                    style="margin-bottom: -19px">
                                    <v-avatar color="error" size="40">
                                        <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                                    </v-avatar>
                                </v-card-title>
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-4">{{ labels.DELCON }}</div>
                                    </v-card-title>
                                    {{ labels.DEL_CON }}
                                    <v-card-actions>
                                        <div class="text-center mt-4">
                                            <v-btn class="nocap mx-5" text @click="deletems = false">{{
                                                labels.CANCEL }}</v-btn>
                                            <v-btn class="nocap mx-5" color="error"
                                                @click="statusMicroservices('Delete',)">{{ labels.DEL }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="approvems"
                            persistent max-width="388" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-title class="text-h5 text-bold d-flex text-center pa-0"
                                    style="margin-bottom: -19px">
                                    <v-img style="z-index: 1;height: 40px;" alt="sentient logo"
                                        class="d-flex align-center justify-center" src="@/assets/tick-mark1.svg"
                                        contain></v-img>
                                </v-card-title>
                                <v-card-text class="text-center white rounded-lg" style="height: 200px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-4">{{ labels.APPCON }}</div>
                                    </v-card-title>
                                    {{ labels.APP_CON }}
                                    <v-card-actions>
                                        <div class="text-center mt-4">
                                            <v-btn class="nocap mx-5" text @click="approvems = false">{{
                                                labels.CANCEL }}</v-btn>
                                            <v-btn class="nocap mx-5" color="primary"
                                                @click="statusMicroservices('Approved')">{{ labels.APPROVE }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <v-row justify="center">
                        <v-dialog class="mspop" style="box-shadow: none; background-color: transparent" v-model="rejectms"
                            persistent max-width="450" content-class="vDialog">
                            <v-card class="d-flex flex-column pa-0 align-center justify-center" flat
                                style="background-color: transparent">
                                <v-card-title class="text-h5 text-bold d-flex text-center pa-0"
                                    style="margin-bottom: -19px">
                                    <v-avatar color="warning" size="40">
                                        <v-icon class="white--text text-h5">mdi-close-circle</v-icon>
                                    </v-avatar>
                                </v-card-title>
                                <v-card-text class="text-center white rounded-lg" style="height: 300px">
                                    <v-card-title class="text-h5 text-bold d-flex justify-center">
                                        <div class="mt-4">{{ labels.REJCON }}</div>
                                    </v-card-title>
                                    {{ labels.REJ_CON }}
                                    <v-col>
                                        <v-textarea v-model="rejection" auto-grow outlined placeholder="Reject comments..."
                                            rows="3" row-height="10"></v-textarea>
                                    </v-col>
                                    <v-card-actions>
                                        <div class="text-center mt-4">
                                            <v-btn class="nocap mx-2" text @click="rejectms = false">{{
                                                labels.CANCEL }}</v-btn>
                                            <v-btn class="nocap mx-2" color="warning" :disabled="rejection == ''"
                                                @click="statusMicroservices('Rejected', rejection)">{{ labels.REJECT }}
                                            </v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                    <!-- End -->
                    <!-- -------Register Microservice Detail Pages --------->
                    <v-dialog v-model="regmsdetaildialog" width="1000" scrollable>
                        <registerMsDetailPage :regmicroalldata="regmicroalldata" :labels="labels"
                            @closeMsDetailDialog="closeMsDetailDialog" :regmsdetaildialog="regmsdetaildialog"
                            :regmseditdialog="regmseditdialog" @editDialog="closeDialog" :tabstatus="tabstatus" />
                    </v-dialog>
                    <!-- -------Register Microservice Edit Pages --------->
                    <v-dialog v-model="regmseditdialog" width="1000" scrollable class="">
                        <registerMsEditPage :regmicroalldata="regmicroalldata" :validForm="validForm" :labels="labels"
                            :datacheck="datacheck" :response_message="response_message" @closeRegMsEdit="closeEdit"
                            @regMsEditSave="save()" />
                    </v-dialog>
                </div>
            </v-content>
        </div>
        <loaderimg v-if="loader" />
    </div>
</template> 
<script>

import loaderimg from "./loaderimg.vue";
import API_Util from "../services/util/API_Util.js";
import headline from "./headline.vue";
import registerMsDetailPage from "./registerMsDetailPage.vue";
import registerMsEditPage from "./registerMsEditPage.vue";
import regMsDetails from "./regMsPopUp/regMsDetails.vue";
import regApiDetails from "./regMsPopUp/regApiDetails.vue";
import regDocsDetails from "./regMsPopUp/regDocsDetails.vue";

export default {
    components: {
        loaderimg,
        headline,
        registerMsDetailPage,
        registerMsEditPage,
        regMsDetails,
        regApiDetails,
        regDocsDetails
    },
    name: "registerMicroservice",
    data: () => ({
        response_message: "",
        mspop: false,
        search_label: "",
        owner: "",
        user: "",
        apiKey: "",
        selectedmicro: [],
        regmslisting: [],
        regmsdetaildialog: false,
        regmseditdialog: false,
        regmicroservices: false,
        checkbox: false,
        response_msg: "",
        response_status: "",
        loading: false,
        searchText: "",
        deletems: false,
        approvems: false,
        rejectms: false,
        successms: false,
        uploadms: false,
        errorms: false,
        response: "",
        datacheck: {},
        version: "",
        rejection: "",
        regmicroalldata: {
            id: 0,
            name: "",
            description: "",
            deployment_type: "",
            resource_cpu: "",
            resource_memory: "",
            env_variables: [],
            enable_gpu: "yes",
            model_image_port: "",
            endpoint: "",
            // teamspace_code: localStorage.getItem("ownerCode"),
            microservice_code: "",
            result_type: "",
            version_type: "major",
            version: "",
            model_image_source: "",
            docs_yaml: "",
            model_image_url: "",
            docs_yaml_url: ""

        },
        validForm: true,
        items: [
            {
                text: "Owners",
                disabled: false,
                href: "/ownerlisting",
            },
            {
                text: "",
                disabled: false,
                href: "",
            },
            {
                text: "Deployments - Register Microservice",
                disabled: true,
                href: "",
            },
        ],
        stepperData: [
            {
                title: "Microservice Details",
                id: 0
            },
            {
                title: "API Details",
                id: 1
            },
            {
                title: "Documentation Details",
                id: 2
            },

        ],
        loader: false,
        ownerType: "",
        labels: {},
        language: "EN",
        ownerDrop: "",
        ownerDesc: "",
        currentStep: 1,
        isSuperAdmin: localStorage.getItem('owner') == 1,
        tabstatus: "pending",
        upload_count: 0,
        file_name: "",
        templist: {},
        selected_row: {},
    }),

    created() {
        var sessionObj = this.$session.get("user_session");
        this.apiKey = sessionObj.session.userApiKey;
        this.ownerName = sessionObj.session.ownerName;
        this.owner_Id = sessionObj.session.owner;
        this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
        this.selectedOwner = localStorage.getItem("ownerName");
        this.owner = localStorage.getItem("owner");
        this.user = sessionObj.session.id;
        this.orgId = localStorage.getItem("orgId");
        this.ownerDesc = localStorage.getItem('ownerDesc');
        localStorage.setItem('orgId', this.owner);
        this.ownerType = sessionObj.session.ownerType;
        this.ownerCode = sessionObj.session.ownerCode;
        this.items[1].text =
            "Sentient.io (" + localStorage.getItem("ownerName") + ")";
        this.items[1].href = "/ownersettings";
        this.getFlexi();
        this.getheader();
        this.getRegMicroserviceList()
        this.dataCheck();
    },
    methods: {
        makeSelectedEmpty() {
            this.selectedmicro = [];
        },
        successPopupClose() {
            this.successms = false;
            this.uploadms = false;
            this.errorms = false;
        },
        changeOwner(obj) {
            this.loader = true;
            localStorage.setItem("owner", obj.ownerId);
            localStorage.setItem("ownerName", obj.ownerName);
            localStorage.setItem("orgId", obj.id);
            localStorage.setItem('ownerType', obj.ownerType);
            this.selectedOwner = obj.ownerName;
            this.ownerDesc = obj.desc;
            localStorage.setItem('ownerDesc', obj.desc);
            this.$emit('ownerchange', obj.ownerType);
            this.items[1].text =
                "Sentient.io (" + localStorage.getItem("ownerName") + ")";
            this.isSuperAdmin = localStorage.getItem('owner') == 1,
                this.getLangList();
            this.getFlexyLabelList();
        },
        clearObjectData() {
            this.regmicroservices = false;
            this.clearMicroAllData();
        },
        changeStepper() {
            this.stepperData = [
                {
                    title: "Microservice Details",
                    id: 0
                },
                {
                    title: "API Details",
                    id: 1
                },
                {
                    title: "Documentation Details",
                    id: 2
                },

            ]
        },
        clearMicroAllData() {
            this.regmicroalldata = {
                id: 0,
                name: "",
                description: "",
                deployment_type: "new",
                resource_cpu: "",
                resource_memory: "",
                env_variables: [],
                model_image_port: "",
                endpoint: "",
                // teamspace_code: localStorage.getItem("ownerCode"),
                microservice_code: "",
                version_type: "major",
                version: "",
                result_type: "getresults",
                model_image_source: "",
                docs_yaml: "",
                enable_gpu: "yes",
                model_image_url: "",
                docs_yaml_url: "",
            };
            this.response_message = "";
            this.response_status = "";
            this.currentStep = 1;
            this.validForm = true;
        },
        regMicroserve() {
            this.regmicroservices = true;
            this.clearMicroAllData();
            this.teamspace_code();

        },
        findText(tab) {
            var listdata = localStorage.getItem("temp_reg");
            if (this.searchText != '') {
                this.regmslisting[tab] = JSON.parse(listdata)[tab].filter(list => list.fun_name.toUpperCase().indexOf(this.searchText.toUpperCase()) != -1)
            } else {
                this.regmslisting[tab] = JSON.parse(listdata)[tab];
            }
        },
        resetSearch(tab) {
            this.searchText = '';
            var listdata = localStorage.getItem("temp_reg");
            this.regmslisting[tab] = JSON.parse(listdata)[tab];

        },
        teamspace_code() {
            if (this.owner_Id > 1) {
                this.regmicroalldata.teamspace_code = this.ownerCode
            } else {
                this.regmicroalldata.teamspace_code = localStorage.getItem("ownerCode");
            }
        },
        selected_micro(item) {
            if (this.tabstatus == "pending") {
                this.selectedmicro = [];

                if (localStorage.getItem("current_id") != undefined) {
                    if (parseInt(localStorage.getItem("current_id")) != item.fun_id) {
                        this.selectedmicro.push(item);
                        localStorage.setItem("current_id", item.fun_id);
                    } else {
                        localStorage.removeItem("current_id");
                    }
                } else {
                    localStorage.setItem("current_id", item.fun_id);
                    this.selectedmicro.push(item);
                }
            }
        },
        closeEdit(status) {
            this.regmseditdialog = status;
        },
        closeMsDetailDialog(value) {
            this.regmsdetaildialog = value;
        },
        closeDialog(value) {
            this.regmsdetaildialog = value;
            this.regmseditdialog = true;
        },
        async uploadSourceCode(file) {
            this.upload_count = 0;
            this.file_name = "Model source"
            if (this.regmicroalldata.docs_yaml_url == "") {
                this.validForm = false;
            } else {
                this.validForm = true;
                this.uploadms = true;
                //PROGRESS BAR DETAILS
                const onUploadProgress = (event) => {
                    const percentage = Math.round((100 * event.loaded) / event.total);
                    this.upload_count = percentage;
                };

                const fd = new FormData();
                fd.append("fileToUpload", file, file.name);
                await API_Util.URLS.axios
                    .post(API_Util.URLS.cdnImageUpload, fd, {
                        headers: {
                            'Content-Type': "multipart/form-data",
                            "x-api-key": this.apiKey,
                        },
                        onUploadProgress,
                    })
                    .then((response) => {
                        this.regmicroalldata.model_image_source = response.data.url;
                        if (this.regmicroalldata.docs_yaml_file) {
                            this.uploadYaml(this.regmicroalldata.docs_yaml_file);
                        } else {
                            this.registerData();
                        }
                    })
                    .catch((err) => console.log(err));
            }
        },
        async uploadYaml(file) {
            this.uploadms = true;
            this.upload_count = 0;
            this.file_name = "Docs Yaml"
            const onUploadProgress = (event) => {
                const percentage = Math.round((100 * event.loaded) / event.total);
                this.upload_count = percentage;
            };
            const fd = new FormData();

            fd.append("fileToUpload", file, file.name);
            await API_Util.URLS.axios
                .post(API_Util.URLS.cdnImageUpload, fd, {
                    headers: {
                        'Content-Type': "multipart/form-data",
                        "x-api-key": this.apiKey,
                    },
                    onUploadProgress,
                })
                .then((response) => {
                    this.regmicroalldata.docs_yaml = response.data.url;
                    this.registerData();
                })
                .catch((err) => console.log(err));
        },
        async openMsDetailPage(item) {
            var data = { id: item.fun_id }
            let response = await API_Util.URLS.axios
                .post(API_Util.URLS.viewdetails, data,
                    {
                        headers: { "x-api-key": this.apiKey }
                    }
                )
                .then((response) => response.data.details)
                .catch((err) => console.log(err));
            this.regmicroalldata = response;
            this.regmicroalldata = Object.fromEntries(
                Object.entries(this.regmicroalldata).map(([key, value]) => [key.toLowerCase(), value])
            );
            this.regmicroalldata.env_variables = JSON.parse(this.regmicroalldata.env_variables)
            this.regmsdetaildialog = true;
        },
        async getRegMicroserviceList() {
            var data = { owner_id: localStorage.getItem("owner") }
            let response = await API_Util.URLS.axios
                .post(API_Util.URLS.getregmicrolist, data,
                    {
                        headers: { "x-api-key": this.apiKey }
                    }
                )
                .then((response) => response.data.results)
                .catch((err) => console.log(err));

            if (response.status == "Success") {
                this.regmslisting = response.listdata;
            } else {
                response.listdata['pending'] = [];
                response.listdata['approved'] = [];
                response.listdata['rejected'] = [];
                this.regmslisting = response.listdata
            }

            localStorage.setItem("temp_reg", JSON.stringify(response.listdata));
        },
        async dataCheck() {
            var data = { owner_id: localStorage.getItem("owner") }

            let response = await API_Util.URLS.axios
                .post(API_Util.URLS.datacheck, data, {
                    headers: {
                        "Content-Type": "application/json;charset=UTF-8",
                        "x-api-key": this.apiKey,
                    },
                })
                .then((response) => response)
                .catch((err) => console.log(err));
            this.datacheck = response.data;
        },
        getheader(tabstatus) {
            this.header = [
                { text: "Name", align: "start", sortable: true, value: "fun_name" },
                { text: "Type", value: "fun_type" },
                { text: "Description", value: "fun_desc" },
            ];
            if (this.tabstatus == "pending" || tabstatus == "pending") {
                this.header.push({ text: "End Point", value: "fun_endpoint" })
            } else if (tabstatus == "approved") {
                this.header.push({ text: "Model Status", value: "fun_model_status" })
            } else {
                this.header.push({ text: "Error Details", value: "fun_error_msg" })
            }

            localStorage.removeItem("current_id");
        },
        getFlexi() {
            API_Util.URLS.axios
                .post(
                    API_Util.URLS.getlblbyscr,
                    {
                        owner_id: localStorage.getItem("owner"),
                        scrId: "REG_MS",
                        lang: this.language,
                    },
                    { headers: { "x-api-key": this.apiKey } }
                )
                .then((response) => {
                    this.labels = response.data.labels;
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
        async nextStep() {
            if (this.currentStep == 1) {
                if (this.regmicroalldata.name == "") {
                    this.validForm = false;
                } else if (this.regmicroalldata.deployment_type == "") {
                    this.validForm = false;
                } else if (this.regmicroalldata.description == "") {
                    this.validForm = false;
                } else if (this.regmicroalldata.resource_cpu == "") {
                    this.validForm = false;
                } else if (this.regmicroalldata.resource_memory == "") {
                    this.validForm = false;
                } else if (this.regmicroalldata.enable_gpu == "") {
                    this.validForm = false;
                } else if (this.regmicroalldata.model_image_port == "") {
                    this.validForm = false;
                } else if (this.datacheck.memory < this.regmicroalldata.resource_memory || this.datacheck.cpu < this.regmicroalldata.resource_cpu) {
                    this.validForm = false;
                } else {
                    var data = {
                        owner: this.owner, msName: this.regmicroalldata.name,
                        id: this.regmicroalldata.id,
                        type: 'name'
                    }
                    let response = await API_Util.URLS.axios
                        .post(await API_Util.URLS.checkmsdup, data, {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8",
                                "x-api-key": this.apiKey,
                            },
                        })
                        .then((response) => response)
                        .catch((err) => console.log(err));
                    if (response.data.message == "NAME OR CODE EXIST") {
                        this.response_message = "Invalid Input - Microservice Name Already Exists";
                        this.response_status = "Error";
                        this.validForm = false;
                    } else {
                        this.response_message = "";
                        this.response_status = "";
                        this.validForm = true;
                    }
                }
            }
            if (this.currentStep == 2) {
                if (this.regmicroalldata.endpoint == "") {
                    this.validForm = false;
                } else {
                    data = {
                        owner: this.owner, msCode: this.regmicroalldata.microservice_code,
                        id: this.regmicroalldata.id, type: 'code',
                        urlCode: this.regmicroalldata.microservice_code.toLowerCase()
                    }
                    let response = await API_Util.URLS.axios
                        .post(API_Util.URLS.checkmsdup, data, {
                            headers: {
                                "Content-Type": "application/json;charset=UTF-8",
                                "x-api-key": this.apiKey,
                            },
                        })
                        .then((response) => response)
                        .catch((err) => console.log(err));

                    if (response.data.message == "NAME OR CODE EXIST") {
                        this.response_message = "Invalid Input - Microservice Code Already Exists";
                        this.response_status = "Error";
                        this.validForm = false;
                    } else {
                        this.response_message = "";
                        this.response_status = "";
                        this.validForm = true;
                    }
                }
            }
            if (this.currentStep < 3 && this.validForm) {
                this.currentStep = this.currentStep + 1;
            }
        },
        backStep() {
            if (this.currentStep > 1) {
                this.currentStep = this.currentStep - 1;
                this.validForm = true;
            }
        },
        async registerData() {
            if (this.regmicroalldata.model_image_source != "" && this.regmicroalldata.docs_yaml != "") {
                this.regmicroalldata.owner_id = localStorage.getItem('owner')
                if (this.regmicroalldata.id > 0) {
                    var url = API_Util.URLS.updatedetails;
                } else {
                    url = API_Util.URLS.register;
                }
                let response = await API_Util.URLS.axios
                    .post(url, this.regmicroalldata, {
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            "x-api-key": this.apiKey,
                        },
                    })
                    .then((response) => response)
                    .catch((err) => console.log(err));
                if (response.data.status == "Failure") {
                    this.uploadms = false;
                    this.response_msg = response.data.message;
                    this.errorms = true;
                } else {
                    this.response_message = "";
                    this.response_status = "";
                    this.uploadms = false;
                    if (this.regmicroalldata.id > 0) {
                        this.response_msg = "Successfully Updated";
                        this.successms = true;
                    }
                    else {
                        this.response_msg = "Successfully Created";
                        this.successms = true;
                    }

                    setTimeout(() => {
                        this.getRegMicroserviceList();
                        this.regmicroservices = false;
                        if (this.regmicroalldata.id > 0) {
                            this.regmseditdialog = false;
                        }
                        this.clearMicroAllData();
                        this.changeStepper();
                    }, 1000);
                }
            } else {
                this.response_msg = "Error";
                this.errorms = true;

            }
        },
        save() {
            if (this.regmicroalldata.id == 0) {
                this.uploadSourceCode(this.regmicroalldata.model_image_file);
            } else {
                if (this.regmicroalldata.model_image_file) {
                    this.uploadSourceCode(this.regmicroalldata.model_image_file);
                } else if (this.regmicroalldata.docs_yaml_file) {
                    this.uploadYaml(this.regmicroalldata.docs_yaml_file);
                } else {
                    this.registerData();
                }
            }

        },
        statusMicroservices(approval_status, rejection) {
            const headers = {
                headers: { "x-api-key": this.apiKey },
            }
            this.selectedmicro.forEach(function (arrayItem) {
                if (approval_status == "Approved") {
                    var url = API_Util.URLS.deploy;
                    var data = { "id": arrayItem.fun_id, "approval_status": approval_status, "owner_id": localStorage.getItem("owner") }
                } else {
                    url = API_Util.URLS.updatedetails;
                    data = { "id": arrayItem.fun_id, "approval_status": approval_status, "approval_status_old": arrayItem.fun_status, "owner_id": localStorage.getItem("owner"), "error_msg": rejection }
                }
                API_Util.URLS.axios
                    .post(url, data,
                        headers
                    )
                    .then()
                    .catch((err) => console.log(err));
                data = {}
            });
            this.selectedmicro = [];
            setTimeout(() => {
                this.getRegMicroserviceList();
                this.deletems = false;
                this.approvems = false;
                this.rejectms = false;
            }, 500);
        },
        allOwners() {
            this.$router.push("/ownerlisting");
        },
        watch: {
        },

    },
};
</script>
<style lang="scss" scoped>
::v-deep .v-divider:last-child {
    display: none;
}

::v-deep .v-stepper--alt-labels .v-stepper__header {
    margin: auto;
    width: 65%;
}

.selected-border {
    cursor: pointer;
}

.active-chip {
    border-bottom: 4px solid #545454;
    font-weight: bold;
}

::v-deep .selected-border p .v-chip.v-size--default {
    height: 32px;
    width: 32px;
    margin-left: 4px;
    padding: 0px 7px 0 7px;
    justify-content: center;
}

::v-deep .v-radio .v-label {
    color: #000 !important;
}

::v-deep .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 20px;
}

::v-deep .v-radio .v-input--selection-controls__input .v-icon {
    font-size: 20px;
}

.patchInput .v-input {
    display: inline-block;
}

.patchInput a {
    background: #D4D4D4;
    border-radius: 4px;
    padding: 5px;
    text-decoration: unset;
    margin-left: 24px;
}

.basicInfo-tlt {
    padding: 12px 10px 0;
}
</style>
  