<template>
    <v-container style="padding-top:0;">
            <v-row class="d-flex">
              <v-col>
                <span>Description:</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-if="!showDetail"
                  dense
                  outlined
                  placeholder = "Description"
                  v-model="microAllData.pricing[pricingValue].desc"></v-text-field>
                <span v-else>{{microAllData.pricing[pricingValue].desc}}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-radio-group v-model="radios" row>
                <template v-slot:label>
                  <div class="px-3" style="color:black">Pricing Plan Type:</div>
                </template>
                  <v-radio value="tiered" 
                    class="radio-btn"
                    :disabled="(microAllData.pricing[pricingValue].callperapi).length>0 && !(microAllData.pricing[pricingValue].callperapi == 0.00)">
                    <template v-slot:label>
                      <div style="color:black">Tiered</div>
                    </template>
                  </v-radio>
                  <v-radio value="standard" class="radio-btn" :disabled="(microAllData.pricing[pricingValue].tier).length>0">
                    <template v-slot:label>
                      <div style="color:black">Standard</div>
                    </template>
                  </v-radio>
              </v-radio-group>
            </v-row>
            <div v-if="radios == 'standard'">
              <!--------standard pricing start------->
              <v-row v-if="!showDetail">
                <v-col cols="3">
                  <span class="pt-2 pl-1">Standard Pricing:</span>
                </v-col>
                <v-col cols="2" class="space-text">
                    <span class="text-left">USD</span>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    dense
                    outlined
                    v-model="microAllData.pricing[pricingValue].callperapi"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"></v-text-field>
                </v-col>
                <v-col cols="3" class="space-text">
                    <span class="text-left" v-if="microAllData.pricing[pricingValue].endpointType == 'sync'">/ API Call</span>
                    <span class="text-left" v-if="microAllData.pricing[pricingValue].endpointType == 'async'">/ MB Filesize</span>
                    <span class="text-left" v-if="microAllData.pricing[pricingValue].endpointType == 'stream'">/ Minutes</span>
                </v-col>
                </v-row>
                <!-------standard pricing end------>
                <!-------msDetail Show start------->
                <v-row v-else>
                  <v-col cols="4">
                    <span class="pt-2 pl-1">Standard Pricing:</span>
                  </v-col>
                  <v-col cols="8" style="padding-left: 45px;">
                    <span v-if="microAllData.pricing[pricingValue].endpointType == 'sync'">
                      <span v-if="microAllData.pricing[pricingValue].callperapi > 0.9">{{ microAllData.pricing[pricingValue].callperapi }} USD / API Call</span>
                      <span v-else>{{ microAllData.pricing[pricingValue].callperapi }} USD / API Call</span>
                    </span>
                    <span v-if="microAllData.pricing[pricingValue].endpointType == 'async'">
                      <span v-if="microAllData.pricing[pricingValue].callperapi > 0.9">{{ microAllData.pricing[pricingValue].callperapi }} USD / MB Filesize</span>
                      <span v-else>{{ microAllData.pricing[pricingValue].callperapi }} USD / MB Filesize</span>
                    </span>
                    <span v-if="microAllData.pricing[pricingValue].endpointType == 'stream'">
                      <span v-if="microAllData.pricing[pricingValue].callperapi > 0.9">{{ microAllData.pricing[pricingValue].callperapi }} USD / Minutes</span>
                      <span v-else>{{ microAllData.pricing[pricingValue].callperapi }} USD / Minutes</span>
                    </span>
                  </v-col>
                </v-row>
                <!-------msDetail Show end------->
            </div>
            
            <div v-if="radios == 'tiered'">
              <!-------add tier start------->
              <v-row v-if="!showDetail">
              <v-col cols="12"
              v-if="microAllData.pricing[pricingValue].callperapi == 0.00">
                <v-divider class="div-size"></v-divider>
                <v-row>
                  <v-col cols="3" class="font-weight-bold">Lower Limit:</v-col>
                  <v-col cols="3" class="font-weight-bold" style="padding-left: 60px;">Upper Limit:</v-col>
                  <v-col cols="5" class="font-weight-bold" style="padding-left: 100px;">Price Per Unit:</v-col>
                </v-row>
                <v-divider class="div-size"></v-divider>
              </v-col>
              <div v-for="(tr, index) in microAllData.pricing[pricingValue].tier" :key="index" style="width:700px;">
                  <v-row  v-if="!tr.showTier || tr.showTier == undefined">
                    <v-col cols="2" class="lower-space">
                      {{ parseFloat(tr.lower).toLocaleString() }}
                    </v-col>
                    <v-col>
                      -
                    </v-col>
                    <v-col cols="3" class="upper-space">
                      <span v-if="microAllData.pricing[pricingValue].endpointType == 'sync'">
                        <span v-if="tr.upper != ''">{{ parseFloat(tr.upper).toLocaleString() }}</span>
                        <v-icon v-else>mdi-infinity</v-icon> API Calls</span>

                      <span v-if="microAllData.pricing[pricingValue].endpointType == 'async'">
                        <span v-if="tr.upper != ''">{{ parseFloat(tr.upper).toLocaleString() }}</span>
                        <v-icon v-else>mdi-infinity</v-icon> MB Filesize</span>

                      <span v-if="microAllData.pricing[pricingValue].endpointType == 'stream'">
                        <span v-if="tr.upper != ''">{{ parseFloat(tr.upper).toLocaleString() }}</span>
                        <v-icon v-else>mdi-infinity</v-icon> Minutes</span>
                    </v-col>
                    <v-col cols="4" class="cost-space">
                      <span v-if="tr.cost == 0">FREE</span>
                      <span v-else>
                        <span v-if="microAllData.pricing[pricingValue].endpointType == 'sync'">USD {{ cost_decimal(tr.cost) }} / API Call</span>
                        <span v-if="microAllData.pricing[pricingValue].endpointType == 'async'">USD {{ cost_decimal(tr.cost) }} / MB Filesize</span>
                        <span v-if="microAllData.pricing[pricingValue].endpointType == 'stream'">USD {{ cost_decimal(tr.cost) }} / Minutes</span>
                      </span>
                    </v-col>
                    <v-col cols="2">
                      <v-icon class="pl-5" style="cursor:pointer" @click="showTierDetails(index)" v-if="!showDetail">mdi-pencil</v-icon>
                      <v-icon class="pl-2" color="red" style="cursor:pointer" 
                        @click.prevent="deleteTier(index)" v-if="!showDetail">mdi-trash-can-outline</v-icon>
                    </v-col>
                  </v-row>
                  <v-row  v-if="tr.showTier">
                    <v-col cols="2">
                      <v-text-field
                        outlined
                        type="number" 
                        v-model="tr.lower" 
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"></v-text-field>
                    </v-col>
                    <v-col cols="2" class="space">
                      -
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        outlined
                        type="number" 
                        v-model="tr.upper"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"></v-text-field>
                    </v-col>
                    <v-col cols="2" class="space-text1">
                      <span v-if="microAllData.pricing[pricingValue].endpointType == 'sync'">API Calls</span>
                      <span v-else-if="microAllData.pricing[pricingValue].endpointType == 'async'">MB Filesize</span>
                      <span v-else-if="microAllData.pricing[pricingValue].endpointType == 'stream'">Minutes</span>
                    </v-col>
                    <v-col cols="2" style="padding-left: 0px;">
                      <v-text-field
                        outlined
                        v-model="tr.cost"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"></v-text-field>
                    </v-col>
                    <v-col cols="2" class="pt-5">
                      <v-icon color="primary" style="cursor:pointer" 
                        class="pl-5"
                        @click.prevent="addTier(index)"
                        :disabled="!tr.lower || !tr.cost || 
                        parseInt(tr.lower) > parseInt(tr.upper)">mdi-check-bold</v-icon>
                      <v-icon class="pl-2" color="red" style="cursor:pointer"
                      @click.prevent="microAllData.pricing[pricingValue].tier.splice(index,1)">mdi-trash-can-outline </v-icon>
                    </v-col>
                  </v-row>
              </div>
              <div class="pt-6 pb-10 btn-space text-center">
                <span class="px-2"
                  ><v-btn class="nocap grey white--text px-10"
                  @click.prevent="addPricingTier"
                  v-if="!showDetail && microAllData.pricing[pricingValue].callperapi == 0.00">
                    <span>Add Tier</span>
                  </v-btn></span
                >
              </div>
              </v-row>
              <!--------add tier end---------------->
              <!---------msDetail Show start--------->
              <v-row v-else>
              <v-col cols="12"
              v-if="microAllData.pricing[pricingValue].callperapi == 0.00">
                <v-divider class="div-size"></v-divider>
                <v-row>
                  <v-col cols="3" class="font-weight-bold">Lower Limit:</v-col>
                  <v-col cols="2"></v-col>
                  <v-col cols="4" class="font-weight-bold">Upper Limit:</v-col>
                  <v-col cols="3" class="font-weight-bold">Price Per Unit:</v-col>
                </v-row>
                <v-divider class="div-size"></v-divider>
              </v-col>
              <div v-for="(tr, index) in microAllData.pricing[pricingValue].tier" :key="index" style="width:700px;">
                  <v-row  v-if="!tr.showTier || tr.showTier == undefined">
                    <v-col cols="3" class="lower-space">
                      {{ parseFloat(tr.lower).toLocaleString() }}
                    </v-col>
                    <v-col cols="2">
                      -
                    </v-col>
                    <v-col cols="4" style="padding-left: 40px;">
                      <span v-if="microAllData.pricing[pricingValue].endpointType == 'sync'">
                        <span v-if="tr.upper != ''">{{ parseFloat(tr.upper).toLocaleString() }}</span>
                        <v-icon v-else>mdi-infinity</v-icon> API Calls</span>

                      <span v-if="microAllData.pricing[pricingValue].endpointType == 'async'">
                        <span v-if="tr.upper != ''">{{ parseFloat(tr.upper).toLocaleString() }}</span>
                        <v-icon v-else>mdi-infinity</v-icon> MB Filesize</span>

                      <span v-if="microAllData.pricing[pricingValue].endpointType == 'stream'">
                        <span v-if="tr.upper != ''">{{ parseFloat(tr.upper).toLocaleString() }}</span>
                        <v-icon v-else>mdi-infinity</v-icon> Minutes</span>
                    </v-col>
                    <v-col cols="3" class="cost-space1">
                      <span v-if="tr.cost == 0">FREE</span>
                      <span v-else>
                        <span v-if="microAllData.pricing[pricingValue].endpointType == 'sync'">USD {{ tr.cost }} / API Call</span>
                        <span v-if="microAllData.pricing[pricingValue].endpointType == 'async'">USD {{ tr.cost }} / MB Filesize</span>
                        <span v-if="microAllData.pricing[pricingValue].endpointType == 'stream'">USD {{ tr.cost }} / Minutes</span>
                      </span>
                    </v-col>
                  </v-row>
              </div>
              <div class="pt-6 pb-10 btn-space text-center">
                <span class="px-2"
                  ><v-btn class="nocap grey white--text px-10"
                  @click.prevent="addPricingTier" v-if="!showDetail">
                    <span>Add Tier</span>
                  </v-btn></span
                >
              </div>
              </v-row>
              <!----------msDetail Show end---------->
            </div>
    </v-container>
</template>
<script>
import Vue from 'vue'
import API_Util from '../../services/util/API_Util.js';
export default {
    name: "pricingPlanTier",
    props: ['microAllData', 'labels', 'pricingValue', 'showDetail'],
    data: () => ({
        apiKey: "",
        micro_pricing_type: [],
        pricingObj: {id:0, tier: []},
        tierObj: {},
        pricing: [],
        owner: '',
        radios: 'tiered',
        pricing_tier: 'small',
        showTier: false,
        temp_upper: 0,
        temp_cost: 0
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
    this.getFunPricingType();
    if((this.microAllData.pricing[this.pricingValue].callperapi).length>0 && !(this.microAllData.pricing[this.pricingValue].callperapi == 0.00)){
      this.radios = "standard"
    }
  },
  methods: {
    showTierDetails(idx){
      Vue.set(this.microAllData.pricing[this.pricingValue].tier[idx], "showTier", true);
    },
    deleteTier(idx){
      var to_delete_ids = "";
      var val = this.microAllData.pricing[this.pricingValue].tier.splice(idx,1)
      val.forEach((obj) => {
        if (obj.id > 0) {
          to_delete_ids = obj.id;
        }
      })
      var det = {"ids": to_delete_ids}
      API_Util.URLS.axios
        .post(API_Util.URLS.deleteMicroPricing, det, {
          headers: { "x-api-key": this.apiKey },
          params: {},
        })
        .then((response) => alert(JSON.stringify(response)))
        .catch((err) => console.log(err));
      to_delete_ids = "";
    },
    clearPricingTier(id) {
      if (this.microAllData.pricing.id > 0) {
        this.pricingObj = {id:id, tier: []};
      } else {
        this.pricingObj = {id:0, tier: []};
      }
      
    },
    addTier(idx) {
      this.temp_upper = this.microAllData.pricing[this.pricingValue].tier[idx].upper;
      Vue.set(this.microAllData.pricing[this.pricingValue].tier[idx], "showTier", false);
      // this.temp_cost = this.microAllData.pricing[this.pricingValue].tier[idx].cost;
      // if((this.temp_cost).toString.indexOf(".") == -1){
      //   var temp = this.temp_cost.toString.append(.00)
      //   alert(temp)
      //   this.microAllData.pricing[this.pricingValue].tier[idx].cost = temp
      // }
      
    },
    cost_decimal(n)
    {
      var result = (n - Math.floor(n)) !== 0; 
      if (result || n.endsWith('.00'))
        return n;
      else
        return (n + '.00');
    },
    addPricingTier() {
        // this.microAllData.pricing.forEach((obj,idx) => {
        //   if(idx==this.pricingValue){
        //     obj.tier.push({"id": 0, "lower": "", "upper": "", "cost": "", "showTier": true})
        //   }
        // });
        if(this.microAllData.pricing[this.pricingValue].tier.length < 1){
          this.microAllData.pricing[this.pricingValue].tier = [{"id": 0, "lower": "", "upper": "", "cost": "", "showTier": true}];
        }
        else{
        this.microAllData.pricing[this.pricingValue].tier.push({"id": 0, "lower": "", "upper": "", "cost": "", "showTier": true})
        }
    },
    async getFunPricingType() {
     var data = {
      "owner_id":localStorage.getItem("owner"),"lang":"EN","code":"PRICING_ENDPOINT_TYPE"
    };
    let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'x-api-key': this.apiKey
            }
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
        this.micro_pricing_type = response;
    },
  },
  watch: {
    microAllData(data) {
      this.$emit('microDataSubmitted', data);
    }
  }
}

</script>
<style scoped>
.btn-space{
  padding-left: 270px;
}
.radio-btn{
  padding-left: 150px;
}
.reduce-box{
  padding-left:100px;
}
.lower-space{
  padding-left: 30px;
}
.upper-space{
  padding-left: 75px;
}
.cost-space{
  padding-left: 120px;
}
.dspace{
  padding-left: 45px;
}
.cost-space1{
  padding-left: 50px;
}
.div-size{
  border-width: thick 0px 0px 0 !important;
}
.space {
    padding-left: 35px;
    margin-top: 10px;
}
.space-text1 {
    padding-left: 0px;
    margin-top: 9px;
}
</style>
