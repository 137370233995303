<template>
  <div class="mbl-msform msForm">
    <v-row>
      <v-col cols="12" sm="6">
        <label>{{ labels.TYP }}</label>
        <v-select
          append-to-body
          :calculate-position="withPopper"
          outlined
          v-model="promoAllData.type"
          :items="promoType"
          item-text="value"
          item-value="code"
          placeholder="Select Promotypes"
        ></v-select>
        <span v-if="!promoAllData.type && !isValidForm" style="color:red;">{{ labels.TYVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.CD }}</label>
        <v-text-field
          outlined
          placeholder="promocode"
          v-model.trim="promoAllData.code"
        ></v-text-field>
        <span v-if="!promoAllData.code && !isValidForm" style="color:red;">{{ labels.CDVAL }}</span>
      </v-col>
      <!-- -----------------Start  Date---------------------- -->
      <v-col cols="12" sm="6">
        <label>{{ labels.S_DA }}</label>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              readonly
              v-model="dateFormattedStart"
              v-bind="attrs"
              @blur="start_date = parseDate(dateFormattedStart)"
              v-on="on"
              outlined
              class="datePicker"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="start_date"
            no-title
            :min="currentDate"
            :show-current="currentDate"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
        <!-- <p>Date in ISO format: <strong>{{ date }}</strong></p> -->
      </v-col>
      <!-- -----------------End Date---------------------- -->
      <v-col cols="12" sm="6">
        <label>{{ labels.E_DA }}</label>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              readonly
              outlined
              class="datePicker"
              v-model="dateFormattedEnd"
              v-bind="attrs"
              @blur="end_date = parseDate(dateFormattedEnd)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="end_date"
            no-title
            @input="menu2 = false"
            :min="start_date"
          ></v-date-picker>
        </v-menu>
        <span v-if="(promoAllData.start_date > promoAllData.end_date) && !isValidForm" style="color:red;">{{ labels.E_DAVAL }}</span>
        <!-- <p>Date in ISO format: <strong>{{ date }}</strong></p> -->
      </v-col>
      <v-col cols="12" sm="6" v-if="promoAllData.type === 'POS'">
        <label>{{ labels.DIS_TY }}</label>
        <v-select
          outlined
          v-model="promoAllData.discount_type"
          :items="discountType"
          item-text="value"
          item-value="code"
          placeholder="Select Discount types"
        ></v-select>
        <span v-if="!promoAllData.discount_type && !isValidForm" style="color:red;">{{ labels.DIS_TYVAL }}</span>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        v-if="promoAllData.type === 'POS' && promoAllData.discount_type"
      >
        <label v-if="promoAllData.discount_type === 'DIS_PERCENT'"
          >{{ labels.DIS_P }}</label
        >
        <label v-if="promoAllData.discount_type === 'DIS_AMT_USD'"
          >{{ labels.DIS_AMT }}</label
        >
        <v-text-field
          outlined
          type="text"
          v-model="promoAllData.discount_amount"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"
        ></v-text-field>
        <span v-if="!promoAllData.discount_amount && !isValidForm" style="color:red;">{{ labels.DIS_AMTVAL }}</span>
      </v-col>
      <v-col cols="12" sm="6" v-if="promoAllData.type === 'POS'">
        <label>{{ labels.MIN_TOP }}</label>
        <v-text-field
          outlined
          type="text"
          v-model="promoAllData.minTopupAmount"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"
        ></v-text-field>
        <span v-if="!promoAllData.minTopupAmount && !isValidForm" style="color:red;">{{ labels.MIN_TOPVAL }}</span>
      </v-col>

      <v-col cols="12" sm="6" v-if="promoAllData.type === 'PROMO'">
        <label>{{ labels.CRE }}</label>
        <v-text-field
          outlined
          type="text"
          v-model="promoAllData.credit"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"
        ></v-text-field>
        <span v-if="!promoAllData.credit && !isValidForm" style="color:red;">{{ labels.CREVAL }}</span>
      </v-col>

      <v-col cols="12" sm="6" v-if="promoAllData.type === 'PROMO'">
        <label>{{ labels.CREV }}</label>
        <v-select
          outlined
          v-model="promoAllData.credit_validity_type"
          :items="creditValidityType"
          item-text="value"
          item-value="value"
          placeholder="Select Credit Valid Duration"
          @change="changeCreditValidity"
        ></v-select>
        <span v-if="!promoAllData.credit_validity_type && !isValidForm" style="color:red;">{{ labels.CREVVAL }}</span>
      </v-col>
      <!-- ------------Duration--------------- -->
      <v-col
        cols="12"
        sm="6"
        v-if="
          promoAllData.type === 'PROMO' && promoAllData.credit_validity_type
        "
      >
        <label>{{ labels.DUR }}</label>
        <div v-if="promoAllData.credit_validity_type === 'date'">
          <v-menu
            ref="menu3"
            v-model="menu3"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                readonly
                outlined
                class="datePicker"
                v-model="dateFormattedCredit"
                v-bind="attrs"
                @blur="credit_validity_date = parseDate(dateFormattedCredit)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="credit_validity_date"
              no-title
              @input="menu3 = false"
              :min="start_date"
            ></v-date-picker>
          </v-menu>
            <span v-if="(promoAllData.start_date > promoAllData.credit_validity_value) && promoAllData.credit_validity_type === 'date' && !isValidForm" style="color:red;">{{ labels.DUR_DAVAL }}</span>
        </div>
        <div v-if="promoAllData.credit_validity_type === 'month'">
          <v-select
            outlined
            v-model="promoAllData.credit_validity_value"
            :items="creditInMonths"
            placeholder="Select Credit Valid Duration in month"
          ></v-select>
          <span v-if="promoAllData.credit_validity_type === 'month' && !promoAllData.credit_validity_value && !isValidForm" style="color:red;">{{ labels.DUR_VAL }}</span>
        </div>
      </v-col>
      <v-col sm="6" cols="12">
        <label>{{ labels.DESC }}</label>
        <v-textarea
          outlined
          placeholder="Long Description"
          name="input-7-1"
          rows="3"
          v-model.trim="promoAllData.description"
        ></v-textarea>
        <span v-if="!promoAllData.description && !isValidForm" style="color:red;">{{ labels.DESCVAL }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "addpromocode",
  props: ["promoAllData", "promoType", "discountType", "creditValidityType", "isValidForm", "labels"],
  data: (vm) => ({
    currentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    credit_validity_date: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    dateFormattedStart: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    dateFormattedEnd: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    dateFormattedCredit: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    menu1: false,
    menu2: false,
    menu3: false,
    apiKey: "",
    owner: "",
    creditInMonths: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
    ],
  }),
  watch: {
    start_date(val) {
      this.dateFormattedStart = this.formatDate(val);
      this.promoAllData.start_date = this.start_date;
    },
    end_date(val) {
      this.dateFormattedEnd = this.formatDate(val);
      this.promoAllData.end_date = this.end_date;
    },
    credit_validity_date(val) {
      this.dateFormattedCredit = this.formatDate(val);
      this.promoAllData.credit_validity_value = this.credit_validity_date;
    },
    promoAllData: {
      handler(data) {
        if (data.type === "PROMO") {
          data.discount_amount = "";
          data.discount_type = "";
          data.minTopupAmount = "";
          // if (data.credit_validity_type === 'date') {
          //     data.credit_validity_value = this.credit_validity_date;
          // }
        }
        if (data.type === "POS") {
          data.credit = "";
          data.credit_validity_type = "";
          data.credit_validity_value = "";
        }
        if (this.promoAllData.id > 0) {
          this.dateFormattedStart = this.formatDate(this.promoAllData.start_date);
          this.start_date = this.promoAllData.start_date;
          this.dateFormattedEnd = this.formatDate(this.promoAllData.end_date);
          this.end_date = this.promoAllData.end_date;
          if (
            this.promoAllData.credit_validity_type === "date" &&
            this.promoAllData.credit_validity_value.length > 3
          ) {
            this.dateFormattedCredit = this.formatDate(
              this.promoAllData.credit_validity_value
            );
          }
        }
        this.$emit("promoDataSubmitted", data);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
    this.promoAllData.start_date = this.start_date;
    this.promoAllData.end_date = this.end_date;
  },
  methods: {
    changeCreditValidity() {
      this.promoAllData.credit_validity_value='';
      if (this.promoAllData.credit_validity_type == 'date') {
        this.promoAllData.credit_validity_value = this.credit_validity_date;
      }
    }, 
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
.v-menu__content {
  margin-top: 0px !important;
  margin-left: 0px;
}
</style>