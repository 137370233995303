import 'font-awesome/css/font-awesome.min.css'
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi'
      },
      theme:{
        options: { customProperties: true },
        themes: {
          light: {
            // primary:'#424242',
            // secondary:'a90000',
            // tertiary:'#130c60',
            // error: '#b22222',
          }
        }
      }
});
