<template>
  <div>
    <!-- <navigation/> -->
    <div>
      <v-breadcrumbs :items="items" v-if="ownerDrop">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <div class="pl-5">
        <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" v-if="false"/>
      </div>
      <v-content class="white px-10">
        <div class="pb-4">
          <div class="d-flex justify-space-between align-center">
            <!-- <div cols="12 px-0">
              <a>
                <v-btn text class="nocap" @click="allOwners()">
                  <span class="grey--text">
                    <v-icon style="margin-left: -10px">mdi-arrow-left</v-icon>
                    Back
                  </span>
                </v-btn>
              </a>
            </div> -->
            <div>
              <h2 class="text-center font-weigt-bold">Flexy Value - Sub List ( private )</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
              <!-- <v-btn
                class="tertiary2 white--text nocap py-5"
                @click.stop="clearData()"
              >
                <v-icon class="pr-1 add">mdi-plus-circle</v-icon>
                Add Flexy Error
              </v-btn> -->
            </div>
          </div>
          <div class="d-flex flex-md-row flex-sm-column justify-start align-md-end justify-md-space-between">
            <div> 
              <v-chip outlined
                @click="makeSelectedEmpty"
                class="ma-2 white"
              v-if="selectedCode.length"
              >
                <v-icon>mdi-close-circle</v-icon><span>&nbsp;</span>  {{ selectedCode.length }} selected
              </v-chip>
            </div>
          <div>
            <v-btn text
                class="tertiary2--text nocap py-5"
                @click.stop="clearData()"
              >
               <v-icon class="pr-1 add" style="font-size:26px">mdi-format-list-checks  </v-icon>
                Create Flexy Value - Sub List
              </v-btn>
              <v-btn
                text
                @click.stop="getItem"
                :disabled="selectedCode.length != 1"
                class="tertiary1--text nocap py-5"
              >
                <v-icon class="pr-1 add" style="font-size: 26px"
                  >mdi-file-edit-outline</v-icon
                >
                Edit
              </v-btn>
            <v-btn text
              @click.stop="deletecodelistSubVal = true"
              :disabled="!selectedCode.length"
              class="error--text nocap py-5"
            >
             <v-icon  class="pr-1 add" style="font-size:26px">mdi-trash-can  </v-icon>
              Delete
            </v-btn>
          </div>
          </div>
          <div>
            <v-data-table
             :mobile-breakpoint="0"
              v-model="selectedCode"
              :headers="headers"
              :items="flexyCodeData"
              item-key="id"
              show-select
              @click:row="getItem"
              class="elevation-1 listingTable"
            >
            </v-data-table>
          </div>
          <v-row justify="center" style="margin-bottom: 25px !important;">
            <v-form ref="form" v-model="valid">
              <v-dialog
                class="stepBystep"
                scrollable
                v-model="addflexysubvalDialog"
                persistent
                max-width="1000px"
              >
                <v-card class="ms-title">
                  <v-card-title>
                    <div class="py-2">
                      <h3 class="text-center">New Flexy Value - Sub List</h3>
                    </div>
                     <div class="closeicon-right">
                        <v-icon @click="addflexysubvalDialog = false" class="text-right"
                          >mdi-close-circle-outline
                        </v-icon>
                      </div>
                  </v-card-title>
                  <v-divider class="my-4"></v-divider>
                  <v-card-text style="height: 400px">
                    <div>
                      <v-col cols="12" class="px-10">
                        <h3>Basic info</h3>
                      </v-col>
                     
                      <div>
                        <addflexyvaluesublist 
                          :newSubValue="newSubValue" 
                          :isValidForm="isValidForm"
                          :langItems="langItems"
                        />
                      </div>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <div class="px-md-16">
                       <v-alert class=""
                      text
                      :type="response_status"
                      v-if="response_msg.length"
                    >
                      {{ response_msg }}
                    </v-alert>
                    </div>
                  <v-card-actions class="px-10 py-6 text-center">                   
                    <v-btn
                      class="nocap"
                      large
                      text
                      @click="addflexysubvalDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      class="nocap mx-5"
                      large
                      color="primary"
                      @click="save()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-row>
          <!-- Success pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="successValSub"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-img
                    style="z-index: 1"
                    alt="sentient logo"
                    class="d-flex align-center justify-center"
                    src="@/assets/tick-mark1.svg"
                    contain
                    max-width="192"
                  ></v-img>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Success</div>
                  </v-card-title>
                  <p v-if="response_message.length">{{ response_message }}</p>
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap" color="primary" @click="successValSub = false">Done </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->
          <!-- delete pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="deletecodelistSubVal"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-avatar
                    color="error"
                    size="62"
                  >
                    <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Delete Confirmation</div>
                  </v-card-title>
                  Are you sure want to delete the selected flexy value sub-list?
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap mx-5" text @click="deletecodelistSubVal = false">Cancel </v-btn>
                      <v-btn class="nocap mx-5" color="error" @click="deletecodelistSubVals()">Delete </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->
          <!-- -------++++ Flexy Edit Pages +++++--------->
          <v-dialog v-model="flexyvaluesublistEditdialog" width="1000" height="400" scrollable persistent>
            <flexyvaluesublistEdit   
            @closeSubValEdit="closeEdit"
            :newSubValue="newSubValue"
            :langItems="langItems"
            :isValidForm="isValidForm"
            :valueInfo="valueInfo"
            @save="save"
            :response_msg="response_msg"
            :response_status="response_status"/>
          </v-dialog>
        </div>
      </v-content>
    </div>
    <loaderimg v-if="loader"/>
  </div>
</template> 
<script>
import addflexyvaluesublist from "./flexy/addflexyvaluesublist.vue";
import flexyvaluesublistEdit  from "./flexyvaluesublistEdit .vue"
import API_Util from "../services/util/API_Util.js";
import loaderimg from "./loaderimg.vue";
import headline from "./headline.vue";

export default {
  components: {
    flexyvaluesublistEdit,
    addflexyvaluesublist,
    loaderimg,
    headline
  },
  name: "valuesublist",
  data: () => ({
    response_message: "",
    ownerList: [],
    flexyvaluesublistEditdialog: false,
    flexysubvaldetail:false,
    successValSub: false,
    owner: "",
    apiKey: "",
    addflexysubvalDialog: false,
    enableDelete: false,
    deletecodelistSubVal: false,
    selectedOwner: "",
    headers: [
      {
        text: "ORDERBY",
        align: "start",
        sortable: true,
        value: "orderBy",
      },
      { text: "CODE", value: "code" },
      { text: "VALUE", value: "value" },
    ],
    isValidForm: true,
    flexyCodeData: [],
    langItems: [],
    selectedCode: [],
    newSubValue:{
      id: 0, parentId: localStorage.getItem("codeIdds"), code: "", value: "", orderBy: 0, dateUpdated: "", languages: [], updatedBy: 2
    },
    checkbox: false,
    response_msg: "",
    response_status: "",
    loading: false,
    search: "",
    errorList: [],
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Localisation - Flexy Value - Sub List (Private)",
        disabled: true,
        href: "",
      },
    ],
    loader: true,
    ownerType: "",
    ownerDrop: "",
    ownerDesc: "",
  }),

  created(){
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerName = sessionObj.session.ownerName;
    this.ownerType = sessionObj.session.ownerType;
    this.selectedOwner = localStorage.getItem("ownerName");
    this.owner = localStorage.getItem("owner");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
    this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
    this.getLangList();
    this.getOrgList();
    this.getflexycodeList();
  },

  computed: {},

  watch: {
    selected(val) {
      this.enableDelete = false;
      if (val.length) {
        this.enableDelete = true;
      }
    },
  },

  methods: {
    clearData(){
      this.newSubValue = {
        id: 0, parentId: localStorage.getItem("codeIdds"), code: "", value: "", orderBy: 0, dateUpdated: "", languages: [], updatedBy: 2
      };
      this.addflexysubvalDialog = true;
    },
   changeOwner(obj) {
      this.loader = true;
      localStorage.setItem("owner", obj.ownerId);
      localStorage.setItem('ownerName', obj.ownerName);
      this.selectedOwner = obj.ownerName;
      localStorage.setItem('ownerType', obj.ownerType);
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
      this.$emit('ownerchange',obj.ownerType);
      this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
      this.getLangList();
      this.getflexycodeList();
    },
    getOrgList() {
      var empt = {}
      API_Util.URLS.axios
        .post(API_Util.URLS.listOrg, empt, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.ownerList = response.data.results;
        });
    },
    allOwners() {
      this.$router.push("/valuelist");
    },
    closeEdit(status){
      this.flexyvaluesublistEditdialog = status;
    },
    getItem(obj){
      this.newSubValue = obj;
      const headers = { "x-api-key": this.apiKey };
      API_Util.URLS.axios
      .post(API_Util.URLS.flexyValInfo, obj.id, {headers})
      .then((response) => {
        this.valueInfo = response.data.result;
      })
      setTimeout(() => {
        this.flexyvaluesublistEditdialog=true;
      }, 1000);
      if(this.newSubValue.id > 0){
        this.newSubValue.languages = this.valueInfo.languages;
      }
    },
    getLangList(){
      const headers = { "x-api-key": this.apiKey };
      var empt = {};
      API_Util.URLS.axios
      .post(API_Util.URLS.flexyLangValue, empt,
      { headers }
      )
      .then((response) => {
        this.langItems = response.data.result;
        this.loader = false;
      });
    },
    getflexycodeList(){
      const headers = { "x-api-key": this.apiKey };
      API_Util.URLS.axios
      .post(API_Util.URLS.flexysubvalList, localStorage.getItem('codeIdds'),
      { headers }
      )
      .then((response) => {
        this.flexyCodeData = response.data.result;
        this.loader = false;
      });
    },
    save(){    
      if(this.newSubValue.code == ""){
        this.isValidForm = false;
      } else if(this.newSubValue.value == ""){
        this.isValidForm = false;
      } else if(this.newSubValue.orderBy == ""){
        this.isValidForm = false;
      } 
      else{
        this.langItems.forEach((obj) => {
          obj.disable = false;
          this.valueInfo.languages.forEach((row) => {
            if (row.lang == obj.language) {
              row.lang = obj.id;
            }
          });
        });
        if(this.newSubValue.id > 0){
          this.newSubValue.updatedBy = 0,
          this.newSubValue.ownerId = localStorage.getItem('owner'),
          this.newSubValue.orgId = localStorage.getItem("owner"),
          this.newSubValue.dateUpdated = "",
          this.newSubValue.parentId = localStorage.getItem("codeIdds"),
          this.newSubValue.languages = []
        }
        var self = this;
        const headers = { "x-api-key" : this.apiKey }
        API_Util.URLS.axios.post(API_Util.URLS.flexysubvalAdd, self.newSubValue,
        { headers })
        .then((response) => {
          var resp = response.data;
          if(resp.message == "ERROR_CODE_EXIST"){
            self.response_msg = "Error Code ALready Exists";
            self.response_status = "error";
            setTimeout(() => {
              this.response_msg = "";
            }, 1000)
          } else {
              // self.refreshData();
              if(self.newSubValue.id > 0){
                self.response_message = "Successfully Updated";
                self.successValSub = true;
              }
              else{
                self.response_message = "Successfully Created";
                self.successValSub = true;
              }
              setTimeout(() => {
                self.flexyvaluesublistEditdialog = false;
                self.addflexysubvalDialog = false;
                self.clearObjectData();
                self.getflexycodeList();
              }, 2000)
          }
        })
        .catch((err) => console.log(err));
      }
    },
    clearObjectData(){
      this.response_msg = "";
    },
    makeSelectedEmpty() {
      this.selectedCode = [];
    },
    refreshData(){
      this.newSubValue = {
        id: 0, parentId: localStorage.getItem("codeIdds"), code: "", value: "", orderBy: 0, dateUpdated: "", languages: [], updatedBy: 2
      }
    },
    deletecodelistSubVals(){
      let toDelete = [];
      this.selectedCode.forEach((arrayItem) => {
        toDelete.push(arrayItem.id);
      });
      API_Util.URLS.axios
      .post(API_Util.URLS.flexysubvalDelete, toDelete,
      { headers: { "x-api-key": this.apiKey }
      })
      .then((response) => response)
      .catch((err) => console.log(err));
      toDelete = [];
      this.selectedCode = [];
      this.deletecodelistSubVal = false;
      this.getLangList();
      setTimeout(() => {
        this.getflexycodeList();
      }, 1000)
    },
  },
};
</script>
<style lang="scss">
.v-input__append-inner {
  margin-top: 11px !important;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 46px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.container {
  max-width: 1224px;
}
.v-menu__content {
  background-color: white;
}
.v-chip .mdi-pencil-box-multiple {
  font-size: 21px !important;
}
.v-btn::before {
  background-color: transparent !important;
}
.v-stepper__step__step {
  height: 12px !important;
  min-width: 12px !important;
  width: 12px !important;
}

.v-stepper {
  // .v-stepper__step__step.primary {
  //     background-color: red !important;
  //     border-color: red !important;
  // }
  .v-divider {
    border-width: thin 0px 3px 0 !important;
  }

  .v-stepper__step {
    padding: 30px 0px 0px;

    hr {
      .v-stepper--alt-labels.v-stepper__header.v-divider {
        margin: 35px -80px 0 !important;
      }
    }
  }
}

//  .v-stepper__step--complete + .v-divider{
//      background-color: red !important;
//   }
.v-stepper__step__step {
  color: transparent !important;
  .v-icon {
    display: none !important;
  }
}
.v-stepper__header {
  box-shadow: none !important;
}

.ms-title {
  .v-card__title {
    text-align: center !important;
    display: block !important;
    padding: 24px 24px 10px !important;
  }
}
.v-card__actions {
  text-align: center !important;
  display: block !important;
}
</style>
