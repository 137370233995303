<template>
  <div class="mbl-msform msForm">
    <v-row>     
      <v-col cols="12">
        <label>{{ labels.ADD_NAME }}</label>
        <v-text-field outlined placeholder="Function Title" v-model.trim="newItems.name"></v-text-field>
        <span v-if="!newItems.name && !isValidForm" style="color:red;">Name is required</span>
      </v-col>
      <v-col cols="12">
        <label>{{ labels.FUN_CODE }}</label>
        <v-text-field
          outlined
          placeholder="Function Code"
          v-model.trim="newItems.code"
          @input="() => (newItems.code = newItems.code.toUpperCase())"
          onkeypress="return /^[ A-Za-z0-9_@./#&+-]*$/i.test(event.key)"
        ></v-text-field>
        <span v-if="!newItems.code && !isValidForm" style="color:red;">Code is required</span>
      </v-col>
      <v-col cols="12">
        <label>{{ labels.ADD_DESC}}</label>
        <v-text-field
          outlined
          placeholder="Description"
          v-model.trim="newItems.description1"
        ></v-text-field>
        <span v-if="!newItems.description1 && !isValidForm" style="color:red;">Description is required</span>
      </v-col>      
    </v-row>
    
  </div>
</template>

<script>
export default {
  name: "addFunInfo",
  props: ["newItems", "isValidForm", "labels"],
  data: () => ({
    iconlist: [],    
    msType: ["Atomistic", "Pipeline"],
  }),
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>