<template>
  <div>
    <!-- <navigation/> -->
    <div>
      <v-breadcrumbs :items="items" v-if="ownerDrop">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" v-if="ownerDrop"/>
      </div>
      <v-content class="white maincontent">
        <div class="pb-4">
          <div class="d-flex justify-space-between align-center">
            <!-- <div cols="12 px-0" v-if="ownerDrop">
              <v-btn text class="nocap px-0" @click="$router.go(-1)">
                <span class="grey--text">
                  <v-icon style="margin-left: -10px">mdi-arrow-left</v-icon>
                   {{ labels.BK }}
                </span>
              </v-btn>
            </div> -->
            <div>
              <h2 class="text-center font-weigt-bold">{{ labels.FL_LANG }}</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
            </div>
          </div>
          <div
            class="
              d-flex
              flex-md-row flex-sm-column
              justify-start
              align-md-end
              justify-md-space-between">
          </div>
          <div class="mt-4">
            <v-divider></v-divider>
            <h3 class="font-weigt-bold py-4" v-if="langList.length < 103">{{ labels.TR_LA }}</h3>
            <v-col cols="4" class="pb-6 pl-0">
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }" >
                  <v-btn large 
                    dark
                    block
                    v-bind="attrs"
                    v-on="on"
                    class="nocap justify-space-between text-left"
                    color="primary"
                    v-if="langList.length < 103"
                  >
                      <!-- <v-icon style="margin: 0"> mdi-translate  </v-icon> -->
                     {{ labels.ADDN_LA }}
                    <v-icon style="margin: 0"> mdi-menu-right </v-icon>
                  </v-btn>
                </template>

                <!-- Group selection items -->
                <v-list
                  style="max-height: 60vh; overflow-y: scroll"
                  hide-selected
                >
                  <v-list-item v-for="(item, index) in langItems" :key="index"
                  v-show="!checkExisting(item.id, langList)">
                    <v-checkbox color="tertiary1" @click="addLang()"
                    v-model="newItem.languages"
                    :value="item">
                      <template v-slot:label>
                        <span class="black--text">{{ item.language }}</span>
                      </template>
                    </v-checkbox>
                  </v-list-item>
                </v-list>
              </v-menu>
              <span v-if="langList.length == langItems.length" style="color:red; font-size:19px">All Languages are selected.</span>
            </v-col>
            <v-divider></v-divider>
            <div class="mt-4">
              <v-chip
                v-for="(lang, index) in langList"
                :key="index"
                class="ma-2"
              >
                <span v-if="lang.value!=undefined">
                  {{ lang.value }}
                </span>
                <span v-else>
                  {{ lang.value}}
                </span>
                <v-icon @click.stop="deleteLanguage(lang.id)">
                  mdi-close-circle
                </v-icon>
              </v-chip>
            </div>
          </div>
          <!-- ------------+++ Delete language row +++------------------ -->
          <v-row justify="space-around">
            <v-col cols="auto">
              <v-dialog max-width="600" v-model="deleteLang" persistent>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="py-6">
                      <v-icon large color="error" class="pr-3"
                        >mdi-alert-circle
                      </v-icon>
                      <h3>{{ labels.DEL_CON }}</h3>
                      <v-spacer></v-spacer>
                      <v-icon @click="dialog.value = false"
                        >mdi-close-circle-outline
                      </v-icon>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <div class="mt-4">
                        {{ labels.DEL_MSG }}
                      </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="text-right py-6">
                      <v-btn
                        class="nocap"
                        large
                        text
                        @click="dialog.value = false"
                        >{{ labels.CL }}</v-btn
                      >
                      <v-btn
                        class="nocap"
                        color="error"
                        @click="removeArrayItem(dialog)"
                        >{{ labels.S }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
          </v-row>
          <!-- ------------+++ Add language success  +++------------------ -->
          <v-row justify="space-around">
            <v-col cols="auto">
              <v-dialog max-width="600" v-model="langAdded">
                <template v-slot:default="dialog">
                  <v-card>
                    <v-card-title class="py-6">
                      <v-icon large color="success" class="pr-3"
                        >mdi-check-circle
                      </v-icon>
                      <h3>{{ labels.LA_SU_MSG }}</h3>
                      <v-spacer></v-spacer>
                      <v-icon @click="dialog.value = false"
                        >mdi-close-circle-outline
                      </v-icon>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <div class="mt-4">{{ labels.LA_SE_MSG }}</div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="text-right py-6">
                      <v-btn
                        class="nocap"
                        color="success"
                        @click="dialog.value = false"
                        >{{ labels.LA_OK }}</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-col>
          </v-row>
          <!-- ------------+++ Add Language row +++------------------ -->
        </div>
      </v-content>
    </div>
    <loaderimg v-if="loader"/>
  </div>
</template> 
<script>
import API_Util from "../services/util/API_Util.js";
import loaderimg from "./loaderimg.vue";
import headline from "./headline.vue";

export default {
  name: "language",
  components: {
    loaderimg,
    headline
  },
  data: () => ({
    ownerName: "",
    chip1: true,
    chip2: true,
    deleteLang: false,
    langAdded: false,
    loading: false,
    search: "",
    selectedInt: [],
    selectedMs: [],
    langItems: [],
    langList: [],
    selectedLang: [],
    selectId: [],
    deleteId: [],
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Localisation - Flexy Language",
        disabled: true,
        href: "",
      },
    ],
    newItem: {
      dateUpdated : "", languages : [], updatedBy : 0, id: 0
    },
    response_message: "",
    response_status: "",
    ownerList: [],
    selectedOwner: "",
    loader: true,
    ownerType: "",
    user_id: "",
    owner: "",
    orgId: "",
    language: "EN",
    labels: {},
    ownerDrop: "",
    ownerDesc: "",
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerName = sessionObj.session.ownerName;
    this.ownerType = localStorage.getItem("ownerType");
    this.user_id = sessionObj.session.id;
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.owner = localStorage.getItem("owner");
    localStorage.setItem('orgId', this.owner);
    this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
    this.orgId = localStorage.getItem("orgId");
    this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
    this.getLangList();
    this.getOrgList();
    this.getOrgLang();
    this.getFlexi();
  },
  computed: {},

  watch: {},

  methods: {
    allOwners() {
      this.$router.push("/ownerlisting", () => { window.location.reload()});
    },
    checkExisting(id, data){
      var isExist = false;
      if(data != undefined && data.length > 0){
        data.forEach((lang) => {
          if(id == lang.langId){
            isExist = true;
          }
        })
      }
      return isExist;
    },
    changeOwner(obj) {
      this.loader = true;
      localStorage.setItem("owner", obj.ownerId);
      this.selectedOwner = obj.ownerName;
      localStorage.setItem('ownerName', obj.ownerName);
      localStorage.setItem('orgId', obj.id);
      localStorage.setItem('ownerType', obj.ownerType);
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
      this.$emit('ownerchange',obj.ownerType);
      this.owner = localStorage.getItem("owner");
      this.orgId = localStorage.getItem("orgId");
      this.items[1].text = "Sentient.io (" + localStorage.getItem("ownerName") + ")";
      this.getOrgLang();
    },
    getOrgList() {
      var empt = {}
      API_Util.URLS.axios
        .post(API_Util.URLS.listOrg, empt, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.ownerList = response.data.results;
        });
    },
    getLangList(){
      const headers = { "x-api-key": this.apiKey };
      var empt = {};
      API_Util.URLS.axios
      .post(API_Util.URLS.flexyLangValue, empt,
      { headers }
      )
      .then((response) => {
        this.langItems = response.data.result;
      });
    },
    getOrgLang(){
      const headers = { "x-api-key": this.apiKey };
      var ids = {"orgId": this.orgId, "ownerId": this.owner};
      API_Util.URLS.axios
      .post(API_Util.URLS.flexyLangList, ids, { headers })
       .then((response) => {
         this.langList = response.data.result;
         this.loader = false;
       })
    },
    addLang(){
      var modifiedArray = []
      this.newItem.languages.forEach((value) => {
        if(value.id > 0){
          modifiedArray.push({"id": 0, "langId": value.id});
        }
      })
      this.newItem.languages =modifiedArray;
      this.newItem.updatedBy = this.user_id;
      this.newItem.orgId =  this.owner;
      this.newItem.ownerId = this.owner;
      const headers = { "x-api-key": this.apiKey };
      API_Util.URLS.axios
      .post(API_Util.URLS.flexyAdd, this.newItem, {headers})
      .then((response) => {
        this.langList.push(response.data.result)
      })
      this.langAdded = true
      setTimeout(() => {
        this.getOrgLang();
      }, 3000);
    },
    deleteLanguage(id){
      this.deleteLang = true
      this.selectId.push(id)
      this.deleteId.push(id)
    },
    removeArrayItem(obj) {
      obj.value = true
      const headers = { "Content-Type" : "application/json;charset=UTF-8", "x-api-key" : this.apiKey};
      API_Util.URLS.axios
      .post(API_Util.URLS.flexyDelete, this.selectId, {headers})
      .then((response) => {
        console.log(response.data)
      })
      .catch((err) => console.log(err));
      this.selectId = []
      this.deleteLang = false
      setTimeout(() => {
        this.getOrgLang();
      }, 2000)
      location.reload()
    },
    getFlexi() {
      API_Util.URLS.axios
        .post(
          API_Util.URLS.getlblbyscr,
          {
            owner_id: this.owner,
            scrId: "LANG_SET",
            lang: this.language,
          },
          { headers: { "x-api-key": this.apiKey } }
        )
        .then((response) => {
          this.labels = response.data.labels;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>
<style lang="scss">
.v-input__append-inner {
  margin-top: 11px !important;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 46px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.container {
  max-width: 1224px;
}
.v-menu__content {
  background-color: white;
}
.v-chip .mdi-pencil-box-multiple {
  font-size: 21px !important;
}
.v-btn::before {
  background-color: transparent !important;
}
.v-stepper__step__step {
  height: 12px !important;
  min-width: 12px !important;
  width: 12px !important;
}

.v-stepper {
  // .v-stepper__step__step.primary {
  //     background-color: red !important;
  //     border-color: red !important;
  // }
  .v-divider {
    border-width: thin 0px 3px 0 !important;
  }

  .v-stepper__step {
    padding: 30px 0px 0px;

    hr {
      .v-stepper--alt-labels.v-stepper__header.v-divider {
        margin: 35px -80px 0 !important;
      }
    }
  }
}

//  .v-stepper__step--complete + .v-divider{
//      background-color: red !important;
//   }
.v-stepper__step__step {
  color: transparent !important;
  .v-icon {
    display: none !important;
  }
}
.v-stepper__header {
  box-shadow: none !important;
}

.ms-title {
  .v-card__title {
    text-align: center !important;
    display: block !important;
  }
}
.v-card__actions {
  text-align: center !important;
  display: block !important;
}
</style>
