<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>{{labels.MIC_DE}}</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text>
      <div class="msForm demo-detatil">
        <!-- -----------Basic Info------------>
        <v-col cols="12" class="px-0 pb-5">
          <h2>Basic Info</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
         
          <v-col cols="12">
            <label class="my-4">{{labels.MIC_NAME}}</label>
            <div>{{ demoappsAllData.name }}</div>
          </v-col>
           <v-col cols="12" v-if="demoappsAllData.desc">
            <label class="my-4">{{labels.MIC_DESC}}</label>
            <div>{{ demoappsAllData.desc }} </div>
          </v-col>
          <v-col cols="12" v-if="demoappsAllData.desc2">
            <label class="my-4">{{labels.LNG_DESC}}</label>
            <div>{{ demoappsAllData.desc2 }}</div>
          </v-col>  
          <v-col cols="12" v-if="demoappsAllData.demoappsUseCase">
            <label class="my-4">{{labels.USECASE}}</label>
            <!-- <div v-html="demoappsAllData.demoappsUseCase"></div> -->
            <markdown-it-vue
                class="md-body text-justify mt-3"
                :content="demoappsAllData.demoappsUseCase"
                :options="options"
              >
              </markdown-it-vue>
          </v-col>       
        </v-row>  
      </div>
      <div class="msForm my-6">
        <!-- -----------Basic Info------------>
        <v-col cols="12" class="px-0 pb-5">
          <h2>Additional Info</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >        
        
          <v-col cols="12" v-if="demoappsAllData.tags.length">
             <label class="my-4">{{labels.TAGS}}</label>
            <v-card :elevation="hover ? 24 : 2">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{labels.TAGSKEY}}</span
                        >
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1"
                          >{{labels.TAGSVAL}}</span
                        >
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(tag, index) in demoappsAllData.tags" :key="index">
                      <td>{{ tag.key }}</td>
                      <td>{{ tag.value }}</td>                     
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
            <v-col cols="12" v-if="demoappsAllData.categoryName">
            <label class="my-4">{{labels.MIC_CATEGORY}}</label>
            <div>{{ demoappsAllData.categoryName }}</div>
          </v-col>
          <v-col cols="12" v-if="demoappsAllData.keywords">
            <label class="my-4">{{ labels.KEYWO }}</label>
              <v-combobox
              outlined
              multiple
              v-model="demoappsAllData.keywords"
              append-icon
              chips
              class="tag-input"
              >
              </v-combobox>
          </v-col>
           <v-col cols="12" v-if="demoappsAllData.demolink">
            <label class="my-4">{{ labels.DM_LINK }}</label>
            <div>{{ demoappsAllData.demolink }}</div>
          </v-col>
           <v-col cols="12" v-if="demoappsAllData.codelink">
            <label class="my-4">{{ labels.CD_LILNK }}</label>
            <div>{{ demoappsAllData.codelink }}</div>
          </v-col>
          <v-col cols="6">
            <div v-if="demoappsAllData.mIconUrl !='' && demoappsAllData.mIconUrl.startsWith('http')">
              <label class="my-4">{{labels.DMAPP_LOGO}}</label><br>
                <div class="colorPanel mt-0">
                <div class="colorClass imgBox d-flex align-center">
                  <v-img
                    alt="sentient logo"
                    class="shrink rounded mx-auto d-block"
                    style="padding: 0px"
                    :src="demoappsAllData.mIconUrl"
                    min-width="80"
                    max-width="36"
                    contain
                    aspect-ratio="2"
                  ></v-img>
                </div>
              </div>
            </div>
            <div v-else-if="demoappsAllData.mIconUrl !=''">
              <label class="my-4">Icon Url</label>
                <div>{{ demoappsAllData.mIconUrl }}</div>
            </div>
          </v-col>
          
          <v-col cols="6">
            <label class="my-4">{{ labels.DM_IMAGES }}</label><br>
            <div class="colorPanel mt-0" v-for="img in demoappsAllData.images" :key="img">
              <div class="colorClass imgBox d-flex align-center ml-1">
                <v-img
                  class="shrink rounded mx-auto d-block"
                  style="padding: 0px"
                  :src="img.imgUrl"
                  min-width="80"
                  max-width="36"
                  contain
                  aspect-ratio="2"
                ></v-img>
              </div>
            </div>
          </v-col>
          <v-col cols="12" v-if="demoappsAllData.urlcodems">
            <label class="my-4">{{ labels.URL_CODE }}</label>
            <div>{{ demoappsAllData.urlcodems }}</div>
          </v-col>

        <v-col cols="12" v-if="demoappsAllData.functions.length">
          <label class="my-4">{{ labels.MIC_FUN }}</label>
           <v-divider class="my-4"></v-divider>
           {{ labels.MIC_ASN_FN }}
        <div class="mb-4">
          <v-chip
            class="ma-1"
            color="tertiary1"
            dark
            v-for="(func, index) in demoappsAllData.functions"
            :key="index"
          >
            {{ func.function_name }}
          </v-chip>
        </div>
      </v-col>

        </v-row>  
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="nocap" @click="closeDemoappsDetail" text>
        <span class=""> {{ labels.CAN }}</span>
      </v-btn>
      <v-btn class="nocap primary" text @click="demoAppEditDialog()">
        <span class="">
          <v-icon>mdi-square-edit-outline</v-icon>
          {{ labels.EDIT }}
        </span>
      </v-btn>
    </v-card-actions>       
  </v-card>
</template>

<script>
import MarkdownItVue from "markdown-it-vue";
export default {
  name: "demoAppDetail",
  components: {
    MarkdownItVue,
  },
  props: ['demoappsAllData', 'labels'],
  data: () => ({
    options: {
      markdownIt: {
        linkify: true,
      },
      linkAttributes: {
        attrs: {
          target: "_blank",
          rel: "noopener",
        },
      },
    },
    checkbox: true,
    iconlist: [],
    text: "",
    itemsSource: [
      {
        text: "Nature",
        icon: "mdi-pencil-outline ",
      },
      {
        text: "Nightlife",
        icon: "mdi-pencil-outline ",
      },
    ],
    msType: ["Atomistic", "Pipeline"],
  }),
  methods: {
    closeDemoappsDetail() {
      this.$emit('closeDetailDemoapps', false);
    },
    demoAppEditDialog(){
      // this.msDetailDialog=false;
      // this.msEdit=true;
      this.$emit('closeDetailDialog', false);
    },
    onUpdate(text) {
      this.text = text;
    },
    onChangefile(fieldname, imageref) {
      this[fieldname] = [...this.$refs[fieldname].files];

      if (fieldname != "imagelist" && this[fieldname].length > 1) {
        this[fieldname].splice(0, this[fieldname].length - 1);
      }

      for (let i = 0; i < this[fieldname].length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.$refs[`${imageref}` + parseInt(i)][0].src = reader.result;
        };
        reader.readAsDataURL(this[fieldname][i]);
      }
    },
  },
};
</script>

<style lang="scss">
  .demo-detatilv {
    padding: 40px;
  }
.v-input__slot {
  min-height: 40px !important;
}
</style>