<template>
  <div class="mbl-msform msForm">
    <v-row>     
      <v-col cols="12" sm="6">
        <label>{{ labels.DS_NAM }} </label>
        <v-text-field outlined placeholder="Name of Schema" v-model.trim="dataSchemaObj.name"></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.TYPE }}</label>
        <div class="d-flex">
          <v-select
            outlined
            v-model="dataSchemaObj.type"
            :items="micro_schema"
            item-text="value"
            item-value="code"
            placeholder="Select Schema Datatype"
          ></v-select>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="d-flex">
          <v-checkbox v-model="isNull" @click.prevent="isNullCheckBox(isNull)" :label="`IsNull`"></v-checkbox>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <label>{{ labels.SIZ }}</label>
        <v-text-field outlined placeholder="Size" type="text" v-model="dataSchemaObj.datalength" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"></v-text-field>
      </v-col>

      <v-col cols="12">
        <label>{{ labels.DESC }}</label>
        <v-textarea
          outlined
          placeholder="Schema Description"
          name="input-7-1"
          rows="3"
          v-model="dataSchemaObj.description"
        ></v-textarea>
      </v-col>
      <v-col>
        <div class="text-center" >
          <span class="px-2">
            <v-btn class="nocap" outlined
            @click.prevent="dataSchemaObj={}">
              <!-- <v-icon>mdi-autorenew</v-icon> -->
              <span class="pl-1">{{ labels.RSET }}</span>
            </v-btn></span
          >
          <span class="px-2"
            ><v-btn class="nocap sen-success white--text"
              :disabled="!dataSchemaObj.name || !dataSchemaObj.type"
              @click.prevent="addDataSchema">
                <!-- <v-icon>mdi-plus </v-icon> -->
                <span class="pl-1"> {{ labels.ADD_CREATE }}</span>
            </v-btn></span
          >
        </div>
      </v-col>

      <v-col cols="12" class="px-0 pt-6" v-if="dataSchema.length">
        <v-card>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="grey lighten-5 text-center grey--text">
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{ labels.MIC_NAME }}</span>
                  </th>
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{ labels.TYPE }}</span>
                  </th>
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{ labels.ISNUL }}</span>
                  </th>
                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{ labels.SIZ }}</span>
                  </th>

                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1"
                      >{{ labels.DESC }}</span
                    >
                  </th>

                  <th class="text-left">
                    <span class="font-weight-boald subtitle-1">{{ labels.DEL }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                
                <tr v-for="(dataSch, index) in dataSchema" :key="index">
                  <td>{{ dataSch.name }}</td>
                  <td>{{ dataSch.type }}</td>
                  <td>{{ dataSch.isnull }}</td>
                  <td>{{ dataSch.datalength }}</td>
                  <td>{{ dataSch.description }}</td>
                  <td @click.prevent="dataSchema.splice(index,1)"><v-icon color="error">mdi-trash-can </v-icon></td>
                </tr>
                 
                <tr v-if="!dataSchema.length">
                  <td class="grey lighten-5 text-center grey--text" colspan="7">
                     {{ labels.NOD }}
                  </td>
                  <!-- <td>Name</td>
                              <td><v-icon>mdi-trash-can </v-icon></td> -->
                </tr>
                
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import API_Util from '../../services/util/API_Util.js';
export default {
  name: "dataSchema",
  props: ['microAllData', 'labels'],
  data: () => ({
    apiKey: "",
    checkbox: true,
    micro_schema: [],
    itemtype: "",
    dataSchema: [],
    dataSchemaObj: {isnull: 'no'},
    owner: "",
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
    this.getFunSchemaType();
    if (this.microAllData.id > 0) {
      if (this.microAllData.dataSchema) {
        this.dataSchema = this.microAllData.dataSchema;
      }
    }
  },
  methods: {
    isNullCheckBox(data) {
      if (data) {
        this.dataSchemaObj.isnull = 'yes';
      } else {
        this.dataSchemaObj.isnull = 'no';
      }
    },
    async getFunSchemaType() {
     var data = {
      "owner_id":localStorage.getItem("owner"),"lang":"EN","code":"DATA_TYPE"
    };
    let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'x-api-key': this.apiKey
            }
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
        this.micro_schema = response;
    },
    addDataSchema() {
      this.dataSchema.push(this.dataSchemaObj);
      this.dataSchemaObj = {};
      this.microAllData.dataSchema = this.dataSchema;
    }
  },
  watch: {
    microAllData(data) {
      this.$emit('microDataSubmitted', data);
    }
  }
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>