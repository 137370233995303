<template>

  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>
          {{ labels.DISC_DET }}</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text>
      <div class="px-10 msForm">
        <!-- -----------Basic Info------------>
        <v-col cols="12" class="px-0 pb-5">
          <h2>{{ labels.DISC }}</h2>
        </v-col>
        <v-row
          class="grey lighten-5 rounded-3"
          style="
            border: 1px solid var(--v-secondary-lighten2) !important;
            border-radius: 5px;
          "
        >
          <v-col cols="12">
            <label class="my-4">{{ labels.NM }}</label>
            <div>{{newDiscount.name}}</div>
          </v-col>

          <v-col cols="12" sm="6">
            <label class="my-4">{{ labels.S_DA }}</label>
            <div>{{newDiscount.sdate}}</div>
          </v-col>
          
          <v-col cols="12" sm="6">
            <label class="my-4">{{ labels.E_DA }}</label>
            <div>{{newDiscount.edate}}</div>
          </v-col>

          <v-col cols="12">
            <label class="my-4">{{ labels.DISC }}</label>
            <div>{{newDiscount.discount}}</div>
          </v-col>
          <v-col cols="12">
            <label class="my-4">{{ labels.MIC }}</label>
            <div>
              <div v-for="mic in getMicroList(newDiscount.micros)" :key="mic">{{mic}}</div>
            </div>
          </v-col>
        </v-row>        
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="py-6">
      <v-spacer></v-spacer>
      <v-btn class="nocap" @click="closeDetailDialog" text>
        <span class=""> {{ labels.CAN }}</span>
      </v-btn>
      <v-btn class="nocap primary" text @click="discountEditDialog()">
        <span class="">
          <v-icon>mdi-square-edit-outline</v-icon>
          {{ labels.EDI }}
        </span>
      </v-btn>
    </v-card-actions>       
  </v-card>
</template>
<script>
export default {
  name: "discountdetail", 
  props: ['newDiscount', 'microArray', 'labels'],
  data: () => ({
    micro_list: [],
    checkbox: true,
    iconlist: [],
    text: "",
    itemsSource: [
      {
        text: "Nature",
        icon: "mdi-pencil-outline ",
      },
      {
        text: "Nightlife",
        icon: "mdi-pencil-outline ",
      },
    ],
    msType: ["Atomistic", "Pipeline"],
  }),
  methods: {    
    discountEditDialog(){
      this.$emit('closeDetailDialog', false);
    },
    closeDetailDialog(){
      this.$emit('closeDiscountDetail', false)
    },
    getMicroList(obj){
      var microNames = []
      obj.forEach((id) => {
        this.microArray.forEach((value) => {
          if(value.id === parseInt(id)){
            microNames.push(value.name)
          }
        })
      })
      return microNames
    },
    onUpdate(text) {
      this.text = text;
    },
    onChangefile(fieldname, imageref) {
      this[fieldname] = [...this.$refs[fieldname].files];

      if (fieldname != "imagelist" && this[fieldname].length > 1) {
        this[fieldname].splice(0, this[fieldname].length - 1);
      }

      for (let i = 0; i < this[fieldname].length; i++) {
        let reader = new FileReader();
        reader.onload = () => {
          this.$refs[`${imageref}` + parseInt(i)][0].src = reader.result;
        };
        reader.readAsDataURL(this[fieldname][i]);
      }
    },
  },
};
</script>

<style lang="scss">
.v-input__slot {
  min-height: 40px !important;
}
</style>