import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import microservices from "../components/microservices.vue"
import popup from "../components/funDialog.vue"
import owner from "../components/owner.vue"
import Header from "../components/Header.vue"
import login from "../components/login.vue"
import navigation from "../components/navigation.vue"
import content from "../components/content.vue"
import ownersettings from "../components/ownersettings.vue"
import planDetails from "../components/planDetails.vue"
import notifications from "../components/notifications.vue"
import Footer from "../components/Footer.vue"
import ownerlisting from "../components/ownerlisting.vue"
import platformFunction from "../components/functionPage.vue"
import demoapp from "../components/demoAppPage.vue"
import promocode from "../components/promocode.vue"
import discount from "../components/discount.vue"
import referralcode from "../components/referralcode.vue"
import predefined from "../components/predefined.vue"
import language from "../components/language.vue"
import label from "../components/label.vue"
import error from "../components/error.vue"
import codelist from "../components/codelist.vue"
import valuelist from "../components/valuelist.vue"
import valuesublist from "../components/valuesublist.vue"
import headline from "../components/headline.vue"
import CreditManagement from "../components/CreditManagement.vue"
import registerMicroservice from "../components/registerMicroservice.vue"
// import organisations from "../components/organisations.vue"

import ManageOrganisations from '../components/org-admin/ManageOrganisations.vue'
//import OrganisationDetails from '../components/org-admin/OrganisationDetails.vue'
import ManageOrgDetails from '../components/org-admin/ManageOrgDetails'

// import { from } from 'core-js/core/array'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'login',
  //   components: {
  //     mainContent: login
  //   }
  // },
  {
    path: '/headline',
    name: 'headline',
    components: {
      mainContent: headline
    }
  },
  {
    path: '/navigation',
    name: 'navigation',
    components: {
      mainContent: navigation
    }
  },
  {
    path: '/Header',
    name: 'Header',
    components: {
      mainContent: Header
    }
  },
  {
    path: '/Footer',
    name: 'Footer',
    components: {
      mainContent: Footer
    }
  },
 
  {
    path: '/',
    name: '',
    redirect:'/ownerlisting',
    components: {
      mainContent: content
    },
    children: [{
      path: 'ownerlisting',
      name: 'ownerlisting',
      component: ownerlisting
    },{
      path: '/ownersettings',
      name: 'ownersettings',
      component: ownersettings
    },
    {
      path: '/creditmanagement',
      name: 'CreditManagement',
      component: CreditManagement
    },
    {
      path: '/planDetails',
      name: 'planDetails',
      component: planDetails
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: notifications
    },
    {
      path: '/registermicroservice',
      name: 'registerMicroservice',
      component: registerMicroservice
    },
  {
    path: '/microservices',
    name: 'microservices',
    component: microservices
  },
  {
    path: '/platformFunction',
    name: ' platformFunction',
    component:  platformFunction
  },
  {
    // * Changed from /addorg to /manage_organisations
    // * /addorg is not descriptive
    // path: "/addorg",
    // * For Sentient.io super admin use
    path: '/manageorgs',
    name: 'Manage Organisations',
    meta: {
      title: 'Manage Organisations'
    },
    component: ManageOrganisations
  },
  {
    path: '/orgdetails',
    name: 'Organisation Details',
    meta: {
      title: 'Organisation Details | Organisations'
    },
    component: ManageOrgDetails
  },
  {
    path: '/manageOrgDetails',
    name: 'Organisation Details',
    meta: {
      title: 'Organisation Details | Organisations'
    },
    component: ManageOrgDetails
  },
  {
    path: '/promocode',
    name: ' promocode',
    component:  promocode
  },
  {
    path: '/discount',
    name: ' discount',
    component:  discount
  },
  {
    path: '/referralcode',
    name: ' referralcode',
    component:  referralcode
  },
  {
    path: '/demoapp',
    name: ' demoapp',
    component:  demoapp
  },
    {
      path: '/popup/:type',
      name: 'popup',
      component: popup
    },
    {
      path: '/owner',
      name: 'owner',
      component: owner
    },    
    {
      path: '/predefined',
      name: 'predefined',
      component: predefined
    },
    {
      path: '/language',
      name: 'language',
      component: language
    },
    {
      path: '/label',
      name: 'label',
      component: label
    },
    {
      path: '/error',
      name: 'error',
      component: error
    },
    {
      path: '/codelist',
      name: 'codelist',
      component: codelist
    },
    {
      path: '/valuelist',
      name: 'valuelist',
      component: valuelist
    },
    {
      path: '/valuesublist',
      name: 'valuesublist',
      component: valuesublist
    },
  ]
  },

  // {
  //   path: '/ownersettings/:orgId',
  //   name: 'ownersettings',
  //   components: {
  //     mainContent: ownersettings
  //   }
  // },

  
  {
    path: '/login',
    name: 'login',
    components: {
      mainContent: login
    }
  },
  {
    path: '/logout',
    name: 'login',
    components: {
      mainContent: login
    }
  },
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to,from) => {
  console.log("tooo", to, from);
  if (JSON.parse(localStorage.getItem('MARK')) && (to.path =='/ownerlisting' || to.path == '/ownersettings' || to.path == '/microservices' || to.path == '/platformFunction' || to.path == '/organisations' || to.path == '/demoapp' || to.path == '/popup' || to.path == '/owner' || to.path == '/predefined' || to.path == '/language' || to.path == '/label' || to.path == '/error' || to.path == '/codelist' || to.path == '/valuelist' || to.path == '/valuesublist')) {
    router.push("/discount");
}

if(to.path == "/logout"){
  window.localStorage.clear();
}

})

export default router