<template>
  <div class="msForm">
    <v-row>
      <v-col cols="12" sm="6">
        <label>{{ labels.MSNAME }}</label>
        <v-text-field class="mt-2" outlined placeholder="Microservice Name"
          v-model.trim="regmicroalldata.name"></v-text-field>
        <span v-if="!regmicroalldata.name && !validForm" style="color:red;">{{ labels.NAMER }}</span>
      </v-col>
      <v-col class="pl-8" cols="12" sm="6">
        <label class="ml-1">{{ labels.DEPTYPE }}</label>
        <v-radio-group v-model="regmicroalldata.deployment_type" row class="mt-0">
          <v-radio label="New" value="new"></v-radio>
          <v-radio label="Upgrade" value="upgrade"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" class="pt-0">
        <label>{{ labels.DESC }}</label>
        <mavon-editor v-model="regmicroalldata.description" class="mt-2" language="en" outlined name="input-7-1"
          rows="3" />
      </v-col>
      <span v-if="!regmicroalldata.description && !validForm" style="color:red;padding-left: 12px;">{{ labels.DESCR
      }}</span>
      <v-col cols="12">
        <label class="model-img">{{ labels.MODIMAGE }}</label>
        <span class="pl-5 pt-3 tool-tip-icon">
          <v-tooltip top max-width="200">
            <template v-slot:activator="{ on, attrs }">
              <v-icon dark v-bind="attrs" v-on="on">
                fa-question-circle
              </v-icon>
            </template>
            <span>{{ labels.MODEL_MSG }}</span>
          </v-tooltip>
        </span>
        <div class="">
          <div class="d-flex flex-column" style="width: 135px">
            <div class="position-relative pt-3"
              v-if="!regmicroalldata.model_image_file && !regmicroalldata.model_image_url" @dragover.prevent="dragOver"
              @dragleave.prevent="dragLeave" @drop.prevent="drop($event, 'icon')">
              <div class="text-center d-flex-column justify-center fileBox">
                <v-icon class="secondary--text"> mdi-cloud-upload</v-icon>
                <div>Upload</div>
              </div>
              <input type="file" class="clicktofileUpload overflow-hidden w-px h-px opacity-0"
                @change="modelImageFileChange($event.target.files, 'icon')" accept=".zip,.rar,.7zip,.tar,.gz" />
            </div>
          </div>
          <!-- <div v-if="this.regmicroalldata.docs_yaml_url" class="mt-2" style="position: relative;">
            <button class="pos close-iconPos" type="button" @click="clearIcon" title="Remove file">
              <v-icon color="error">mdi-close-circle-outline </v-icon>
            </button>
          </div> -->
          <p class="shrink rounded  fileimgWidth logoBoxSize mt-1" v-if="regmicroalldata.model_image_url" alt="Model file"
            contain aspect-ratio="2">{{ regmicroalldata.model_image_url }}
            <button class="pos close-iconPos" type="button" @click="clearIcon" title="Remove file">
              <v-icon color="error">mdi-close-circle-outline </v-icon>
            </button>
          </p>
        </div>
        <span v-if="!regmicroalldata.model_image_url && !validForm" style="color:red;">{{ labels.MODIMAGER }}</span>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-row>
          <v-col cols="12">
            <h3>{{ labels.RESD }}</h3>
          </v-col>
          <v-col cols="12" sm="8" class="d-flex">
            <div class="mr-12">
              <label>{{ labels.CPU }}</label>
              <v-text-field class="mt-2" outlined v-model.trim="regmicroalldata.resource_cpu"
                oninput="this.value=this.value.replace(/[^\d.-]+/g, '');"></v-text-field>
              <span v-if="!regmicroalldata.resource_cpu && !validForm" style="color:red;">{{ labels.CPUR }}</span>
              <span v-if="datacheck.cpu < regmicroalldata.resource_cpu && !validForm && regmicroalldata.resource_cpu"
                style="color:red;">{{ labels.ISCPU }}</span>
            </div>
            <div class="ml-12">
              <label>{{ labels.MEM }}</label>
              <v-text-field class="mt-2" outlined v-model.trim="regmicroalldata.resource_memory"
                oninput="this.value=this.value.replace(/[^\d.-]+/g, '');"></v-text-field>
              <span v-if="!regmicroalldata.resource_memory && !validForm" style="color:red;">{{ labels.MEMR }}</span>
              <span
                v-if="datacheck.memory < regmicroalldata.resource_memory && !validForm && regmicroalldata.resource_memory"
                style="color:red;">{{ labels.ISMEM }}</span>
            </div>
          </v-col>
          <v-col class="pl-8" cols="12" sm="4">
            <label class="ml-1 model-img">{{ labels.GPU }}</label>
            <span class=" pt-3 pl-2 tool-tip-icon">
              <v-tooltip top max-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon dark v-bind="attrs" v-on="on">
                    fa-question-circle
                  </v-icon>
                </template>
                <span>{{ labels.GPU_MSG }}</span>
              </v-tooltip>
            </span>
            <v-radio-group v-model="regmicroalldata.enable_gpu" row class="mt-0">
              <v-radio label="Yes" value="yes"></v-radio>
              <v-radio label="No" value="no"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="">
        <v-row>
          <v-col cols="12" class="pl-0">
            <label>{{ labels.EV }}</label>
          </v-col>
          <v-col cols="12" sm="5" class="px-5 pb-5 secondary lighten-3">
            {{ labels.EVK }}
            <v-text-field outlined placeholder="Key" v-model="requestEnvObject.key"></v-text-field>
          </v-col>
          <v-col cols="12" sm="5" class="px-5 pb-5 secondary lighten-3">
            {{ labels.EVV }}
            <div class="patchInput">
              <v-text-field outlined v-model="requestEnvObject.value"></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="2" md="2" lg="2" class="px-5 pb-5 secondary lighten-3">
            <div class="d-flex flex-column px-2">
              <span class="ml-2">
                <v-btn class="nocap primary white--text" style="height: 41px; margin-top:21px;"
                  :disabled="!requestEnvObject.key || !requestEnvObject.value" @click.prevent="addEnVariable">
                  <v-icon>mdi-plus </v-icon>
                </v-btn></span>
            </div>
          </v-col>
          <v-col cols="12" class=" px-0 lighten-3 my-4" v-if="regmicroalldata.env_variables.length">
            <v-card :elevation="hover ? 24 : 2" style="width: 100%">
              <v-simple-table class="">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1">{{ labels.EVK }}</span>
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1">{{ labels.EVV }}</span>
                      </th>
                      <th class="text-left">
                        <span class="font-weight-boald subtitle-1">{{ labels.DEL }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(req, index) in regmicroalldata.env_variables" :key="req">
                      <td>{{ req.key }}</td>
                      <td>{{ req.value }}</td>
                      <td>
                        <v-icon color="error" @click="removeRequestVariable(index)">mdi-trash-can
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col cols="12" class="px-0">
            <label class="model-img">{{ labels.PORT }}</label>
            <span class="pl-2 pt-3 tool-tip-icon">
              <v-tooltip top max-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon dark v-bind="attrs" v-on="on">
                    fa-question-circle
                  </v-icon>
                </template>
                <span>{{ labels.PORT_MSG }}</span>
              </v-tooltip>
            </span>
            <v-text-field class="mt-3" v-model="regmicroalldata.model_image_port" outlined
              oninput="this.value=this.value.replace(/[^0-9]/g,'');"></v-text-field>
            <span v-if="!regmicroalldata.model_image_port && !validForm" style="color:red;">{{ labels.PORTR }}</span>
          </v-col>
          <v-col>
            <div class="ml-n3">
              <span text :type="response_status" style="color:red" v-if="response_message.length && !validForm">
                {{ response_message }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import API_Util from "../../services/util/API_Util.js";
export default {
  name: "regMsDetails",
  props: ['regmicroalldata', 'validForm', 'labels', 'uploadSourceCode', 'response_message', 'datacheck'],
  data: () => ({
    apiKey: "",
    requestEnvObject: {},
  }),
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.owner = localStorage.getItem("owner");
    if (this.regmicroalldata.id == 0) {
      this.regmicroalldata.deployment_type = "new";
    } else {
      this.regmicroalldata.deployment_type = "upgrade";
    }
  },
  methods: {
    addEnVariable() {
      this.regmicroalldata.env_variables.push(this.requestEnvObject)
      this.requestEnvObject = {};
    },
    removeRequestVariable(index) {
      this.regmicroalldata.env_variables.splice(index, 1)
    },
    clearIcon() {
      this.regmicroalldata.model_image_file = "";
      this.regmicroalldata.model_image_source = "";
      this.regmicroalldata.model_image_url = "";
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    modelImageFileChange(files, input) {
      if (input === "icon") {
        this.regmicroalldata.model_image_url = files[0].name;
        this.regmicroalldata.model_image_file = files[0];
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event, input) {
      event.preventDefault();
      this.modelImageFileChange(event.dataTransfer.files, input)
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
};
</script>
<style>
.v-dialog {
  box-shadow: none !important;
}
</style>
<style scoped>
.v-input__slot {
  min-height: 40px !important;
}

.fileimgWidth {
  max-width: fit-content;
  position: relative;
  margin-bottom: 0;
}

.msForm {
  padding: 0 24px 40px;
}

.close-iconPos {
  position: absolute;
  right: -30px;
  top: -2px;
}

.model-img .v-icon {
  font-size: 12px;
  background: #c1c1c1;
  border-radius: 50%;
  padding: 2px;
  color: #fff;
  margin-top: -2px;
  margin-left: 5px;
}

.icon_relates {
  position: relative;
}

.source_icon_position {
  position: absolute;
  top: 538px;
  right: 600px;
}

.tool-tip-icon {
  font-size: 20px !important;
  color: var(--v-primarytextcolor-darken1) !important;
  cursor: pointer !important;
}

.gpu_icon_position {
  position: absolute;
  top: 773px;
  right: 230px;
}

.port_icon_position {
  position: absolute;
  top: 88%;
  right: 85%
}

/* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .msForm {
    padding: 0;
  }
}</style>
