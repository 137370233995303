import './installCompositionApi.js'

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
// import './styles/style.scss'
import './styles/style.scss'
import Vuetify from "vuetify";
import VueSession from 'vue-session'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import VueTagManager from 'vue-tag-manager'

var options = {
  persist: true
}

Vue.use(VueSession, options)
Vue.config.productionTip = false;
Vue.use(Vuetify);
Vue.use(mavonEditor)

if (process.env.VUE_APP_GTM !=undefined && process.env.VUE_APP_GTM !="" && process.env.VUE_APP_GTM !=null){
  Vue.use(VueTagManager, {
    gtmId: process.env.VUE_APP_GTM
  })
}


new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
 