<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>{{ labels.ED_MICRO }}</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text class="px-6">
      <!-- EXPANSION Basic Info  PANEL -->
      <v-col cols="12" class="panelBorder pa-0" v-if="micro_type==='DUP' || micro_type==='OWN'">
        <v-expansion-panels v-model="basicPanel" multiple>
          <v-expansion-panel >
            <v-expansion-panel-header>
              <h3 class="pl-10 secondary--text text--darken-4">Basic Info</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <msbasicinfo :microAllData="microAllData" :validForm="validForm" :labels="labels" @microDataSubmitted="updateMicroAllData"></msbasicinfo>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- EXPANSION PANEL END -->
      <!-- EXPANSION Descriptions  PANEL -->
      <v-col cols="12" class="panelBorder pa-0" v-if="micro_type==='DUP' || micro_type==='OWN'">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="pl-10 secondary--text text--darken-4">Descriptions</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <msDescription :microAllData="microAllData" :labels="labels"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- EXPANSION PANEL END -->
      <!-- EXPANSION Microservice Request  PANEL -->
      <v-col cols="12" class="panelBorder pa-0" v-if="micro_type==='DUP' || micro_type==='OWN'">
        <v-expansion-panels v-model="requestPanel" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="pl-10 secondary--text text--darken-4">
                Microservice Type
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <mstype :microAllData="microAllData" :request_type="request_type" :request_version="request_version" :labels="labels" :validForm="validForm" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- EXPANSION PANEL END -->
      <!-- EXPANSION Pricing and Plan  PANEL -->
      <v-col cols="12" class="panelBorder pa-0" v-if="micro_type==='OWN'">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="pl-10 secondary--text text--darken-4">
                Pricing and Plan
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <mspricingPlan :microAllData="microAllData" :labels="labels" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- EXPANSION PANEL END -->
      <!-- EXPANSION Data Schema  PANEL -->
      <v-col cols="12" class="panelBorder pa-0" v-if="microAllData.clas === 'CLASS_DATA' && (micro_type==='DUP' || micro_type==='OWN')">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="pl-10 secondary--text text--darken-4">Data Schema</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <dataSchema :microAllData="microAllData" :labels="labels" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- EXPANSION PANEL END -->
      <!-- EXPANSION Additional Info  PANEL -->
      <v-col cols="12" class="panelBorder pa-0" v-if="micro_type==='DUP' || micro_type==='OWN'">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="pl-10 secondary--text text--darken-4">
                Additional Info
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <msAdditionalInfo :microAllData="microAllData" :micro_source="micro_source" :labels="labels" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- EXPANSION PANEL END -->
      <!-- EXPANSION Access Details  PANEL -->
      <v-col cols="12" class="panelBorder pa-0" v-if="micro_type==='DUP' || micro_type==='OWN'">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="pl-10 secondary--text text--darken-4">
                Access Details
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <msAccessDetail :microAllData="microAllData" :validForm="validForm" :labels="labels" :micro_type="micro_type" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <msAccessDetail v-else :microAllData="microAllData" :validForm="validForm" :labels="labels" :micro_type="micro_type" />
      <!-- EXPANSION PANEL END -->
      <!-- EXPANSION Validation Settings  PANEL -->
      <v-col cols="12" class="panelBorder pa-0" v-if="micro_type==='DUP' || micro_type==='OWN'">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="pl-10 secondary--text text--darken-4">
                Validation Settings
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <msValidationSettings :microAllData="microAllData" :micro_limits="micro_limits" :labels="labels" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <!-- EXPANSION PANEL END -->
      <div class="px-md-10">
      <v-alert
        text
        :type="response_status"
        v-if="response_message.length"
      >
        {{ response_message }}
      </v-alert>
      </div>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      
      <div class="pl-10">
        <v-btn class="nocap" @click="closeMsEdit" text>
          <span class=""> {{ labels.CAN }}</span>
        </v-btn>
        <v-btn class="nocap primary" text @click="msEditSave()">
          <span class=""> {{ labels.SAV }}</span>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import msDescription from "./msPopUp/msDescription.vue";
import msAdditionalInfo from "./msPopUp/msAdditionalInfo.vue";
import msAccessDetail from "./msPopUp/msAccessDetail.vue";
import msValidationSettings from "./msPopUp/msValidationSettings.vue";
import dataSchema from "./msPopUp/dataSchema.vue";
import mspricingPlan from "./msPopUp/mspricingPlan.vue";
import msbasicinfo from "./msPopUp/msbasicinfo.vue";
import Mstype from './msPopUp/mstype.vue';
export default {
  components: {
    msbasicinfo,
    msDescription,
    msAdditionalInfo,
    msAccessDetail,
    msValidationSettings,
    dataSchema,
    mspricingPlan,
    Mstype,
  },
    Mstypename: "msEditPage",
  props: ['microAllData', 'micro_source', 'micro_limits', 'request_type','request_version','response_status', 'response_message', 'validForm', 'labels', 'micro_type'],
  data: () => ({
    checkbox: true,
    basicPanel: [],
    requestPanel: [],
  }),
  created() {
    this.validForm = false;
  },
  methods: {
    closeMsEdit() {
      this.$emit('closeMsEdit', false);
    },
    msEditSave() {
        if (this.microAllData.name == "") {
            this.validForm = false;
            this.basicPanel = [0];
        } else if (this.microAllData.code == "") {
            this.validForm = false;
            this.basicPanel = [0];
        } else if (this.microAllData.clas == "") {
           this.validForm = false;
           this.basicPanel = [0];
        } else if (this.microAllData.fnType == "") {
           this.validForm = false;
           this.basicPanel = [0];
        } else if (this.microAllData.urlCode == "") {
            this.validForm = false;
            this.basicPanel = [0];
        } else if (!this.microAllData.requestTypes || this.microAllData.requestTypes.length == 0) {
            this.validForm = false;
            this.requestPanel = [0];
        }  else {
        if (this.microAllData.micro_type=='DUP') {
          this.microAllData.id = 0;
        }
           this.$emit("msEditSave");
        } 
      
    },
    onUpdate(text) {
      this.text = text;
    },
  },
};
</script>

<style lang="scss">
.chevron-down-circle-outline {
  color: red;
}
.v-expansion-panel-header__icon .v-icon {
  color: red !important;
}

.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color:  var(--v-secondary-darken4) !important;
  border: 1px solid  var(--v-secondary-darken1) !important;
  border-radius: 100% !important;
}
</style>