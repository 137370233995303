<template>
  <div>
    <!-- <navigation/> -->
    <div>
      <v-breadcrumbs :items="items" v-if="ownerDrop">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" v-if="ownerDrop"/>
      </div>
      <v-content class="white maincontent">
        <div class="pb-4">
          <div class="d-flex justify-space-between align-center">
            <!-- <div cols="12 px-0" v-if="ownerDrop">
              <a>
                <v-btn text class="nocap px-0 px-0" @click="$router.go(-1)">
                  <span class="grey--text">
                    <v-icon style="margin-left: -10px">mdi-arrow-left</v-icon>
                    {{ labels.ALL_OWNS }}
                  </span>
                </v-btn>
              </a>
            </div> -->
            <div>
              <h2 class="text-center font-weigt-bold">{{ labels.CREATE }}</h2>
            </div>
            <div class="text-right">
              <v-spacer></v-spacer>
              <!-- <v-btn
                class="tertiary2 white--text nocap py-5"
                @click.stop="addnewDMApps"
              >
                <v-icon class="pr-1 add">mdi-plus-circle</v-icon>
                {{labels.CREATE}}
              </v-btn> -->
            </div>
          </div>
          <div
            class="
              d-flex
              flex-md-row flex-sm-column
              justify-start
              align-md-end
              justify-md-space-between
            "
          >
        <div> 
          <v-chip outlined
            @click="makeSelectedEmpty"
            class="ma-2 white"
          v-if="selectedDemoapps.length"
          >
            <v-icon>mdi-close-circle</v-icon><span>&nbsp;</span>  {{ selectedDemoapps.length }} selected
          </v-chip>
        </div>
            <div>
              <v-btn
                text
                class="tertiary2--text nocap py-5"
                @click.stop="addnewDMApps"
              >
              
                <v-icon class="pr-1 add" style="font-size: 26px"
                  >mdi-play-protected-content
                </v-icon>
                {{ labels.CREATE }}
              </v-btn>
              <v-btn
                text
                @click.stop="deletDemoapps = true"
                :disabled="!selectedDemoapps.length"
                class="error--text nocap py-5"
              >
                <v-icon class="pr-1 add" style="font-size: 26px"
                  >mdi-trash-can
                </v-icon>
                {{ labels.DELETE }}
              </v-btn>
            </div>
          </div>

          <div>
            <v-data-table
              
              v-model="selectedDemoapps"
              :headers="headers"
              :items="demoappsListing"
              item-key="name"
              show-select
              @click:row="openDemoappsDetail"
              class="elevation-1 listingTable"
            >
            </v-data-table>
          </div>
          <v-row justify="center" style="margin-bottom: 25px !important;">
            <v-form ref="form" v-model="valid">
              <v-dialog
                class="stepBystep"
                scrollable
                v-model="addnewDemoapp"
                persistent
                max-width="1000px"
              >
                <v-card class="ms-title">
                  <v-card-title>
                    <div class="py-2">
                      <h3 class="text-center">{{ labels.CREATE }}</h3>
                    </div>
                  </v-card-title>
                  <v-divider class="my-4"></v-divider>
                  <v-card-text style="height: 400px">
                    <div class="d-flex">
                      <v-stepper
                        alt-labels
                        v-model="currentStep"
                        flat
                        class="stepper sticky"
                      >
                        <div style="max-width:500px; margin: auto;" class="text-center">
                          <v-stepper-header flat>
                            <template v-for="(item, index) in stepperData">
                              <v-stepper-step
                                :key="index"
                                :complete="currentStep > index + 1"
                                :step="index + 1"
                              >
                                {{ item.title }}
                              </v-stepper-step>

                              <v-divider
                                :key="index"
                                v-if="index < 1"
                              ></v-divider>
                            </template>
                          </v-stepper-header>
                        </div>

                        <v-stepper-items>
                          <v-stepper-content step="1">
                            <v-col cols="12" class="px-10">
                              <h3>Basic info</h3>
                            </v-col>
                            <addDemoAppInfo
                              :demoappsAllData="demoappsAllData"
                              :isValidForm="isValidForm"
                              :labels="labels"
                            />
                          </v-stepper-content>

                          <v-stepper-content step="2">
                            <v-col cols="12" class="px-10">
                              <h3>Additional Info</h3>
                            </v-col>
                            <demoAdditionalInfo
                              :demoappsAllData="demoappsAllData"
                              :msListing="msListing"
                              :labels="labels"
                              :demo_apps_iconlist="demo_apps_iconlist"
                              :demo_category="demo_category"
                              @demoappsAllDataSubmitted="
                                demoappsAllDataSubmitted
                              "
                              :isValidForm="isValidForm"
                            />
                          </v-stepper-content>
                        </v-stepper-items>
                      </v-stepper>
                    </div>
                    <div class="my-4 px-md-16">
                      <v-alert
                        class="text-left"
                        text
                        :type="response_status"
                        v-if="response_message.length"
                      >
                        {{ response_message }}
                      </v-alert>
                    </div>
                  </v-card-text>
                  <v-card-actions class="px-10 py-6 text-center">
                    <v-btn
                      class="nocap"
                      large
                      text
                      v-if="currentStep == 1"
                      @click="addnewDemoapp = false"
                    >
                      {{ labels.CAN }}
                    </v-btn>

                    <v-btn
                      class="nocap mx-5"
                      large
                      text
                      @click="backStep()"
                      v-if="currentStep > 1"
                    >
                      {{ labels.BK }}
                    </v-btn>

                    <v-btn
                      class="nocap mx-5"
                      large
                      v-if="currentStep < 2"
                      color="primary"
                      @click="nextStep()"
                    >
                      {{ labels.NXT }}
                    </v-btn>
                    <v-btn
                      class="nocap mx-5"
                      large
                      v-if="currentStep == 2"
                      @click="save"
                      color="primary"
                    >
                      {{ labels.MIC_SAVE }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </v-row>
          <!-- Success pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="successDemo"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-img
                    style="z-index: 1"
                    alt="sentient logo"
                    class="d-flex align-center justify-center"
                    src="@/assets/tick-mark1.svg"
                    contain
                    max-width="192"
                  ></v-img>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <!-- <v-icon color="primary" class="text-center" style="font-size:60px">mdi-check-circle </v-icon> -->

                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Success</div>
                  </v-card-title>
                  <p v-if="response_msg.length">{{response_msg}}</p>
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap" color="primary" @click="successDemo = false">Done </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->
          <!-- delete pop up -->
          <v-row justify="center">
            <v-dialog
              class="msPop"
              style="box-shadow: none; background-color: transparent"
              v-model="deletDemoapps"
              persistent
              max-width="388"
              content-class="vDialog"
            >
              <v-card
                class="d-flex flex-column pa-0 align-center justify-center"
                flat
                style="background-color: transparent"
              >
                <v-card-title
                  class="text-h5 text-bold d-flex text-center pa-0"
                  style="margin-bottom: -19px"
                >
                  <v-avatar
                    color="error"
                    size="62"
                  >
                    <v-icon class="white--text text-h5">mdi-trash-can-outline</v-icon>
                  </v-avatar>
                </v-card-title>
                <v-card-text
                  class="text-center white rounded-lg"
                  style="height: 200px"
                >
                  <v-card-title class="text-h5 text-bold d-flex justify-center">
                    <div class="mt-4">Delete Confirmation</div>
                  </v-card-title>
                  Are you sure want to delete the selected demoapp?
                  <v-card-actions>
                    <div class="text-center mt-4">
                      <v-btn class="nocap mx-5" text @click="deletDemoapps = false">Cancel </v-btn>
                      <v-btn class="nocap mx-5" color="error" @click="deleteDemoapps()">Delete </v-btn>
                    </div>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- End -->

          <!-- -------++++ Demo App Detail Pages open +++++--------->
          <v-dialog v-model="demoAppDetailDialog" width="1000" scrollable>
            <demoAppDetail
              :demoappsAllData="demoappsAllData"
              :labels="labels"
              @closeDetailDialog="closeDialog"
              @closeDetailDemoapps="closeDetailDemoapps"
            />
          </v-dialog>
          <!-- ----------------------------- -->
          <!-- -------++++ Edit Demo App +++++--------->
          <v-dialog v-model="demoAppEditDialog" width="1000" scrollable>
            <demoAppEdit
              :demoappsAllData="demoappsAllData"
              :msListing="msListing"
              :demo_category="demo_category"
              :labels="labels"
              :demo_apps_iconlist="demo_apps_iconlist"
              :response_message="response_message"
              :response_status="response_status"
              @closeDetailDemoapps="closeDetailDemoapps"
              @updatedDemoappsSubmitted="save"
            />
          </v-dialog>
          <!-- ----------------------------- -->
        </div>
      </v-content>
    </div>
    <loaderimg v-if="loader" />
  </div>
</template> 
<script>
// import navigation from "./navigation.vue";
import loaderimg from "./loaderimg.vue";
import API_Util from "../services/util/API_Util.js";
import demoAppEdit from "./demoAppEditPage.vue";
import demoAppDetail from "./demoAppDetailPage.vue";
import addDemoAppInfo from "./demoPopup/addDemoapp.vue";
import demoAdditionalInfo from "./demoPopup/demoAdditionalInfo.vue";
import headline from "./headline.vue";

export default {
  components: {
    demoAppEdit,
    demoAppDetail,
    demoAdditionalInfo,
    addDemoAppInfo,
    loaderimg,
    headline
  },
  name: "demoapp",
  data: () => ({
    response_msg: "",
    demoAppDetailDialog: false,
    demoAppEditDialog: false,
    currentStep: 1,
    successDemo: false,
    stepperData: [
      {
        title: "Basic Info",
      },

      {
        title: "Additional Info",
      },
    ],
    show1: false,
    password: "Password",
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
    addnewDemoapp: false,
    singleSelect: false,
    selected: [],
    demoappsListing: [],
    lang: "EN",
    labels: {},
    loading: false,
    search: "",
    selectedInt: [],
    selectedMs: [],
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: false,
        href: "",
      },
      {
        text: "Content - Demo Apps",
        disabled: true,
        href: "",
      },
    ],
    demoappsAllData: {
      id: 0,
      name: "",
      desc: "",
      desc2: "",
      demoappsUseCase: "",
      category: "",
      functions: [],
      dateUpdated: "",
      updatedBy: "",
      tags: "",
      keywords: "",
      deleteTags: [],
      demolink: "",
      codelink: "",
      urlcodems: "",
      mIconUrl: "",
      ownerId: "",
      logo_file: "",
      demo_images: [],
      images: [],
      img_deleted_array: [],
    },
    apiKey: "",
    owner: "",
    msListing: [],
    demo_category: [],
    user_id: "",
    refactored_related_funs: [],
    selectedDemoapps: [],
    deletDemoapps: false,
    selectedOwner: "",
    ownerList: [],
    response_status: "",
    response_message: "",
    isValidForm: true,
    ownerName: "",
    loader: true,
    ownerType: "",
    ownerDrop: "",
    ownerDesc: "",
    demo_apps_iconlist:[],
  }),

  computed: {
    allSelectedInt() {
      return this.selectedInt.length === this.items.length;
    },
    allSelectedMs() {
      return this.selectedMs.length === this.msSelect.length;
    },
    categoriesInternal() {
      const search = this.search.toLowerCase();

      if (!search) return this.items;

      return this.items.filter((item) => {
        const text = item.text.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    categoriesMS() {
      const search = this.search.toLowerCase();

      if (!search) return this.msSelect;

      return this.msSelect.filter((item) => {
        const text = item.text.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    selectionsInt() {
      const selections = [];

      for (const selection of this.selectedInt) {
        selections.push(selection);
      }

      return selections;
    },

    selectionsMs() {
      const selections = [];

      for (const selection of this.selectedMs) {
        selections.push(selection);
      }

      return selections;
    },
  },

  watch: {
    selectedInt() {
      this.search = "";
    },
    selectedMs() {
      this.search = "";
    },
  },
  created() {
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerName = sessionObj.session.ownerName;
    this.user_id = sessionObj.session.id;
    this.ownerType = localStorage.getItem("ownerType");
    this.selectedOwner = localStorage.getItem("ownerName");
    this.owner = localStorage.getItem("owner");
    this.ownerDesc = localStorage.getItem('ownerDesc');
    this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
    this.items[1].text =
      "Sentient.io (" + localStorage.getItem("ownerName") + ")";
    this.items[1].href = "/ownersettings";
    this.getOrgList();
    this.getMicroserviceList();
    this.getCategory();
    this.getDemoappsList();
    this.getFlexi();
    this.getRequestDemoapps();
  },
  methods: {
    changeOwner(obj) {
      this.loader = true;
      localStorage.setItem("owner", obj.ownerId);
      localStorage.setItem("ownerName", obj.ownerName);
      localStorage.setItem("ownerType", obj.ownerType);
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
      this.$emit("ownerchange", obj.ownerType);
      this.items[1].text =
        "Sentient.io (" + localStorage.getItem("ownerName") + ")";
      this.getMicroserviceList();
      this.getCategory();
      this.getDemoappsList();
    },
    async getOrgList() {
      var empt = {}
      await API_Util.URLS.axios
        .post(API_Util.URLS.listOrg, empt, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.ownerList = response.data.results;
        });
    },
    cancelDelete(obj) {
      obj.value = false;
      this.selectedDemoapps = [];
    },
    deleteDemoapps() {
      let toDeleteIds = [];
      this.selectedDemoapps.forEach(function (arrayItem) {
        toDeleteIds.push(arrayItem.id);
      });
      API_Util.URLS.axios
        .post(API_Util.URLS.deleteDemoapps, toDeleteIds, {
          headers: { "x-api-key": this.apiKey },
          params: {},
        })
        .then((response) => response)
        .catch((err) => console.log(err));
      toDeleteIds = [];
      this.selectedDemoapps = [];
      this.deletDemoapps = false;
      setTimeout(() => {
        this.getDemoappsList();
      }, 200);
    },
    clearDemoappsAllData() {
      this.demoappsAllData = {
        id: 0,
        name: "",
        desc: "",
        desc2: "",
        demoappsUseCase: "",
        category: "",
        functions: [],
        dateUpdated: "",
        updatedBy: "",
        tags: "",
        keywords: "",
        deleteTags: [],
        demolink: "",
        codelink: "",
        urlcodems: "",
        mIconUrl: "",
        ownerId: "",
        logo_file: "",
        demo_images: [],
        images: [],
        img_deleted_array: [],
        refactored_related_funs: [],
      };
      this.isValidForm = true;
      this.response_status = "";
      this.response_message = "";
      this.currentStep = 1;
    },
    addnewDMApps() {
      this.addnewDemoapp = true;
      this.clearDemoappsAllData();
    },
    closeDetailDemoapps(emittedValue) {
      this.demoAppDetailDialog = emittedValue;
      this.demoAppEditDialog = emittedValue;
    },
    async openDemoappsDetail(obj) {
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.infoDemoapps, obj.id, {
          headers: { "x-api-key": this.apiKey },
          params: {},
        })
        .then((response) => response)
        .catch((err) => console.log(err));
      //this.demoappsAllData = response.data;
      var keyArray = Object.keys(response.data);
      keyArray.forEach((obj) => {
        this.demoappsAllData[obj] = response.data[obj];
      });
      this.demoAppDetailDialog = true;
    },
    async getDemoappsList() {
      let response = await API_Util.URLS.axios
        .post(
          API_Util.URLS.listDemoapps, localStorage.getItem("owner"),
          {
            headers: { "x-api-key": this.apiKey },
          }
        )
        .then((response) => response.data)
        .catch((err) => console.log(err));
      this.demoappsListing = response.data;
    },
    demoappsAllDataSubmitted(emitted_data) {
      this.demoappsAllData = emitted_data;
    },
    async save() {
      if (this.demoappsAllData.id > 0) {
        this.currentStep = 2;
      }
      if (this.currentStep == 2) {
        if (this.demoappsAllData.category == "") {
          this.isValidForm = false;
        } else if (this.demoappsAllData.urlcodems == "") {
          this.isValidForm = false;
        } else if (this.demoappsAllData.name == "") {
          this.isValidForm = false;
        }
        else {
          this.isValidForm = true;

          if (this.demoappsAllData.id > 0) {
            if (!this.demoappsAllData.deleteTags) {
              this.demoappsAllData.deleteTags = [];
            }
          }
          // for (let item of this.demoappsAllData.functions) {
          //   if (!item.id) {
          //     this.refactored_related_funs.push({
          //       id: 0,
          //       fId: item.function_id,
          //       fName: item.funtion_name,
          //     });
          //   }
          // }
          // this.demoappsAllData.functions = this.refactored_related_funs;
          // this.refactored_related_funs = [];
          this.demoappsAllData.updatedBy = this.user_id;
          this.demoappsAllData.ownerId = localStorage.getItem("owner");
          let response = await API_Util.URLS.axios
            .post(API_Util.URLS.addDemoapps, this.demoappsAllData, {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "x-api-key": this.apiKey,
              },
            })
            .then((response) => response)
            .catch((err) => console.log(err));
          var timeout = 3000;
          if (response.data.message == "NAME_EXISTS") {
            this.response_message = "Name or Urlcode already exists";
            this.response_status = "error";
          } else {
            //// FILE UPLOAD PART START ////
            var demo_id = response.data.id;
            if (
              this.demoappsAllData.logo_file != undefined &&
              this.demoappsAllData.logo_file != ""
            ) {
              this.uploadIcons(this.demoappsAllData.logo_file, demo_id, "logo");
            }

            if (this.demoappsAllData.demo_images.length > 0) {
              this.demoappsAllData.demo_images.forEach((obj) => {
                if (obj.file != undefined && obj.file != "") {
                  this.uploadIcons(obj.file, demo_id, "img");
                  timeout = timeout + 1000;
                }
              });
            }
            // Removed deleted demoapp images array
            if (this.demoappsAllData.img_deleted_array.length > 0) {
              this.deleteImages(this.demoappsAllData.img_deleted_array);
            }
            //// FILE UPLOAD PART END ////
            if(this.demoappsAllData.id > 0){
              this.response_msg = "Successfully Updated";
              this.successDemo = true;
            }
            else{
              this.response_msg = "Successfully Created";
              this.successDemo = true;
            }
            setTimeout(() => {
              this.getDemoappsList();
              this.demoAppEditDialog = false;
              this.addnewDemoapp = false;
              this.clearDemoappsAllData();
            }, timeout);
          }
        }
      }
    },
    async uploadIcons(file, demoid, from) {
      const fd = new FormData();
      fd.append("fileToUpload", file, file.name);
      await API_Util.URLS.axios
        .post(API_Util.URLS.cdnImageUpload, fd, {
          headers: {
            mimeType: "multipart/form-data",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => {
          var url = response.data.url;
          if (from == "logo") {
            this.updateLogoUrl(url, demoid);
          } else {
            this.updateImgUrl(url, demoid);
          }
        })
        .catch((err) => console.log(err));
    },
    async updateLogoUrl(url, demoid) {
      var det = { "fId": demoid, "mIconUrl": url };
      await API_Util.URLS.axios
        .post(API_Util.URLS.updatemicroserviceicon, det, {
          params: {},
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((err) => console.log(err));
    },
    async updateImgUrl(url, demoid) {
      var data = {
        micId: demoid,
        imgUrl: url,
        dateUpdated: "",
        updatedBy: this.user_id,
      };
      await API_Util.URLS.axios
        .post(API_Util.URLS.updatemicroservicemultiimage, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((err) => console.log(err));
    },
    async deleteImages(ids) {
      var det = { "ids": JSON.stringify(ids.join()) }
      await API_Util.URLS.axios
        .post(API_Util.URLS.deletemicroservicemultiimage, det, {
          params: {},
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => {
          console.log(response);
        })
        .catch((err) => console.log(err));
    },
    async getCategory() {
      var data = {
        owner_id: localStorage.getItem("owner"),
        lang: "EN",
        code: "MICROSERVICE_CATEGORY",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      this.demo_category = response;
    },
    async getMicroserviceList() {
      var data = {owner_id: localStorage.getItem("owner"),type:'apis',user_id: this.usrId}
      let response = await API_Util.URLS.axios
        .post(
          API_Util.URLS.listMicroservice ,data,
          {
            headers: { "x-api-key": this.apiKey }
          }
        )
        .then((response) => response.data.results)
        .catch((err) => console.log(err));
      this.msListing = response;
      this.loader = false;
    },
    async getRequestDemoapps(){
      var data = {
        owner_id: localStorage.getItem("owner"),
        lang: this.lang,
        code: "DEMO_APPS_ICONS",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
        this.demo_apps_iconlist = response;
    },
    allOwners() {
      this.$router.push("/ownerlisting", () => {
        window.location.reload();
      });
    },
    closeDialog(value) {
      this.demoAppDetailDialog = value;
      this.demoAppEditDialog = true;
    },
    makeSelectedEmpty() {
      this.selectedDemoapps = [];
    },
    openFun(type) {
      this.$router.push({ name: "popup", params: { type: type } });
    },
    nextStep() {
      if (this.currentStep == 1) {
        if (this.demoappsAllData.name == "") {
          this.isValidForm = false;
        } else {
          this.isValidForm = true;
        }
      }
      if (this.currentStep < 2 && this.isValidForm) {
        this.currentStep = this.currentStep + 1;
      }
    },
    backStep() {
      if (this.currentStep > 1) {
        this.currentStep = this.currentStep - 1;
      }
    },
    next() {
      this.loading = true;

      setTimeout(() => {
        this.search = "";
        this.selectedInt = [];
        this.selectedMs = [];
        this.loading = false;
      }, 2000);
    },
    getFlexi() {
      var self = this;
      API_Util.URLS.axios
        .post(
          API_Util.URLS.getlblbyscr,
          {
            owner_id: localStorage.getItem("owner"),
            scrId: "MIC_SET",
            lang: this.lang,
          },
          { headers: { "x-api-key": this.apiKey } }
        )
        .then((response) => {
          self.labels = response.data.labels;
          self.headers = [
            {
              text: self.labels.NAME,
              align: "start",
              sortable: true,
              value: "name",
            },
            { text: self.labels.DESC, value: "desc" },
            { text: self.labels.CREATEDDATE, value: "dateUpdated" },
          ];
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
};
</script>
<style lang="scss">
  .owner-tlt {
    padding-left: 20px;
  }
.v-input__append-inner {
  margin-top: 11px !important;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 46px !important;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}
.container {
  max-width: 1224px;
}
.v-menu__content {
  background-color: white;
}
.v-chip .mdi-pencil-box-multiple {
  font-size: 21px !important;
}
.v-btn::before {
  background-color: transparent !important;
}
.v-stepper__step__step {
  height: 12px !important;
  min-width: 12px !important;
  width: 12px !important;
}

.v-stepper {
  // .v-stepper__step__step.primary {
  //     background-color: red !important;
  //     border-color: red !important;
  // }
  .v-divider {
    border-width: thin 0px 3px 0 !important;
  }

  .v-stepper__step {
    padding: 30px 0px 0px;

    hr {
      .v-stepper--alt-labels.v-stepper__header.v-divider {
        margin: 35px -80px 0 !important;
      }
    }
  }
}

//  .v-stepper__step--complete + .v-divider{
//      background-color: red !important;
//   }
.v-stepper__step__step {
  color: transparent !important;
  .v-icon {
    display: none !important;
  }
}
.v-stepper__header {
  box-shadow: none !important;
}

.ms-title {
  .v-card__title {
    text-align: center !important;
    display: block !important;
  }
}
.v-card__actions {
  text-align: center !important;
  display: block !important;
}
/* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .owner-tlt {
    padding-left: 0;
  }
}
</style>
