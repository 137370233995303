// Created @2021-Feb-13 by Zhang Qiao
// As we are moving to composition API, current vue 2 have not been updated yet
// This file was created to solve "Cannot import Vue Plugin using Composition API" error
// @vue/composition-api via Vue.use() must be installed before using other APIs.
// This file will be imported by main.js as the top level element
// so Composition API will be installed before everything else

import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'

Vue.use(VueCompositionApi)