<template>
  <v-card class="ms-title">
    <v-card-title class="text-center">
      <div class="py-2">
        <h3>{{ labels.EDITMS }}</h3>
      </div>
    </v-card-title>
    <v-divider class="my-4"></v-divider>
    <v-card-text class="px-6 pb-0">
      <!-- EXPANSION Basic Info  PANEL -->
      <v-col cols="12" class="panelBorder pa-0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="pl-0 secondary--text text--darken-4">{{ labels.MSD }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="">
              <regMsDetails :regmicroalldata="regmicroalldata" :validForm="validForm" :labels="labels"
                :datacheck="datacheck" :response_message="response_message" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" class="panelBorder pa-0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="pl-0 secondary--text text--darken-4">{{ labels.APID }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <regApiDetails :regmicroalldata="regmicroalldata" :validForm="validForm" :labels="labels"
                :response_message="response_message" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" class="panelBorder pa-0">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="pl-0 secondary--text text--darken-4">{{ labels.DOCD }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <regDocsDetails :regmicroalldata="regmicroalldata" :validForm="validForm" :labels="labels" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>

      <div class="pl-10">
        <v-btn class="nocap" @click="closeRegMsEdit" text>
          <span class="">{{ labels.CANCEL }}</span>
        </v-btn>
        <v-btn class="nocap primary" text @click="regMsEditSave()">
          <span class="">{{ labels.SAVE }}</span>
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import regMsDetails from "./regMsPopUp/regMsDetails.vue";
import regApiDetails from "./regMsPopUp/regApiDetails.vue";
import regDocsDetails from "./regMsPopUp/regDocsDetails.vue";
export default {
  components: {
    regMsDetails,
    regApiDetails,
    regDocsDetails
  },
  name: "registerMsEditPage",
  props: ['regmicroalldata', 'validForm', 'labels', 'datacheck', "response_message"],
  data: () => ({
    checkbox: true,
  }),
  created() {
    this.validForm = true;
  },
  methods: {
    closeRegMsEdit() {
      this.$emit('closeRegMsEdit', false);
    },
    regMsEditSave() {
      if (this.regmicroalldata.name == "") {
        this.validForm = false;
      } else if (this.regmicroalldata.deployment_type == "") {
        this.validForm = false;
      } else if (this.regmicroalldata.description == "") {
        this.validForm = false;
      } else if (this.regmicroalldata.model_image_url == "") {
        this.validForm = false;
      } else if (this.regmicroalldata.resource_cpu == "") {
        this.validForm = false;
      } else if (this.regmicroalldata.resource_memory == "") {
        this.validForm = false;
      } else if (this.regmicroalldata.enable_gpu == "") {
        this.validForm = false;
      } else if (this.regmicroalldata.model_image_port == "") {
        this.validForm = false;
      } else if (this.regmicroalldata.endpoint == "") {
        this.validForm = false;
      } else if (this.regmicroalldata.docs_yaml_url == "") {
        this.validForm = false;
      } else {
        this.$emit("regMsEditSave");
      }
    },
    onUpdate(text) {
      this.text = text;
    },
  },
};
</script>

<style></style>
