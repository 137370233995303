<template>
  <div class="main-plandetails">
    <!-- <navigation/> -->
    <!-- <div style="padding-left:300px"> -->
    <div>
      <v-breadcrumbs :items="items" v-if="ownerDrop">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
      <div class="owner-tlt">
        <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" v-if="ownerDrop"/>
      </div>
      <v-content class="white ml-7 mr-4">
        <!-- <div cols="12 px-0 pb-6" v-if="ownerDrop">
          <a style="margin-top: -20px">
            <v-btn text class="nocap" @click="goBack">
              <span class="grey--text">
                <v-icon style="margin-left:-10px">mdi-arrow-left</v-icon>{{labels.BACK}}
              </span>
            </v-btn>
          </a>
        </div>--->
        <!-- ------------------------ plan details --------------------------------->
        <div class="main-div">
          <v-col cols="12" class="pb-0 phead-sec">
            <h2 class="detailLabel plandetail">{{labels.PLAN_DETAILS}}</h2>
            <div class="text-right pb-5" style="position:relative;top: 12px;" v-if="ownerDrop">
              <v-btn text solo elevation='2' color="secondary lighten-3 text--secondary text--darken-2  py-5"
                class="nocap ml-3" @click.stop="dialog = true">
                <v-icon color="secondary darken-2">
                  mdi-file-edit-outline
                </v-icon>
                <span style="color: black;">{{labels.EDIT}}</span>
              </v-btn>
            </div>
          </v-col>

          <section class="planList pb-7">
            <v-col cols="12" v-show="ownerthemeInfo.plan_name != null && ownerthemeInfo.plan_name != ''">
              <div class="d-flex">
                <div class="detailLabel width-200">{{labels.PLAN_NME}}</div>
                <div class="pl-4">{{ ownerthemeInfo.plan_name }}</div>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="d-flex">
                <div class="detailLabel width-200">{{labels.RT_LMT}}</div>
                <div class="pl-4">{{ ownerthemeInfo.rate_limit }}</div>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="">
                <div class="detailLabel width-200 mb-5">{{labels.COMPUTE}}</div>
                <div class="tbl-maindiv">
                  <v-card class="compTable">

                    <v-data-table :headers="Computeheaders" :items="Compute" class="elevation-1 listingTable"></v-data-table>
                  </v-card>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="">
                <div class="detailLabel width-200 mb-5">{{labels.CSTM_MS}}</div>
                <div class="tbl-maindiv">
                  <v-card class="compTable">

                    <v-data-table :headers="customheaders" :items="customMicro" class="elevation-1 listingTable"></v-data-table>
                  </v-card>
                </div>
              </div>
            </v-col>
          </section>
        </div>

      </v-content>
    </div>
    <!-- <loaderimg v-if="loader"/> -->
    <v-dialog v-model="dialog" max-width="500px">
    
      <v-card>
        <v-card-title class="px-6 pt-6 pb-0">
                  <h3 class="text-left updatePlan_dialog">{{labels.EDT_UPD}}</h3>
                  <v-spacer></v-spacer>
                  <v-icon @click="dialog = false">mdi-close-circle-outline</v-icon>
                </v-card-title>
        <section class="dplanList">
          <div class="col-12 pa-0">
            <div class="d-flex">

              <label class="dlabel">{{labels.PLAN_NME}}</label>

              <v-text-field style="width: 80%" outlined text v-model="new_values.plan_name"></v-text-field>
            </div>
          </div>
          <div class="col-12 pa-0">
            <div class="d-flex" style="position:relative;">

              <label class="dlabel">{{labels.RT_LMT}}</label>

              <v-text-field style="width: 80%" outlined text v-model="new_values.rate_limit"></v-text-field> 
              <span class="Sublabel">m/s</span>
            </div>
          </div>
          <div class="col-12 pa-0">
            <div class="">
              <label class="dlabel">{{labels.COMPUTE}}</label>
              <div class="childLabel mt-4">
                <div class="d-flex">
              <label class="dlabel">{{labels.CPU}}</label>
              <v-text-field style="width: 80%" outlined text v-model="new_values.allocate_cpu"></v-text-field>
            </div>
            </div>
            <div class="childLabel">
                <div class="d-flex">
              <label class="dlabel">{{labels.MEMORY}}</label>
              <v-text-field style="width: 80%" outlined text v-model="new_values.allocate_memory"></v-text-field>
            </div>
            </div>
            <div class="childLabel">
                <div class="d-flex">
              <label class="dlabel">{{labels.STORAGE}}</label>
              <v-text-field style="width: 80%" outlined text v-model="new_values.allocate_storage"></v-text-field>
            </div>
            </div>
            <div class="childLabel">
                <div class="d-flex">
              <label class="dlabel">{{labels.GPU}}</label>
              <v-text-field style="width: 80%" outlined text v-model="new_values.allocate_gpu"></v-text-field>
            </div>
            </div>
            <div class="childLabel">
                <div class="d-flex">
              <label class="dlabel">{{labels.GPU_TYPE}}</label>
              <v-text-field style="width: 80%" outlined text v-model="new_values.gpu_type"></v-text-field>
              <!-- <v-select
                outlined
                
                item-text="value"
                item-value="code"
                placeholder="Select Category"
                style="width: 80%"
              ></v-select> -->
              <!-- <v-text-field style="width: 80%" outlined text></v-text-field> -->
            </div>
            </div>
            </div>
          </div>
          <div class="col-12 pa-0">
            <div class="">
              <label class="dlabel">{{labels.CSTM_MS}}</label>
              <div class="childLabel mt-4">
                <div class="d-flex">
              <label class="dlabel">{{labels.AI}}</label>
              <v-text-field style="width: 80%" outlined text v-model="new_values.allocate_ai"></v-text-field>
            </div>
            </div>
            <div class="childLabel">
                <div class="d-flex">
              <label class="dlabel">{{labels.DATA}}</label>
              <v-text-field style="width: 80%" outlined text v-model="new_values.allocate_da"></v-text-field>
            </div>
            </div>
            </div>
          </div>
          <!-- <v-list-item>
        <v-icon>mdi-square-edit-outline</v-icon>
         <v-list-item-title contenteditable @input="updateContent($event, 'heading')">Number of custom/premium microservice</v-list-item-title>
       </v-list-item> -->

        </section>
        <v-divider></v-divider>
          <v-card-actions class="px-10 py-6">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary darken-1"
              class="nocap mr-3"
              text
              @click="dialog = false"
            >
              {{labels.CANCEL}}
            </v-btn>
            <v-btn
              color="tertiary2 darken-1 white--text"
              class="nocap ml-3"
              @click="saveInfo()"
            >
              {{labels.UPDATE}}
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template> 
<script>
// import navigation from "./navigation.vue";
//import loaderimg from "./loaderimg.vue"
import API_Util from '../services/util/API_Util.js';
import headline from "./headline.vue";
export default {
  components: {
    // loaderimg,
    headline,
  },
  selectedOwner: "",
  ownerDesc: "",
  name: "planDetails",
  delimiters: ["${", "}"],

  data: () => ({
    Computeheaders: [
      {
        text: '',
        align: 'start',
        filterable: false,
        value: 'name',
      },
      { text: 'Allocated', value: 'allocate'},
      { text: 'Used', value: 'used'},
      { text: 'Available', value: 'available'},

    ],
    Compute: [
      {name: 'CPU', allocate: 0, used: 0, available: 0},
      {name: 'Memory', allocate: 0, used: 0, available: 0},
      {name: 'Storage', allocate: 0, used: 0, available: 0},
      {name: 'GPU', allocate: 0, used: 0, available: 0},
    ],
    customheaders: [
      {
        text: '',
        align: 'start',
        filterable: false,
        value: 'name',
      },
      { text: 'Allocated', value: 'allocate' },
      { text: 'Used', value: 'used' },
      { text: 'Available', value: 'available' },


    ],
    customMicro: [
      {name: 'AI', allocate: 0, used: 0, available: 0},
      {name: 'Data', allocate: 0, used: 0, available: 0},
    ],
    ownerDrop: "",
    ownerName: "",
    color: "#1976D2FF",
    mask: "!#XXXXXXXX",
    menu: false,
    filelist: [],
    singleSelect: false,
    selected: [],
    show1: false,
    password: "Password",
    editTheme: false,
    url: null,
    selectedFile: null,
    isSelecting: false,
    ownerInfo: {},
    redis_cloud: "",
    user_quata: "",
    redis_provider: [],
    ownerthemeInfo: {},
    tab: null,
    used_memory: 0,
    
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: true,
        href: "/ownerlisting",
      },
      {
        text: "Platform Setting - Plan Details",
        disabled: true,
        href: "",
      },
    ],
    apiKey: "",
    labels: {},
    lang: 'EN',
    loader: true,
    owner: "",
    ownerType: "",
    dialog: false,
    new_values: {
      owner_id: 0,
      plan_name: "",
      rate_limit: 0,
      allocate_cpu: 0,
      allocate_memory: 0,
      allocate_storage: 0,
      allocate_gpu: 0,
      allocate_ai: 0,
      allocate_da: 0,
      gpu_type: ""
    }
  }),

  computed: {
    buttonText() {
      return this.selectedFile
        ? this.selectedFile.name
        : this.defaultButtonText;
    },
  },
  methods: {
    changeOwner(obj) {
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
    },
    goBack() {
      localStorage.setItem('visit', 0);
      setTimeout(this.$router.go(-1), 1000);
    },
    ownerSettingsInfo() {
      var det = {"owner_id": localStorage.getItem("owner")}
      API_Util.URLS.axios
        .post(API_Util.URLS.listOwnerSettings, det, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.ownerthemeInfo = response.data;
          
          this.new_values.owner_id = this.ownerthemeInfo.owner_id;
          this.new_values.plan_name = this.ownerthemeInfo.plan_name;
          this.new_values.rate_limit = this.ownerthemeInfo.rate_limit;

          this.used_memory = this.ownerthemeInfo.allocate_memory - this.ownerthemeInfo.avail_memory;
          this.used_cpu = this.ownerthemeInfo.allocate_cpu - this.ownerthemeInfo.avail_cpu;
          this.used_storage = this.ownerthemeInfo.allocate_storage - this.ownerthemeInfo.avail_storage;
          this.used_gpu = this.ownerthemeInfo.allocate_gpu - this.ownerthemeInfo.avail_gpu;

          this.used_ai = this.ownerthemeInfo.allocate_ai - this.ownerthemeInfo.avail_ai;
          this.used_da = this.ownerthemeInfo.allocate_da - this.ownerthemeInfo.avail_da;

          this.new_values.allocate_cpu = this.ownerthemeInfo.allocate_cpu;
          this.new_values.allocate_memory = this.ownerthemeInfo.allocate_memory;
          this.new_values.allocate_storage = this.ownerthemeInfo.allocate_storage;
          this.new_values.allocate_gpu = this.ownerthemeInfo.allocate_gpu;

          this.new_values.allocate_ai = this.ownerthemeInfo.allocate_ai;
          this.new_values.allocate_da = this.ownerthemeInfo.allocate_da;

          this.new_values.gpu_type = this.ownerthemeInfo.gpu_type;

          if(this.ownerthemeInfo.gpu_type != null && this.new_values.gpu_type != ""){
            this.Compute = [{name: 'CPU', allocate: this.new_values.allocate_cpu, used: this.used_cpu, available: this.ownerthemeInfo.avail_cpu},
              {name: 'Memory', allocate: this.new_values.allocate_memory, used: this.used_memory, available: this.ownerthemeInfo.avail_memory},
              {name: 'Storage', allocate: this.new_values.allocate_storage, used: this.used_storage, available: this.ownerthemeInfo.avail_storage},
              {name: 'GPU(' + this.new_values.gpu_type + ')', allocate: this.new_values.allocate_gpu, used: this.used_gpu, available: this.ownerthemeInfo.avail_gpu},];
          }
          else{
            this.Compute = [{name: 'CPU', allocate: this.new_values.allocate_cpu, used: this.used_cpu, available: this.ownerthemeInfo.avail_cpu},
              {name: 'Memory', allocate: this.new_values.allocate_memory, used: this.used_memory, available: this.ownerthemeInfo.avail_memory},
              {name: 'Storage', allocate: this.new_values.allocate_storage, used: this.used_storage, available: this.ownerthemeInfo.avail_storage},
              {name: 'GPU', allocate: this.new_values.allocate_gpu, used: this.used_gpu, available: this.ownerthemeInfo.avail_gpu},];
          }

          this.customMicro = [{name: 'AI', allocate: this.new_values.allocate_ai, used: this.used_ai, available: this.ownerthemeInfo.avail_ai},
            {name: 'Data', allocate: this.new_values.allocate_da, used: this.used_da, available: this.ownerthemeInfo.avail_da},]

          if (response.data.id==undefined){
            this.ownerthemeInfo.owner_id=localStorage.getItem("owner");
            this.ownerthemeInfo.id=0;
            this.ownerthemeInfo.user_quata="";
          }
        });
    },
    saveInfo(){
      this.dialog = false;
      API_Util.URLS.axios
        .post(API_Util.URLS.updatePlandetails, this.new_values, { headers: { "x-api-key": this.apiKey } })
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setTimeout(() => {
                this.ownerSettingsInfo();
              }, 2000);
        });
    },
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
    getFlexi() {
      API_Util.URLS.axios
        .post(API_Util.URLS.getlblbyscr,
          {
            owner_id: localStorage.getItem("owner"),
            scrId: "PLAN_DET",
            lang: this.lang
          },
          { headers: { "x-api-key": this.apiKey } }
        )
        .then((response) => {
          this.labels = response.data.labels;
        })
    },
    updateContent(e, contentType) {
      const inputText = e.target.innerText;
      switch (contentType) {
        case "heading":
          this.contentHeading = inputText;
          break;

        default:
          break;
      }
    },
    ownerSettingsOnload() {
      API_Util.URLS.axios
        .post(API_Util.URLS.infoOwner, 
        {
        owner_id:localStorage.getItem("owner")
        },
        {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.loader = false;
          this.ownerInfo = response.data.results;
          console.log(this.ownerInfo)
          this.items[1].text = "Sentient.io (" + this.ownerInfo.ownerName + ")";
        });
    },
    async getProvider() {
      var data = {
        owner_id: localStorage.getItem("owner"),
        lang: "EN",
        code: "CLOUD_PROVIDER",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      this.redis_provider = response;
      console.log(response)
    },
  },
  created: function () {
    localStorage.setItem('visit', 0);
    this.loader = true;
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerName = sessionObj.session.ownerName;
    this.ownerType = localStorage.getItem("ownerType");
    this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
    this.user_id = sessionObj.session.id;
    this.owner = localStorage.getItem("owner");
    this.getFlexi();
    this.getProvider();
    this.ownerSettingsOnload();
    this.ownerSettingsInfo();
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerDesc = localStorage.getItem('ownerDesc');
  },
};
</script>
<style lang="scss">
  .main-plandetails .v-main {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
.plandetail {
  width: 93%;
}

.phead-sec {
  display: flex;
  padding: 0;
}

.planList {
  padding: 0 0px 5px;
}

.dplanList {
  padding: 30px 30px 20px;
  height: 60vh;
    overflow-y: scroll;
}
.dplanList::-webkit-scrollbar {
  width:10px;
}
 
.dplanList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.dplanList::-webkit-scrollbar-thumb {
  background-color: #CBAC81;
  border-radius: 4px;
}
.dplanList .v-list-item__title {
  padding: 10px;
}

.dplanList .v-icon {
  padding: 0 10px 0 0px;
  color: #CBAC81;
}
.childLabel {
  padding-left: 32px;
}
.childLabel .dlabel {
  font-weight: 500;
  width: 92px;
}
.dlabel {
  font-weight: bold;
    padding: 10px 0px 10px;
    width: 135px;
}
.Sublabel {
  font-weight: bold;
    padding: 10px 0px 10px;
    position: absolute;
    right: 15px;
    top: 2px;
}
.main-div {
  //box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  font-family: "NunitoSans Regular" !important;
}
.tbl-maindiv {
  padding-left: 16px;
}
// -------------------- Table styles ------------------------------------
.compTable {
  margin-right: 16px;
}
.compTable .v-data-table-header {
  background-color: #cbac81;
}
.compTable .v-data-table-header span{
  color: #fff;
}
.compTable .v-data-footer {
  display: none;
}
.compTable .v-data-table__wrapper table {
  table-layout: fixed;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .main-plandetails .v-main {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .tbl-maindiv {
    padding-left: 0;
  } 
  .compTable {
    margin-right: 0;
  }
}

/* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .updatePlan_dialog {
    font-size: 18px !important;
  }
  .main-plandetails .v-main {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

.planList {
  padding: 0 10px 40px;
}
.compTable {
  margin-right: unset;
}
.compTable .v-data-table-header {
  display: none;
}
.compTable tbody {
  display: contents;
}
.compTable tbody .v-data-table__mobile-table-row {
  display: table-caption !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}
.tbl-maindiv {
  padding-left: 0px;
}
}


</style>